import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { apiUrl } from 'src/app/core/utils/api_url';
import { UserTableInterface } from '../interface/user_table_interface';
import { ModifyUserInterface } from './modify_user_interface';

@Component({
  selector: 'app-modify-user',
  templateUrl: './modify-user.component.html',
  styleUrls: ['./modify-user.component.scss'],
  providers: [ApiHelperService],
})
export class ModifyUserComponent implements OnInit {
  @Output() emitterToggleOff: EventEmitter<any> = new EventEmitter();

  selectedUser: UserTableInterface = {
    id: 0,
    firstName: '',
    middleName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    status: '',
    lastModified: '',
    registrationDate: '',
  };

  modifiedDetials = {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
  };
  constructor(public http: ApiHelperService<ModifyUserInterface>) {}

  ngOnInit(): void {}

  setSelectedUser(data: UserTableInterface) {
    console.log(data, 'modify modal');
    this.selectedUser = data;
  }

  closeModal(): void {
    this, this.emitterToggleOff.emit();
  }

  submitModifyDetials(): void {
    let modifiedData = this.modifiedDetials   ;
    let selectedUser = this.selectedUser
    modifiedData.firstName = selectedUser.firstName;
    modifiedData.lastName = selectedUser.lastName;
    this.http.put({
      url: apiUrl.modifyUser.replace(':id', this.selectedUser.id.toString()),
      data: modifiedData,
      successCallBack: this.callBackSuccess,
    });
  }

  callBackSuccess = (): void => {
    this.closeModal();
  };
}
