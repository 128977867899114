function grandPrize(reward: string): string {
  const rewards = reward.split(':');
  const rewardsGrandPos = rewards.length - 1;
  return rewards[rewardsGrandPos];
}

function consolationPrize(reward: string): string[] {
  const rewards = reward.split(':');
  // let consolationPrizes :string[] = [];
  const rewardsGrandPos = rewards.length - 1;
  let consolationPrizes = rewards.filter(
    (element, index) => index != rewardsGrandPos
  );
  return consolationPrizes;
}

function removeReward(id: string): void {
  const element = document.getElementById(id);
  element.remove();
}

function resetInputRewards(): void {
  const elements = document.getElementsByClassName('group-rewards');
  while (elements.length > 0) {
    elements[0].parentNode.removeChild(elements[0]);
  }
}

function createRewardsInput(value: string, index: number,inputDesign:number): void {
  const element = document.querySelector('#consolation-rewards');
  const groupdID = `rewards-${index}`;
  const inputGroup = document.createElement('div');
  inputGroup.className = 'input-group mb-3 group-rewards';
  inputGroup.id = groupdID;
  element.appendChild(inputGroup);
  var input = document.createElement('input');
  input.className = `form-control input-design-${inputDesign} rewards-inputs`;
  input.value = value;
  inputGroup.appendChild(input);
  /// input
  const inputGroupApend = document.createElement('div');
  inputGroupApend.className = 'input-group-append';
  inputGroup.appendChild(inputGroupApend);
  /// div append
  const buttonAppend = document.createElement('button');
  buttonAppend.className = 'btn btn-outline-secondary';
  buttonAppend.type = 'button';
  buttonAppend.innerHTML = 'Remove';
  buttonAppend.addEventListener(
    'click',
    function () {
      removeReward(groupdID);
    },
    false
  );
  inputGroupApend.appendChild(buttonAppend);
}

function numberPlaceConverter(place: number): string {
  if (place <= 0) {
    alert('consolation number cant be less than 0');
    return;
  }
  let newValue = place % 10,
    posValue = place % 100;
  if (newValue == 1 && posValue != 11) {
    return place + 'st';
  }
  if (newValue == 2 && posValue != 12) {
    return place + 'nd';
  }
  if (newValue == 3 && posValue != 13) {
    return place + 'rd';
  }
  return place + 'th';
}
var sleep;
sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
export {
  grandPrize,
  consolationPrize,
  removeReward,
  createRewardsInput,
  numberPlaceConverter,
  resetInputRewards,
  sleep,
};
