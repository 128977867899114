<app-download-button (downloadEmitter)="clickDownload()"></app-download-button>
<app-my-table
  [titleTemplates]="titleTemplates"
  [dataTemplate]="dataTemplate"
  [tableOptions]="tableOption"
  (navigatePagination)="navigatePagination($event)"
  (onChangeTableSize)="onChangeTableSize($event)"
>
  <ng-template #titleTemplates>
    <th scope="col" class="th-table b-l-none">Name</th>
    <th scope="col" class="th-table">Transaction ID</th>
    <th scope="col" class="th-table">Creation Date</th>
    <th scope="col" class="th-table">Modified Date</th>
    <th scope="col" class="th-table">Mobile Number</th>
    <th scope="col" class="th-table">Mode of Payment</th>
    <th scope="col" class="th-table">Amount</th>
    <th scope="col" class="th-table">Status</th>
    <th scope="col" class="th-table b-r-none">Error Message</th>
  </ng-template>
  <ng-template #dataTemplate>
    <ng-container *ngIf="isLoading">
      <tr *ngFor="let in of mock(10); let i = index">
        <td class="td-table b-l-none">
          <app-skeleton-loader Cwidth="100"></app-skeleton-loader>
        </td>
        <td class="td-table">
          <app-skeleton-loader Cwidth="100"></app-skeleton-loader>
        </td>
        <td class="td-table">
          <app-skeleton-loader Cwidth="80"></app-skeleton-loader>
        </td>
        <td class="td-table">
          <app-skeleton-loader Cwidth="80"></app-skeleton-loader>
        </td>
        <td class="td-table">
          <app-skeleton-loader Cwidth="70"></app-skeleton-loader>
        </td>
        <td class="td-table">
          <app-skeleton-loader Cwidth="80"></app-skeleton-loader>
        </td>
        <td class="td-table">
          <app-skeleton-loader Cwidth="60"></app-skeleton-loader>
        </td>
        <td class="td-table">
          <app-skeleton-loader Cwidth="60"></app-skeleton-loader>
        </td>
        <td class="b-r-none b-r-none">
          <app-skeleton-loader Cwidth="80"></app-skeleton-loader>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <tr *ngFor="let item of data; let i = index">
        <td class="td-table b-l-none">
          {{ item.firstName }}
          {{ item.middleName }}
          {{ item.lastName }}
        </td>
        <td class="td-table">
          {{ item.transactionId }}
        </td>
        <td class="td-table">
          {{ item.creationDate }}
        </td>
        <td class="td-table">
          {{ item.modifiedDate }}
        </td>
        <td class="td-table">
          {{ item.mobileNumber }}
        </td>
        <td class="td-table">
          {{ item.modeOfPayment }}
        </td>
        <td class="td-table">
          {{ item.amount }}
        </td>
        <td class="td-table">
          {{ item.status }}
        </td>
        <td class="b-r-none b-r-none">
          {{ item.errorMessage != null ? item.errorMessage : "N/A" }}
        </td>
      </tr>
    </ng-container>
  </ng-template>
</app-my-table>
