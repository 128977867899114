import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EmptyResultInterface } from 'src/app/core/interface/empty_result_interface';
import { myRouter } from 'src/app/core/route/my_route';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import bsCustomFileInput from 'bs-custom-file-input';
import { SweetAlert2Service } from 'src/app/core/services/sweet-alert2/sweet_alert2';
import { apiUrl } from 'src/app/core/utils/api_url';

@Component({
  selector: 'app-create-events',
  templateUrl: './create-events.component.html',
  styleUrls: ['./create-events.component.scss'],
  providers: [ApiHelperService],
})
export class CreateEventsComponent implements OnInit {
  static readonly routeName: string = 'create-events';
  myRoutes = myRouter;
  formData = new FormData();
  fileInput: any;

  eventForm = this.formBuilder.group({
    name: ['', Validators.required],
    title: ['', Validators.required],
    slotTotal: ['', Validators.required],
    minAmount: ['', Validators.required],
    maxAmount: [''],
    sort: ['1', Validators.required],
    details: ['', Validators.required],
    uploadedContents: ['', Validators.required],
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    platform: ['mobile', Validators.required],
  });

  htmlOutput: string = '';
  summernoteConfig = {
    placeholder: '',
    tabsize: 2,
    height: '250px',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      [
        'font',
        [
          // 'bold',
          // 'italic',
          // 'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear',
        ],
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'link', 'video', 'hr']],
    ],
    fontNames: [
      'Helvetica',
      'Arial',
      'Arial Black',
      'Comic Sans MS',
      'Courier New',
      'Roboto',
      'Times',
    ],
  };

  constructor(
    private formBuilder: FormBuilder,
    public http: ApiHelperService<EmptyResultInterface>,
    private swal: SweetAlert2Service
  ) {}

  ngOnInit(): void {
    bsCustomFileInput.init();
  }

  onChangeSummerNote() {
    const form = this.ngoEventControl;
    form.details.setValue(this.htmlOutput);
    console.log(this.htmlOutput);
  }

  get ngoEventControl() {
    return this.eventForm.controls;
  }

  createEvents(): void {
    console.log(this.eventForm.value.title);
    this.onChangeSummerNote();
    if (this.eventForm.valid) {
      const formValues = this.eventForm.value;
      const formData = this.formData;
      formData.append('name', formValues.name);
      formData.append('title', formValues.title);
      formData.append('slotTotal', formValues.slotTotal);
      formData.append('minAmount', formValues.minAmount);
      formData.append('maxAmount', formValues.maxAmount);
      formData.append('sort', formValues.sort);
      formData.append('startDate', formValues.startDate);
      formData.append('endDate', formValues.endDate);
      formData.append('platform', formValues.platform);
      formData.append('details', this.htmlOutput);

      this.http.post({
        url: apiUrl.createEvents,
        data: formData,
        successCallBack: this.successCreateEvent,
      });

      console.log('valid');
      return;
    }
    this.swal.toastError('please fill up all fields');
    console.log('notvalid');
  }
  successCreateEvent = (): void => {
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  onFileSelected(event) {
    const formData = this.formData;
    const form = this.ngoEventControl;
    if (event.target.files.length <= 0) {
      formData.append('uploadedContents', '');
      form.uploadedContents.setValue('');
      return;
    }
    for (let i = 0; i < event.target.files.length; i++) {
      form.uploadedContents.setValue('1');
      formData.append('uploadedContents[]', event.target.files[i]);
    }
  }
}
