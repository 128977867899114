import { Component, Input, OnInit } from '@angular/core';
import { PortalTableData } from '../interface/portal_table_data_interface';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { ModifyPortalAccountResponseInterface } from './interface/update_portal_account_response';
import { apiUrl } from 'src/app/core/utils/api_url';
import { StateInterface } from 'src/app/core/interface/state_interface';
import { ResponseInterface } from 'src/app/core/interface/response_interface';
import { RoleInterface } from 'src/app/core/interface/role_interface';
import { myUserRoles } from 'src/app/core/utils/role_helper';

@Component({
  selector: 'app-update-portal-account',
  templateUrl: './update-portal-account.component.html',
  styleUrls: ['./update-portal-account.component.scss'],
  providers: [ApiHelperService],
})
export class UpdatePortalAccountComponent implements OnInit {
  userRoles: RoleInterface[] = myUserRoles;


  @Input() dataTemplate: PortalTableData = {
    id: 0,
    username: '',
    email: '',
    role: '',
    status: '',
  };

  @Input()
  parentModalListener: Subject<any>;

  // form input builder
  modifyUserForm = this.formBuilder.group({
    id: ['', Validators.required],
    username: ['', Validators.required],
    email: ['', Validators.required],
    role: ['', Validators.required],
    status: ['', Validators.required],
    password: ['', Validators.required],
  });
  https: any;

  constructor(
    private formBuilder: FormBuilder,
    public http: ApiHelperService<ModifyPortalAccountResponseInterface>
  ) {
    // this.http.apiDataState = this.dataStateRes;
  }

  ngOnInit(): void {
    // https://stackoverflow.com/a/37677652
    // listener from parent to childrent
    this.parentModalListener.subscribe((event) => {
      console.log(event);
      console.log('open sesame');
      const data = this.dataTemplate;
      this.modifyUserControl['id'].setValue(data.id);
      this.modifyUserControl['username'].setValue(data.username);
      this.modifyUserControl['email'].setValue(data.email);
      this.modifyUserControl['role'].setValue(data.role);
      this.modifyUserControl['status'].setValue(data.status);
    });

  }

  ngOnDestroy() {
    this.parentModalListener.unsubscribe();
  }

  get modifyUserControl() {
    return this.modifyUserForm.controls;
  }

  submitModifyForm(): void {
    const id = this.modifyUserControl['id'].value;
    this.http.put({
      url: `${apiUrl.modifyAccount.replace(':id', id)}`,
      data: this.modifyUserForm.value,
      successCallBack: this.callBackSuccess,
    });
  }

  callBackSuccess = (): void => {
    this.modifyUserForm.reset();
  };


}
