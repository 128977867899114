import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-button',
  templateUrl: './my-button.component.html',
  styleUrls: ['./my-button.component.scss'],
})
export class MyButtonComponent implements OnInit {
  @Input() onClickCallBack: () => void;
  @Input() buttonClass: string;
  @Input() isLoading: boolean;
  @Input() title: string;
  @Input() buttonType: string;
  @Input() isDisabled: boolean;
  
  constructor() {}

  ngOnInit(): void {}

  onClickButton(): void {
    if (this.onClickCallBack != null) {
      this.onClickCallBack();
    }
  }
}
