<app-header-version-two></app-header-version-two>
<div class="container">
  <div class="row align-items-center">
    <div class="col-lg-5 col-md-6 col-10 mx-auto mt-5">
      <div class="card my-border-1">
        <div class="card-body">
          <div class="row p-4">
            <div
              class="col-12 text-center my-text-bold-1 text-decoration-underline"
            >
              Login in to your account
            </div>
            <div class="col-12">
              <form [formGroup]="loginForm" (ngSubmit)="submitLoginForm()">
                <div class="mb-3">
                  <label for="username" class="form-label my-text-label"
                    >Username</label
                  >
                  <input
                    class="form-control input-design-3"
                    id="username"
                    formControlName="username"
                    aria-describedby="usernameHelp"
                  />
                  <!-- <small id="emailHelp" class="form-text red-text"
                    >required</small
                  > -->
                </div>
                <div class="mb-4">
                  <label for="password" class="form-label my-text-label"
                    >Password</label
                  >
                  <input
                    type="password"
                    formControlName="password"
                    class="form-control input-design-3"
                    id="password"
                  />
                  <!-- <small id="passwordHelp" class="form-text red-text"
                    >required</small
                  > -->
                </div>
                <div class="text-center">
                  <app-my-button
                    buttonClass="btn my-btn-color-2 f-weight-6 align-self-center w-150"
                    [isLoading]="http.apiDataState.isLoading"
                    title="LOG IN"
                    buttonType="submit"
                  ></app-my-button>
                </div>

                <!-- <app-dot-loading-handler
                  [dotCount]="3"
                  [dataElement]="myBtn"
                  [isLoading]="loginState.isLoading"
                ></app-dot-loading-handler>
                <ng-template #myBtn let-data="data">
                  <div class="d-flex">
                    <button
                      class="btn my-btn-color-2 f-weight-6 align-self-center mx-auto"
                      type="submit"
                    >
                      LOG IN
                    </button>
                  </div>
                </ng-template> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
