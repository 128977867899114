<form [formGroup]="createUserForm" (ngSubmit)="submitCreateAccount()">
  <div class="row">
    <div class="col-6">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="firstName" class="col-form-label form-label"
            >First Name:</label
          >
        </div>
        <div class="col">
          <input id="firstName" class="form-control input-design-2" />
        </div>
      </div>
      <!-- /.  name -->
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="lastName" class="col-form-label form-label"
            >Last Name:</label
          >
        </div>
        <div class="col">
          <input id="lastName" class="form-control input-design-2" />
        </div>
      </div>
      <!-- /. mobile_number -->
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="email" class="col-form-label form-label">Email:</label>
        </div>
        <div class="col">
          <input id="email" class="form-control input-design-2" />
        </div>
      </div>
      <!-- /. transactions_id -->
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="mobileNumber" class="col-form-label form-label"
            >MobileNumber:</label
          >
        </div>
        <div class="col">
          <input id="mobileNumber" class="form-control input-design-2" />
        </div>
      </div>
      <!-- /. mode_of_payment -->
    </div>
    <!-- /.left side -->
    <div class="col-6">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4 text-end">
          <label for="campaign_name" class="col-form-label form-label"
            >From:</label
          >
        </div>
        <div class="col">
          <input id="campaign_name" class="form-control input-design-2" />
        </div>
      </div>
      <!-- /. campaign from -->
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4 text-end">
          <label for="campaign_name" class="col-form-label form-label"
            >To:</label
          >
        </div>
        <div class="col">
          <input id="campaign_name" class="form-control input-design-2" />
        </div>
      </div>
      <!-- /. campaign to -->
    </div>
    <!-- /. right side -->
    <div class="col-12">
      <div class="d-grid d-flex justify-content-end">
        <button class="btn my-btn-color-3 my-2 mx-2 btn-xs" type="submit">
          filter
        </button>
      </div>
    </div>
  </div>
  <!-- /.row form -->
</form>
