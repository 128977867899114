<!-- <ul class="list-group">
  <ng-container *ngIf="!http.apiDataState.isLoading">
    <li
      class="list-group-item"
      *ngFor="let data of http.apiDataState.result.result; let i = index"
      role="button"
    >
      <div class="question">
        <span class="mx-2">Q.</span> {{ data.question }}
      </div>
      <div class="answer"><span class="mx-2">A.</span> {{ data.answer }}</div>
    </li>
  </ng-container>
</ul> -->
<div class="row align-items-end">
  <div class="col-12 content-bottom-color text-center f-weight-7 p-0">
    <app-my-table
      [titleTemplates]="titleTemplates"
      [dataTemplate]="dataTemplate"
      [settings]="settings"
    >
      <ng-template #titleTemplates>
        <th scope="col" class="th-table b-l-none">Question</th>
        <th scope="col" class="th-table">Answer</th>
        <th scope="col" class="th-table b-r-none" *ngIf="!isRestrictedRole1()" >Action</th>
      </ng-template>
      <ng-template #dataTemplate>
        <ng-container *ngIf="!http.apiDataState.isLoading">
          <!-- *ngIf="data.deletedBy == null && data.dateDeleted == null" -->
          <ng-container
            *ngFor="let data of http.apiDataState.result.result; let i = index"
          >
            <tr *ngIf="data.deletedBy == null && data.dateDeleted == null">
              <td class="td-table b-l-none">
                {{ data.question }}
              </td>
              <td class="td-table b-l-none">
                {{ data.answer }}
              </td>
              <td class="td-table b-r-none" *ngIf="!isRestrictedRole1()">
                <a
                  role="button"
                  class="badge green-bg text-white mx-1 text-size-action-button-table"
                  data-toggle="modal"
                  data-target="#modifyFaq"
                  (click)="clickModify(data)"
                  >Modify</a
                >
                <a
                  role="button"
                  class="badge pointer-cursor red-bg text-white mx-1 text-size-action-button-table"
                  data-toggle="modal"
                  data-target="#deleteFaq"
                  (click)="clickDisabled(data)"
                  >Disabled</a
                >
              </td>
            </tr>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="http.apiDataState.isLoading">
          <tr *ngFor="let in of mock(10); let i = index" class="">
            <td><app-skeleton-loader Cwidth="80"></app-skeleton-loader></td>
            <td><app-skeleton-loader Cwidth="80"></app-skeleton-loader></td>
            <td><app-skeleton-loader Cwidth="50"></app-skeleton-loader></td>
          </tr>
        </ng-container>
      </ng-template>
    </app-my-table>
  </div>
</div>
<!-- /.row -->

<app-delete-faq (reloadFaq)="getFaq()"></app-delete-faq>
<app-modify-faq (reloadFaq)="getFaq()"></app-modify-faq>
