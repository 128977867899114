import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { MockService } from 'src/app/core/services/mock/mock.service';
import { apiUrl } from 'src/app/core/utils/api_url';
import { DeleteFaqComponent } from '../delete-faq/delete-faq.component';
import { FAQInterface } from '../interface/faq_interface';
import { ModifyFaqComponent } from '../modify-faq/modify-faq.component';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage-service';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.scss'],
  providers: [ApiHelperService],
})
export class FaqListComponent implements OnInit {
  settings: boolean = false;
  mock: Function;
  role: string = '';
  user: string = 'User';
  marketing: string = 'Marketing';

  @ViewChild(DeleteFaqComponent) faqDeleteChild: DeleteFaqComponent;
  @ViewChild(ModifyFaqComponent) faqModifyChild: ModifyFaqComponent;

  constructor(
    public http: ApiHelperService<[FAQInterface]>,
    private mockData: MockService,
    private storage: LocalStorageService,

  ) {
    this.mock = this.mockData.createMockData;
  }

  ngOnInit(): void {
    this.getFaq();
    this.role = this.storage.role();

  }

  getFaq(): void {
    this.http.post({
      url: apiUrl.getFaq,
    });
  }

  clickDisabled(data: FAQInterface): void {
    this.faqDeleteChild.setId(data.uid);
  }

  clickModify(data: FAQInterface): void {
    this.faqModifyChild.setSelectedFaq(data);
  }
  isRestrictedRole1(): boolean {
    const restrictedRoles = ['Audit'];
    return restrictedRoles.includes(this.role);
  }
  
  
}
