import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-my-modal',
  templateUrl: './my-modal.component.html',
  styleUrls: ['./my-modal.component.scss']
})
export class MyModalComponent implements OnInit {
  @Output() clickYes: EventEmitter<any> = new EventEmitter();
  @Input() customElement: TemplateRef<any>;

  @Input() title: string;
  @Input() modalId: string;

  constructor() { }

  ngOnInit(): void {
  }

  onClickYes(): void {
    if (this.clickYes != null) {
      this.clickYes.emit();
    }
  }

}
