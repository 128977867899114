import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import bsCustomFileInput from 'bs-custom-file-input';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { SweetAlert2Service } from 'src/app/core/services/sweet-alert2/sweet_alert2';
import { AdsCreateI } from './interface/ads_create_i';
import { apiUrl } from 'src/app/core/utils/api_url';
import { DatePipe } from '@angular/common';
import { AdvertisementI } from '../ads-table/interface/ads_table_i';

@Component({
  selector: 'app-ads-form',
  templateUrl: './ads-form.component.html',
  styleUrls: ['./ads-form.component.scss'],
  providers: [ApiHelperService, DatePipe],
})
export class AdsFormComponent implements OnInit {
  formData = new FormData();
  adsFormBuilder = this.formBuilder.group({
    title: ['', Validators.required],
    description: [null],
    position: ['', Validators.required],
    dateFrom: ['', Validators.required],
    dateTo: ['', Validators.required],
    file: ['', Validators.required],
    link: [null],
  });
  editId: string = '';
  editFile: string = '';

  isEdit: boolean = false;

  adsFile: File = null;
  @Output() reloadAds: EventEmitter<any> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private swal: SweetAlert2Service,
    public http: ApiHelperService<AdsCreateI>,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    bsCustomFileInput.init();
  }

  newDateFormat(dateTime: string): string {
    return this.datePipe.transform(dateTime, 'yyyy-MM-dd');
  }

  onUploadFile(event): void {
    const formValidator = this.adsControl;
    if (event.target.files.length <= 0) {
      this.formData.append('file', null);
      formValidator.file.setValue(null);
      console.log('w/o file');
      this.adsFile = null;
      return;
    }

    for (let i = 0; i < event.target.files.length; i++) {
      this.formData.append('file', event.target.files[i]);
      formValidator.file.setValue('withFile');
    }

    // const file = allFiles[0];
    // this.adsFile = file;
    // console.log('with file');
    // console.log(file);
    // formValidator.file.setValue('withFile');
    // this.formData.append('file', file);
  }

  get adsControl() {
    return this.adsFormBuilder.controls;
  }

  createAds(): void {
    const formValidator = this.adsControl;

    if (formValidator.title.invalid) {
      this.swal.toastError('title is required');
      return;
    }
    if (formValidator.position.invalid) {
      this.swal.toastError('position is required');
      return;
    }
    if (formValidator.dateTo.invalid) {
      this.swal.toastError('date to is required');
      return;
    }
    if (formValidator.dateFrom.invalid) {
      this.swal.toastError('date from is required');
      return;
    }
    if (formValidator.file.invalid) {
      this.swal.toastError('file is required');
      return;
    }

    if (this.isEdit) {
      this.submitModifyAds();
      return;
    }

    this.createAdsApi();
  }

  createAdsApi(): void {
    const formValidator = this.adsControl;
    const form = this.formData;
    form.append('title', formValidator.title.value);
    form.append('position', formValidator.position.value);
    form.append('dateFrom', this.newDateFormat(formValidator.dateFrom.value));
    form.append('dateTo', this.newDateFormat(formValidator.dateTo.value));
    this.http.post({
      url: apiUrl.addAds,
      data: this.formData,
      successCallBack: this.successCallBack,
    });
  }

  successCallBack = (): void => {
    this.clearForm();
  };

  clearForm(): void {
    const formValidator = this.adsControl;
    formValidator.title.setValue('');
    formValidator.position.setValue('');
    formValidator.dateFrom.setValue('');
    formValidator.dateTo.setValue('');
    formValidator.file.setValue('withFile');
    var inputValue = <HTMLInputElement>document.getElementById('file-input');
    inputValue.value = '';
    inputValue.dispatchEvent(new Event('change'));
    this.reloadAds.emit();
  }

  submitModifyAds(): void {
    const formValidator = this.adsControl;
    const form = this.formData;
    form.append('title', formValidator.title.value);
    form.append('position', formValidator.position.value);
    form.append('dateFrom', this.newDateFormat(formValidator.dateFrom.value));
    form.append('dateTo', this.newDateFormat(formValidator.dateTo.value));
    this.http.put({
      url: apiUrl.editAds.replace(':id', this.editId),
      data: this.formData,
      successCallBack: this.successCallBackEdit,
    });
  }
  successCallBackEdit = (): void => {
    this.editId = '';
    this.editFile = '';
    this.isEdit = false;
    this.clearForm();
  };

  setModifyAds(data: AdvertisementI): void {
    const formValidator = this.adsControl;
    formValidator.title.setValue(data.title);
    formValidator.position.setValue(data.position.toLocaleLowerCase());
    formValidator.dateFrom.setValue(this.newDateFormat(data.dateFrom));
    formValidator.dateTo.setValue(this.newDateFormat(data.dateFrom));
    formValidator.file.setValue(data.fileUrl != null ? 'withFile' : '');
    this.editId = data.uid.toString();
    this.editFile = data.fileUrl;
    this.isEdit = true;
    console.log(data, 'from form');
  }

  clickRemoveFile(): void {
    this.adsControl.file.setValue('');
    this.editFile = '';
  }
}
