<div class="content-bottom-color pd-sm">
  <!-- /.row -->
  <app-my-table
    [titleTemplates]="titleTemplates"
    [dataTemplate]="dataTemplate"
    [settings]="false"
  >
    <!-- https://stackoverflow.com/a/42814996 -->
    <!-- /. passing of data thru params with from child to parent -->
    <ng-template #titleTemplates>
      <th scope="col" class="th-table b-l-none">ID</th>
      <th scope="col" class="th-table">Image</th>
      <th scope="col" class="th-table">Title</th>
      <th scope="col" class="th-table">Date From</th>
      <th scope="col" class="th-table">Date To</th>
      <th scope="col" class="th-table b-r-none"  *ngIf="!isRestrictedRole1()">Action</th>
    </ng-template>
    <!-- /. start of  dataTemplate -->
    <ng-template #dataTemplate>
      <ng-container *ngIf="http.apiDataState.isLoading">
        <tr *ngFor="let in of mock(10); let i = index">
          <td class="td-table b-l-none">
            <app-skeleton-loader Cwidth="20"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="80"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="150"></app-skeleton-loader>
          </td>

          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="b-r-none b-r-none">
            <app-skeleton-loader Cwidth="170"></app-skeleton-loader>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="!http.apiDataState.isLoading">
        <tr
          *ngFor="
            let data of http.apiDataState.result.result.advertisements;
            let i = index
          "
        >
          <td class="td-table b-l-none">
            {{ data.uid }}
          </td>
          <td class="td-table text-center">
            <img class="icon-table" src="https://{{ data.fileUrl }}" alt="" />
            <div class="row">
              <ng-container
                *ngFor="
                  let imageContent of data.advertisementContents;
                  let i = index
                "
              >
                <div class="col" *ngIf="imageContent.fileUrl != null">
                  <img
                    class="icon-table"
                    src="https://{{ imageContent.fileUrl }}"
                    alt=""
                  />
                  <br />
                  <label for="">{{ imageContent.view }}</label>
                </div>
              </ng-container>
            </div>
          </td>
          <td class="td-table">
            {{ data.title }}
          </td>
          <td class="td-table">
            {{ data.dateFrom }}
          </td>
          <td class="td-table">
            {{ data.dateTo }}
          </td>
          <td class="b-r-none b-r-none"  *ngIf="!isRestrictedRole1()">
            <div class="d-flex">
              <a
                role="button"
                class="badge purple-bg text-white mx-1 text-size-action-button-table"
                data-toggle="modal"
                data-target="#enableAdsModal"
                [ngClass]="{ 'disabled-btn': data.dateDeleted == null }"
                [attr.disabled]="data.dateDeleted == null ? false : null"
                (click)="clickEnable(data.uid)"
                >Enable</a
              >
              <a
                role="button"
                class="badge purple-bg text-white mx-1 text-size-action-button-table"
                data-toggle="modal"
                data-target="#addImageMobile"
                [ngClass]="{ 'disabled-btn': data.dateDeleted == null }"
                [attr.disabled]="data.dateDeleted == null ? false : null"
                (click)="clickAddImage(data)"
                >upload content</a
              >
              <a
                  role="button"
                  class="badge green-bg text-white mx-1 text-size-action-button-table"
                  [ngClass]="{ 'disabled-btn': data.dateDeleted == null }"
                [attr.disabled]="data.dateDeleted == null ? false : null"
                (click)="clickModify(data)"

                >
                  Modify
              </a>
              <a
                role="button"
                data-toggle="modal"
                data-target="#deleteAdsModal"
                class="badge red-bg text-white mx-1 text-size-action-button-table"
                [ngClass]="{ 'disabled-btn': data.dateDeleted !== null }"
                [attr.disabled]="data.dateDeleted !== null ? true : null"
                (click)="clickDisable(data.uid)"
                >Disable</a
              >
            </div>
          </td>
        </tr>
      </ng-container>
      <!-- /. api data -->
    </ng-template>
    <!-- /. end of dataTemplate -->
  </app-my-table>
</div>

<app-ads-enable (reloadAds)="getAds()"></app-ads-enable>
<app-ads-disable (reloadAds)="getAds()"></app-ads-disable>
<app-add-image-ads (reloadAds)="getAds()"></app-add-image-ads>
