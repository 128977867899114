import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-version-two',
  templateUrl: './header-version-two.component.html',
  styleUrls: ['./header-version-two.component.scss']
})
export class HeaderVersionTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
