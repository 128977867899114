import { Injectable } from '@angular/core';
import { LocalStorageKeys } from '../../utils/local_storage_keys';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  get(key: string) {
    return localStorage.getItem(key);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  role = ():string =>  this.get(LocalStorageKeys.role);

}
