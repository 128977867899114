import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MyTableOptionsInterface } from 'src/app/core/interface/my_table_data_interface';
import { ExcelInterface } from 'src/app/core/services/export-excel/excel_interface';
import { ExportExcelService } from 'src/app/core/services/export-excel/export-excel.service';
import { MockService } from 'src/app/core/services/mock/mock.service';
import { CashInResponseI } from '../interface/transtacion_table_data_interface';

@Component({
  selector: 'app-transaction-table',
  templateUrl: './transaction-table.component.html',
  styleUrls: ['./transaction-table.component.scss'],
})
export class TransactionTableComponent implements OnInit {
  mock: Function;

  @Input() data: CashInResponseI[] = [];
  @Input() isLoading: boolean = true;
  @Input() tableOption: MyTableOptionsInterface;

  @Output() changePageNumber: EventEmitter<number> = new EventEmitter();
  @Output() changePageSize: EventEmitter<number> = new EventEmitter();
  constructor(
    private mockService: MockService,
    public ete: ExportExcelService
  ) {
    this.mock = this.mockService.createMockData;
  }

  ngOnInit(): void {}

  navigatePagination(pageNumber: number): void {
    this.changePageNumber.emit(pageNumber);
  }
  onChangeTableSize(sizeNumber: number) {
    this.changePageSize.emit(sizeNumber);
  }

  clickDownload(): void {
    let reportData: ExcelInterface = {
      fileName: 'cash-in-transcation',
      data: this.data,
    };
    this.ete.exportExcel(reportData);
  }
}
