import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EmptyResultInterface } from 'src/app/core/interface/empty_result_interface';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { apiUrl } from 'src/app/core/utils/api_url';

@Component({
  selector: 'app-ads-disable',
  templateUrl: './ads-disable.component.html',
  styleUrls: ['./ads-disable.component.scss'],
})
export class AdsDisableComponent implements OnInit {
  id: number = null;
  @Output() reloadAds: EventEmitter<any> = new EventEmitter();
  constructor(public http: ApiHelperService<EmptyResultInterface>) {}

  ngOnInit(): void {}

  setId(uid: number): void {
    this.id = uid;
  }

  disableAds(): void {
    this.http.delete({
      url: apiUrl.deleteAds.replace(':id', this.id.toString()),
      successCallBack: this.successCallBack,
    });
  }

  successCallBack = (): void => {
    const closeModal = document.getElementById('closeAdsModaldelete');
    closeModal.click();
    this.reloadAds.emit();
  };
}
