<div class="container">
  <div class="row mb-3">
    <div class="col-4 mt-4">
      <app-page-title
        pageTitle="Frequently ask questions (FAQ)"
      ></app-page-title>
    </div>
    <!-- /.col -->
    <div class="col-md-8 col-12 mb-3 align-self-end mt-4" *ngIf="!isRestrictedRole1()" >
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button
          class="btn my-btn-color-2 me-md-2 mx-2"
          role="button"
          data-toggle="modal"
          data-target="#addFaqModal"
        >
          Add Faq
        </button>
      </div>
    </div>
    <!-- /.col -->
  </div>
  <app-faq-list></app-faq-list>
  <!-- <div class="d-flex">
    <button
      class="btn my-btn-color-2 f-weight-6 align-self-center mx-auto btn-med"
      type="button"
    >
      SAVE
    </button>
  </div> -->
</div>

<app-faq-create-new (reloadFaq)="getFaqList()"></app-faq-create-new>
