<div class="row" [ngClass]="{ 'mt-3': settings }">
  <div class="col-6 mb-2" *ngIf="settings">
    <div class="show-text orange-text">
      Show
      <app-skeleton-loader
        Cwidth="20"
        *ngIf="tableOptions.isLoading"
      ></app-skeleton-loader>
      <input
        type="number"
        class="entry-number"
        (change)="onChangeEntries()"
        [(ngModel)]="tableSize"
        *ngIf="!tableOptions.isLoading"
      />
      entries
      {{ tableSize }}
    </div>
  </div>
  <!-- /. col  -->
  <div class="col-6 align-self-end mb-2" *ngIf="settings">
    <div class="row no-gutters align-items-center justify-content-end">
      <div class="col-auto">
        <label
          for="searchTable"
          class="col-form-label orange-text label-search p-0"
          >Search:</label
        >
      </div>
      <div class="col-auto">
        <input
          type="text"
          id="searchTable"
          class="form-control sm-input input-search-table"
          aria-describedby="passwordHelpInline"
          [(ngModel)]="searchInput"
          (change)="onChangeSearch()"
        />
      </div>
      <div class="col-auto" *ngIf="searchTypes.length >= 1">
        <select
          class="custom-select input-design-2 mx-2"
          id="search-type"
          [(ngModel)]="selectedSearchType"
        >
          <option value="" selected>Please select status...</option>
          <option
            *ngFor="let data of searchTypes; let i = index"
            [value]="data.toString()"
          >
            {{ data }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <!-- /. col  -->

  <div class="table-responsive padding-responsive">
    <table class="table table-bordered" [ngClass]="{ 'mb-0': !settings }">
      <thead>
        <tr *ngIf="titleTemplates">
          <ng-container *ngTemplateOutlet="titleTemplates"> </ng-container>
        </tr>
      </thead>
      <tbody *ngIf="dataTemplate">
        <ng-container *ngTemplateOutlet="dataTemplate"> </ng-container>
      </tbody>
    </table>
  </div>
  <!-- /.table responstive -->
  <div class="col-6 mb-2" *ngIf="settings">
    <app-skeleton-loader
      Cwidth="80"
      *ngIf="tableOptions.isLoading"
    ></app-skeleton-loader>
    <p class="show-text orange-text" *ngIf="!tableOptions.isLoading">
      Showing {{ tableOptions.currentPage }} to
      {{ tableOptions.totalItems }} entries
    </p>
  </div>
  <!-- /. col  -->
  <div class="col-6 mb-2" *ngIf="settings">
    <app-skeleton-loader
      Cwidth="80"
      *ngIf="tableOptions.isLoading"
    ></app-skeleton-loader>
    <nav aria-label="Page navigation example" *ngIf="!tableOptions.isLoading">
      <ul class="pagination justify-content-end pagination-sm">
        <li class="page-item">
          <a
            class="page-link"
            role="button"
            (click)="nextAndPrevTrigger(false)"
            [ngClass]="{ 'disabled-pagination': tableOptions.currentPage == 1 }"
            >Previous</a
          >
        </li>

        <!-- <ng-container *ngIf="tableOptions.totalPages <= 10"> -->
        <li
          *ngFor="let in of mock(tableOptions.totalPages); let i = index"
          class="page-item"
        >
          <a
            class="page-link"
            role="button"
            [ngClass]="{ active: tableOptions.currentPage == i + 1 }"
            (click)="paginateTrigger(i + 1)"
            >{{ i + 1 }}</a
          >
        </li>
        <!-- </ng-container> -->

        <!-- <ng-container *ngIf="tableOptions.totalPages >= 10">
          <ng-container *ngIf="tableOptions.currentPage != 1">
            <li class="page-item">
              <a
                class="page-link"
                role="button"
                (click)="paginateTrigger(i - 1)"
                >{{ tableOptions.currentPage - 1 }}</a
              >
            </li>
          </ng-container>
          <li class="page-item">
            <a class="page-link active" role="button">{{
              tableOptions.currentPage
            }}</a>
          </li>
          <ng-container
            *ngIf="tableOptions.currentPage != tableOptions.totalPages"
          >
            <li class="page-item">
              <a
                class="page-link"
                role="button"
                (click)="paginateTrigger(tableOptions.currentPage + 1)"
                >{{ tableOptions.currentPage + 1 }}</a
              >
            </li>
            <li class="page-item" *ngFor="let in of mock(3); let i = index">
              <a class="page-link" role="button">...</a>
            </li>

          </ng-container>
        </ng-container> -->
        <li class="page-item">
          <a
            class="page-link"
            role="button"
            (click)="nextAndPrevTrigger(true)"
            [ngClass]="{ 'disabled-pagination': !tableOptions.hasNextPage }"
            >Next</a
          >
        </li>
      </ul>
    </nav>
  </div>
  <!-- /. col  -->
</div>
<!-- /.row -->
