<div class="container">
  <div class="row mb-3">
    <div class="col-4 mt-4">
      <app-page-title pageTitle="Portal Account"></app-page-title>
    </div>
    <!-- /.col -->
  </div>
  <!--  /. row buttons -->
  <app-create-portal-account></app-create-portal-account>
  <!-- /.row form -->
  <app-portal-table
    (modifyUser)="modifyUser($event)"
    (passUserId)="getUserID($event)"
  ></app-portal-table>
</div>
<!-- /. end of content -->

<!-- /.modal delete start -->
<app-delete-portal-account
  [userId]="modifyUserData.id"
  (triggerRefresh)="triggerRefreshData()"
></app-delete-portal-account>

<!-- /.modal modify start -->
<app-update-portal-account
  [dataTemplate]="modifyUserData"
  [parentModalListener]="parentModalListener"
></app-update-portal-account>
