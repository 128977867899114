<form [formGroup]="createPortalAccountForm" (ngSubmit)="submitCreateAccount()">
  <div class="row justify-content-center">
    <div class="col-8">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label
            for="username"
            class="col-form-label form-label my-text-label-2"
            >Username:</label
          >
        </div>
        <div class="col">
          <input
            id="username"
            placeholder="Enter username"
            class="form-control input-design-2"
            formControlName="username"
          />
        </div>
      </div>
      <!-- /. username -->
    </div>
    <!-- /.col -->
    <div class="col-8">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="email" class="col-form-label form-label my-text-label-2"
            >Email:</label
          >
        </div>
        <div class="col">
          <input
            id="email"
            class="form-control input-design-2"
            type="email"
            formControlName="email"
            placeholder="example@megacellularnetwork.com.ph"
          />
        </div>
      </div>
      <!-- /. email -->
    </div>
    <!-- /.col -->
    <div class="col-8">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="role" class="col-form-label form-label my-text-label-2"
            >Role/Permission:</label
          >
        </div>
        <div class="col">
          <select
            class="custom-select input-design-2"
            id="role"
            formControlName="role"
          >
            <option value="" selected>Please select role...</option>
            <option [value]="data.title" *ngFor="let data of userRoles">{{data.title}}</option>
          </select>
        </div>
      </div>
      <!-- /. email -->
    </div>
    <!-- /.col -->
    <div class="col-10 mb-3">
      <div class="text-center">
        <app-my-button
          buttonClass="btn my-btn-color-2 f-weight-6 align-self-center w-150"
          [isLoading]="http.apiDataState.isLoading"
          title="Save"
          buttonType="submit"
        ></app-my-button>
      </div>
    </div>
    <!-- /.col -->
    <div class="col-10 mb-3">
      <hr />
    </div>
  </div>
</form>
