import { ErrorInterface } from '../../interface/error_interface';

export class ApiErrorResponse implements ErrorInterface {
  timestamp: string;
  status: number;
  error: string;
  message: string;
  path: string;
  constructor(init: {
    timestamp: string;
    status: number;
    error: string;
    message: string;
    path: string;
  }) {
    this.timestamp = init.timestamp;
    this.status = init.status;
    this.message = init.message;
    this.path = init.path;
    this.error = init.error;
  }
}
