<div class="content-bottom-color pd-sm" [hidden]="isHiddenDraw">
  <app-download-button></app-download-button>
  <!-- /.row -->
  <app-my-table
    [titleTemplates]="titleTemplates"
    [dataTemplate]="dataTemplate"
    [tableOptions]="tableOptionsResponse"
    [searchTypes]="searchTypes"
    (navigatePagination)="navigatePagination($event)"
    (onChangeTableSize)="onChangeTableSize($event)"
    (onChangeSearchEmitter)="searchTable($event)"
  >
    <!-- https://stackoverflow.com/a/42814996 -->
    <!-- /. passing of data thru params with from child to parent -->
    <ng-template #titleTemplates>
      <th scope="col" class="th-table b-l-none">Campaign ID</th>
      <th scope="col" class="th-table">Creation Date</th>
      <th scope="col" class="th-table">Campaign Name</th>
      <th scope="col" class="th-table">Details</th>
      <th scope="col" class="th-table">Slot Number</th>
      <th scope="col" class="th-table">Slot Amount</th>
      <th scope="col" class="th-table">Joiners</th>
      <th scope="col" class="th-table">Status</th>
      <th scope="col" class="th-table b-r-none">Action</th>
    </ng-template>
    <!-- /. start of  dataTemplate -->
    <ng-template #dataTemplate>
      <ng-container *ngIf="http.apiDataState.isLoading">
        <tr *ngFor="let in of mock(10); let i = index">
          <td class="td-table b-l-none">
            <app-skeleton-loader Cwidth="20"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="80"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="150"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="150"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="b-r-none b-r-none">
            <app-skeleton-loader Cwidth="170"></app-skeleton-loader>
          </td>
        </tr>
      </ng-container>
      <ng-container
        *ngIf="!http.apiDataState.isLoading && searchKeyWord.length <= 0"
      >
        <tr
          *ngFor="
            let data of http.apiDataState.result.result.data;
            let i = index
          "
        >
          <td class="td-table b-l-none">
            {{ data.campaignId }}
          </td>
          <td class="td-table">
            {{ data.creationDate }}
          </td>
          <td class="td-table">
            {{ data.campaignName }}
          </td>
          <td class="td-table">
            {{ data.details }}
          </td>
          <td class="td-table">
            {{ data.slotNumber }}
          </td>
          <td class="td-table">
            {{ data.slotAmount }}
          </td>
          <td class="td-table">{{ data.joinersPercentage }}%</td>
          <td class="td-table">
            {{ data.status }}
          </td>
          <td class="b-r-none b-r-none d-flex">
            <a
              role="button"
              class="badge purple-bg text-white mx-1 text-size-action-button-table"
              [ngClass]="
                data.status != 'ACTIVE' ? 'disabled' : 'pointer-cursor'
              "
              (click)="clickDraw(data)"
              >Draw</a
            >
            <a
              role="button"
              [routerLink]="[
                myRoutes.modifyCampaign.path.replace(':id', data.uid)
              ]"
              class="badge green-bg text-white mx-1 text-size-action-button-table"
              >Modify</a
            >
            <a
              role="button"
              (click)="
                onClickTableNav(route.modifyJoinersCampaign.path, 'joiners')
              "
              [ngClass]="role == user ? 'disabled' : 'pointer-cursor'"
              class="badge purple-bg text-white mx-1 text-size-action-button-table"
              >Joiners</a
            >
            <a
              role="button"
              data-toggle="modal"
              data-target="#apppoved-preview"
              (click)="openApproveModal(data.uid)"
              class="badge red-bg text-white mx-1 text-size-action-button-table"
              >Approved</a
            >
            <!-- [ngClass]="
            role == user || role == marketing
              ? 'disabled'
              : 'pointer-cursor'
          " -->
            <a
              role="button"
              (click)="shareCampaign(data.uid)"
              class="badge blue-bg text-white mx-1 text-size-action-button-table"
              >Share</a
            >
          </td>
        </tr>
      </ng-container>
      <!-- /. api data -->
      <ng-container
        *ngIf="filteredData.length >= 1 && searchKeyWord.length >= 1"
      >
        <tr *ngFor="let data of filteredData; let i = index">
          <td class="td-table b-l-none">
            {{ data.campaignId }}
          </td>
          <td class="td-table">
            {{ data.creationDate }}
          </td>
          <td class="td-table">
            {{ data.campaignName }}
          </td>
          <td class="td-table">
            {{ data.details }}
          </td>
          <td class="td-table">
            {{ data.slotNumber }}
          </td>
          <td class="td-table">
            {{ data.slotAmount }}
          </td>
          <td class="td-table">{{ data.joinersPercentage }}%</td>
          <td class="td-table">
            {{ data.status }}
          </td>
          <td class="b-r-none b-r-none d-flex">
            <a
              role="button"
              class="badge purple-bg text-white mx-1 text-size-action-button-table"
              [ngClass]="
                data.status != 'ACTIVE' ? 'disabled' : 'pointer-cursor'
              "
              (click)="clickDraw(data)"
              >Draw</a
            >
            <a
              role="button"
              [routerLink]="[
                myRoutes.modifyCampaign.path.replace(':id', data.uid)
              ]"
              class="badge green-bg text-white mx-1 text-size-action-button-table"
              >Modify</a
            >
            <a
              role="button"
              (click)="
                onClickTableNav(route.modifyJoinersCampaign.path, 'joiners')
              "
              [ngClass]="role == user ? 'disabled' : 'pointer-cursor'"
              class="badge purple-bg text-white mx-1 text-size-action-button-table"
              >Joiners</a
            >
            <a
              role="button"
              data-toggle="modal"
              data-target="#apppoved-preview"
              (click)="openApproveModal(data.uid)"
              class="badge red-bg text-white mx-1 text-size-action-button-table"
              >Approved</a
            >
            <!-- [ngClass]="
       role == user || role == marketing
         ? 'disabled'
         : 'pointer-cursor'
     " -->
            <a
              role="button"
              (click)="
                onClickTableNav(route.modifyJoinersCampaign.path, 'share')
              "
              [ngClass]="role == user ? 'disabled' : 'pointer-cursor'"
              class="badge blue-bg text-white mx-1 text-size-action-button-table"
              >Share</a
            >
          </td>
        </tr>
      </ng-container>
    </ng-template>
    <!-- /. end of dataTemplate -->
  </app-my-table>
</div>

<!-- <app-my-modal
  title="are you sure do you want to approve?"
  modalId="approve-modal"
  (clickYes)="approvedCampaign()"
></app-my-modal> -->
<app-over-lay-loading
  *ngIf="approveHttp.apiDataState.isLoading"
></app-over-lay-loading>

<app-approved-preview> </app-approved-preview>
<!-- <app-start-raffle
  [hidden]="!isHiddenDraw"
  [selectedCampaign]="selectedCampaign"
  (closeDrawEmit)="toggleDrawHidden()"
></app-start-raffle> -->
