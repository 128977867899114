<div class="container">
  <div class="row mb-3">
    <div class="col-4 mt-4">
      <app-page-title pageTitle="StARTT RAFFLE DRAW"></app-page-title>
    </div>
  </div>
  <!--  /. row buttons -->
  <div class="row justify-content-center">
    <div class="col-8">
      <p class="form-label text-decoration-underline">Search Campaign:</p>
      <div class="row g-0 align-items-center mb-3">
        <div class="col-3">
          <label for="campaignName" class="col-form-label form-label"
            >Campaign Name:</label
          >
        </div>
        <div class="col">
          <input
            id="campaignName"
            class="form-control input-design-2"
            [value]="selectedCampaign.campaignName"
            readonly
          />
        </div>
      </div>
      <!-- /. campaign name -->
    </div>
    <!-- /.col -->
    <div class="col-8">
      <div class="row g-0 align-items-center mb-3">
        <div class="col-3">
          <label for="campaginRewards" class="col-form-label form-label"
            >Campaign ID:</label
          >
        </div>
        <div class="col">
          <input
            id="campaginRewards"
            class="form-control input-design-2"
            [value]="selectedCampaign.campaignId"
            readonly
          />
        </div>
      </div>
      <!-- /. campaign rewards -->
    </div>
    <!-- /.col -->
    <div class="col-10 mb-2">
      <div class="d-flex mb-3">
        <button
          class="btn my-btn-color-2 f-weight-6 align-self-center mx-auto btn-xs form-btn"
          type="button"
          (click)="startRaffle(false)"
        >
          GO
        </button>
      </div>
      <div class="d-flex">
        <button
          class="btn my-btn-color-2 f-weight-6 align-self-center mx-auto btn-xs form-btn"
          type="button"
          (click)="startRaffle(true)"
        >
          SAVE AND CLOSE DRAW
        </button>
      </div>
      <!-- /. d flex -->
    </div>
    <div class="col-10 mb-3">
      <hr />
      <p class="my-1 form-label text-center">
        Campaign Name: {{ selectedCampaign.campaignName }}
      </p>
      <p class="my-1 form-label text-center">
        Slot Number: 1-{{ selectedCampaign.slotNumber }}
      </p>
      <hr />
    </div>

    <div class="col-8 mb-3">
      <div class="row g-0 justify-content-start">
        <div class="col-3">
          <label class="form-label col-form-label">Upload Excel File:</label>
        </div>
        <!-- /.col -->
        <div class="col-auto">
          <div class="d-flex">
            <button
              class="btn my-btn-color-1 align-self-center mx-auto btn-xs"
              type="button"
              (click)="openInputFile()"
            >
              Browse
            </button>

            <input
              id="excel"
              type="file"
              (change)="onFileSelected($event)"
              hidden
            />
          </div>

          <!-- /. d flex -->
        </div>
        <div class="col-12" *ngIf="uploadFile != null">
          <span>{{ uploadFile.name }}</span>
        </div>
        <!-- /.col -->
        <div class="col-12">
          <p class="my-1 form-label">
            Reference Number:
            {{
              result.referenceNumber.length >= 1
                ? result.referenceNumber
                : "TBD"
            }}
          </p>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
      <div class="row align-items-end">
        <div class="col-8 content-bottom-color text-center f-weight-7 p-0">
          <app-my-table [dataTemplate]="dataTemplate" [settings]="settings">
            <ng-template #dataTemplate>
              <tr>
                <td class="td-table">DATE :</td>
                <td class="td-table" colspan="2">
                  {{ result.date.length >= 1 ? result.date : "TBD" }}
                </td>
              </tr>
              <tr>
                <td class="td-table">TIME OF DRAW :</td>
                <td class="td-table" colspan="2">
                  {{
                    result.timeOfDraw.length >= 1 ? result.timeOfDraw : "TBD"
                  }}
                </td>
              </tr>
              <tr>
                <td class="td-table">SLOT :</td>
                <td class="td-table" colspan="2">
                  {{ result.slot != 0 ? result.slot : "TBD" }}
                </td>
              </tr>
              <tr>
                <td class="td-table">Draw</td>
                <td class="td-table">Slot</td>
                <td class="td-table">Winner</td>
              </tr>
              <ng-container *ngIf="result.drawSlotWinners.length >= 1">
                <tr *ngFor="let data of result.drawSlotWinners; let i = index" class="">
                  <td class="td-table"> {{data.draw}} </td>
                  <td class="td-table"> {{data.slot}} </td>
                  <td class="td-table"> {{data.winner}} </td>
                </tr>
              </ng-container>
            </ng-template>
          </app-my-table>
        </div>
        <div class="col-4 mb-3">
          <div class="d-block">
            <!-- <button
              class="btn my-btn-color-2 align-self-center mx-auto mb-3 side-btn"
              type="button"
              (click)="startRaffle(true)"
            >
              SAVE AND CLOSE DRAW
            </button> -->
            <button
              class="btn my-btn-outline-1 align-self-center mx-auto mb-3 side-btn"
              type="button"
              (click)="closeDraw()"
            >
              Cancel
            </button>
          </div>
          <!-- /. d flex -->
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.col  -->
  </div>
  <!-- /.row form -->
</div>
<app-over-lay-loading
  *ngIf="http.apiDataState.isLoading"
></app-over-lay-loading>
