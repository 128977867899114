import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GlobalTableInterce } from 'src/app/core/interface/global_table_interface';
import { MyTableOptionsInterface } from 'src/app/core/interface/my_table_data_interface';
import { apiUrl } from 'src/app/core/utils/api_url';
import { ApiHelperService } from '../../../core/services/api-helper/api-helper.service';
import { CampaignInterface } from '../interface/campaign_table_interface';
import { EmptyResultInterface } from '../../../core/interface/empty_result_interface';
import { myRouter } from '../../../core/route/my_route';
import { MockService } from 'src/app/core/services/mock/mock.service';
import { ApprovedPreviewComponent } from './approved-preview/approved-preview.component';
import { environment } from 'src/environments/environment';
import { CampaignFilterInterface } from './interface/campaign_filter_interface';
import { SweetAlert2Service } from 'src/app/core/services/sweet-alert2/sweet_alert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-campaign-table',
  templateUrl: './campaign-table.component.html',
  styleUrls: ['./campaign-table.component.scss'],
  providers: [ApiHelperService],
})
export class CampaignTableComponent implements OnInit {
  myRoutes = myRouter;
  userUrl = environment.userUrl;

  @Input() isHiddenDraw: boolean = false;

  @Output() toggleHiddenDrawParent: EventEmitter<any> = new EventEmitter();

  selectedCampaignId: number;
  tableOptionsResponse: MyTableOptionsInterface = {
    items: 0,
    size: 0,
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    hasNextPage: false,
    nextPage: 0,
    isLoading: false,
  };

  tableSizeAndPage = {
    page: 1,
    size: 10,
  };

  mock: Function;

  searchKeyWord: any = '';
  filteredData: CampaignInterface[] = [];
  searchTypes: string[] = ['campaignId', 'campaignName', 'details'];

  selectedCampaign: CampaignInterface = {
    uid: 0,
    campaignId: '',
    creationDate: '',
    campaignName: '',
    details: '',
    slotNumber: 0,
    slotAmount: 0,
    joinersPercentage: 0,
    status: '',
  };

  tableFilters: CampaignFilterInterface = {
    campaignName: null,
    campaignId: null,
    dateFrom: null,
    dateTo: null,
    status: null,
    search: this.tableSizeAndPage.size,
    sortBy: {
      creationDate: 'desc',
    },
  };

  @ViewChild(ApprovedPreviewComponent) previewChild: ApprovedPreviewComponent;

  constructor(
    private mockData: MockService,
    public http: ApiHelperService<GlobalTableInterce<[CampaignInterface]>>,
    public approveHttp: ApiHelperService<EmptyResultInterface>,
    private swal: SweetAlert2Service,
    private router: Router
  ) {
    this.mock = this.mockData.createMockData;
  }

  ngOnInit(): void {
    this.getTableData({});
  }
  
  clickDraw(data: CampaignInterface): void {
    if (data.status != 'ACTIVE') {
      this.swal.toastInfo('campaign status not active');
      return;
    }
    const convertedData = btoa(JSON.stringify(data));
    console.log(convertedData);
    this.router.navigate([
      `/admin/draw/${convertedData}`,
    ]);
  }

  toggleDrawHidden(): void {
    this.isHiddenDraw = !this.isHiddenDraw;
    this.toggleHiddenDrawParent.emit();
  }

  setFilterData(data: CampaignFilterInterface): void {
    this.tableFilters = data;
    this.getTableData({});
  }

  searchTable(seachInputs: any): void {
    this.searchKeyWord = seachInputs['keyword'];
    const data = this.http.apiDataState.result.result.data;
    const result = data.filter(
      (item) => item[seachInputs['type']] == seachInputs['keyword']
    );
    if (result.length <= 0) {
      this.filteredData = [];
      return;
    }
    this.filteredData = result;
  }

  getTableData(filter: any): void {
    const tableSizeAndPage = this.tableSizeAndPage;
    this.http.post({
      url: `${apiUrl.getCampaigns}?page=${tableSizeAndPage.page}&size=${tableSizeAndPage.size}`,
      data: this.tableFilters,
      successCallBack: this.callBackSuccess,
    });
  }

  openApproveModal(id: number): void {
    this.selectedCampaignId = id;
    this.previewChild.getOneCampaign(id.toString());
  }
  approvedCampaign() {
    this.http.put({
      url: apiUrl.approveCampginStatus.replace(
        ':id',
        this.selectedCampaignId.toString()
      ),
      data: {
        isApproved: true,
      },
      successCallBack: this.resetData,
      failCallBack: this.resetData,
    });
  }

  resetData = (): void => {
    setTimeout(() => {
      this.tableSizeAndPage.page = 1;
      this.tableSizeAndPage.size = 10;
      this.getTableData({});
    }, 1000);
  };

  navigatePagination(pageNumber: number): void {
    console.log(pageNumber, 'from parent');
    this.tableSizeAndPage.page = pageNumber;
    this.getTableData({});
  }

  onChangeTableSize(pageSize: number): void {
    console.log(pageSize, 'from parent');
    this.tableSizeAndPage.size = pageSize;
    this.getTableData({});
  }

  callBackSuccess = (): void => {
    let tableOptionsResponse = this.tableOptionsResponse;
    let tableData = this.http.apiDataState.result.result;
    tableOptionsResponse.totalItems = tableData.totalItems;
    tableOptionsResponse.totalPages = tableData.totalPages;
    tableOptionsResponse.currentPage = tableData.currentPage;
    tableOptionsResponse.size = tableData.size;
    tableOptionsResponse.hasNextPage = tableData.hasNextPage;
  };

  shareCampaign(id) {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${this.userUrl}login/${id}`,
      '_black',
      'location=yes,height=570,width=520,scrollbars=yes,status=yes'
    );
  }
}
