import { MenuInterface } from './../interface/menu_interface';
import { CampaignComponent } from 'src/app/features/campaign/campaign.component';
import { StartRaffleComponent } from 'src/app/features/campaign/start-raffle/start-raffle.component';
import { TransactionComponent } from 'src/app/features/transaction/transaction.component';
import { ManageUserComponent } from 'src/app/features/manage-user/manage-user.component';
import { AboutUsComponent } from 'src/app/features/website-content/about-us/about-us.component';
import { FaqComponent } from 'src/app/features/website-content/faq/faq.component';
import { HowItWorksComponent } from 'src/app/features/website-content/how-it-works/how-it-works.component';
import { PortalAccountsComponent } from 'src/app/features/portal-accounts/portal-accounts.component';
import { CreateCampaignComponent } from 'src/app/features/campaign/create-campaign/create-campaign.component';
import { JoinersCampaignComponent } from 'src/app/features/campaign/joiners-campaign/joiners-campaign.component';
import { ModifyCampaignComponent } from 'src/app/features/campaign/modify-campaign/modify-campaign.component';
import { ViewUserTransactionsComponent } from 'src/app/features/manage-user/view-user-transactions/view-user-transactions.component';
import { roleTypes } from '../utils/role_enum';
import { AdsComponent } from 'src/app/features/website-content/ads/ads.component';
import { TableEventsComponent } from 'src/app/features/ngoEvents/table-events/table-events.component';
import { ModifyEventsComponent } from 'src/app/features/ngoEvents/modify-events/modify-events.component';
import { CreateEventsComponent } from 'src/app/features/ngoEvents/create-events/create-events.component';
import { DonationsReportComponent } from 'src/app/features/donations-report/donations-report.component';

const assetEntry = '/assets/side/';
const routeEntry = '/admin/';
export class myRouter {
  private static _instance = new myRouter();
  private constructor() {}

  static get instance() {
    return this._instance;
  }
  // transaction start

  // ... label only ...
  public static transaction: MenuInterface = {
    name: 'Transaction',
    status: false,
    asset: `${assetEntry}dashboard.png`,
  };

  // transaction menus
  public static viewCashInTransaction: MenuInterface = {
    name: 'View cash in transactions',
    status: false,
    path: `${routeEntry}${TransactionComponent.routeName}`,
    notAllowedRole: [roleTypes.User,],

  };

  // donation
  public static viewDonationsReport: MenuInterface = {
    name: 'View Donations Report',
    status: false,
    path: `${routeEntry}${DonationsReportComponent.routeName}`,
    notAllowedRole: [roleTypes.User,],

  };

  public static viewTransactionUser: MenuInterface = {
    name: 'view transaction user',
    status: false,
    asset: `${assetEntry}user.png`,
    path: `${routeEntry}${ViewUserTransactionsComponent.routeName}`,
    notAllowedRole: [roleTypes.User,],

  };
  // transaction end

  // campaign start

  // ... label only ...
  public static campaign: MenuInterface = {
    name: 'Manage Campaign',
    status: false,
    asset: `${assetEntry}campaign.png`,
  };
  // sub menu start
  public static campaignTable: MenuInterface = {
    name: 'View Campaigns (Create and Modify)',
    status: false,
    path: `${routeEntry}${CampaignComponent.routeName}`,
    notAllowedRole: [roleTypes.User,],
  };

  public static createEvents: MenuInterface = {
    name: 'create events',
    status: false,
    asset: `${assetEntry}campaign.png`,
    path: `${routeEntry}${CreateEventsComponent.routeName}`,
    notAllowedRole: [roleTypes.User,],

  };

  public static eventsTable: MenuInterface = {
    name: 'View Events (Create and Modify)',
    status: false,
    path: `${routeEntry}${TableEventsComponent.routeName}`,
    notAllowedRole: [roleTypes.User,],

  };
  public static modifyEvent: MenuInterface = {
    name: 'modifiy campaign',
    status: false,
    asset: `${assetEntry}campaign.png`,
    path: `${routeEntry}${ModifyEventsComponent.routeName}`,
    notAllowedRole: [roleTypes.User,],

  };

  public static campaignRaffle: MenuInterface = {
    name: 'Start Raffle Draw',
    status: false,
    path: `${routeEntry}${StartRaffleComponent.routeName}`,
    notAllowedRole: [roleTypes.User],
  };

  // sub menu end

  public static createCampaign: MenuInterface = {
    name: 'create campaign',
    status: false,
    asset: `${assetEntry}campaign.png`,
    path: `${routeEntry}${CreateCampaignComponent.routeName}`,
    notAllowedRole: [roleTypes.User],
  };

  public static modifyCampaign: MenuInterface = {
    name: 'modifiy campaign',
    status: false,
    asset: `${assetEntry}campaign.png`,
    path: `${routeEntry}${ModifyCampaignComponent.routeName}`,
    notAllowedRole: [roleTypes.User],
  };

  public static modifyJoinersCampaign: MenuInterface = {
    name: 'modifiy joiners campaign',
    status: false,
    asset: `${assetEntry}campaign.png`,
    path: `${routeEntry}${JoinersCampaignComponent.routeName}`,
    notAllowedRole: [roleTypes.User],
  };
  // campaign end

  // portal start

  // ... label only ...
  public static portal: MenuInterface = {
    name: 'Portal Account',
    status: false,
    asset: `${assetEntry}portal.png`,
  };

  // submenu start
  public static portalTable: MenuInterface = {
    name: 'View Accounts (Create and Modify)',
    status: false,
    path: `${routeEntry}${PortalAccountsComponent.routeName}`,
    notAllowedRole: [
      roleTypes.User,
      roleTypes.Marketing,
      roleTypes.MarketingManger,
      roleTypes.Marketing_Manager,
      roleTypes.Accounting,
      roleTypes.Audit,

    ],
  };
  // submenu end
  // portal end

  // submenu start
  public static ads: MenuInterface = {
    name: 'Ads',
    status: false,
    path: `${routeEntry}${AdsComponent.routeName}`,
    notAllowedRole: [
      roleTypes.Accounting,
    ],
  };
  // submenu end
  // portal end

  // user start

  // ... label only ...
  public static user: MenuInterface = {
    name: 'User Account',
    status: false,
    asset: `${assetEntry}user.png`,
  };
  // submenu start
  public static userTable: MenuInterface = {
    name: 'View Accounts and Transactions (Edit, Enable and Disable)',
    status: false,
    path: `${routeEntry}${ManageUserComponent.routeName}`,
    notAllowedRole: [
      roleTypes.User,
      roleTypes.Marketing,
    ],

  };
  
  // submenu end

  // user end

  // websiteContent start

  // ... label only ...

  public static websiteContent: MenuInterface = {
    name: 'Change Website Contents',
    status: false,
    asset: `${assetEntry}website.png`,
  };

  // submenu start
  public static howItWorks: MenuInterface = {
    name: 'How it works',
    status: false,
    path: `${routeEntry}${HowItWorksComponent.routeName}`,
    notAllowedRole: [
      roleTypes.Accounting,
    ],
  };
  public static aboutUs: MenuInterface = {
    name: 'About Us',
    status: false,
    path: `${routeEntry}${AboutUsComponent.routeName}`,
    notAllowedRole: [
      roleTypes.Accounting,
    ],
  };

  public static faq: MenuInterface = {
    name: 'More FAQs',
    status: false,
    path: `${routeEntry}${FaqComponent.routeName}`,
    notAllowedRole: [
      roleTypes.Accounting,
    ],
  };

  // submenu end

  // websiteContent end
}

export const myRoutesInstance = myRouter.instance;
