import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MyFooterComponent } from './core/components/my-footer/my-footer.component';
import { AdminLayoutComponent } from './core/components/admin-layout/admin-layout.component';
import { MySideNavComponent } from './core/components/my-side-nav/my-side-nav.component';
import { MyHeaderNavComponent } from './core/components/my-header-nav/my-header-nav.component';
import { LoginComponent } from './features/login/login.component';
import { HeaderVersionTwoComponent } from './core/components/header-version-two/header-version-two.component';
import { TransactionComponent } from './features/transaction/transaction.component';
import { MyTableComponent } from './core/components/my-table/my-table.component';
import { CampaignComponent } from './features/campaign/campaign.component';
import { StartRaffleComponent } from './features/campaign/start-raffle/start-raffle.component';
import { PortalAccountsComponent } from './features/portal-accounts/portal-accounts.component';
import { DownloadButtonComponent } from './core/components/download-button/download-button.component';
import { ManageUserComponent } from './features/manage-user/manage-user.component';
import { DropZoneDirective } from './core/directives/drop-zone/drop-zone.directive';
import { AboutUsComponent } from './features/website-content/about-us/about-us.component';
import { PageTitleComponent } from './core/components/page-title/page-title.component';
import { HowItWorksComponent } from './features/website-content/how-it-works/how-it-works.component';
import { FaqComponent } from './features/website-content/faq/faq.component';
import { CreateCampaignComponent } from './features/campaign/create-campaign/create-campaign.component';
import { JoinersCampaignComponent } from './features/campaign/joiners-campaign/joiners-campaign.component';
import { ModifyCampaignComponent } from './features/campaign/modify-campaign/modify-campaign.component';
import { ViewUserTransactionsComponent } from './features/manage-user/view-user-transactions/view-user-transactions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DotLoadingHandlerComponent } from './core/components/dot-loading-handler/dot-loading-handler.component';
import { CreateUserComponent } from './features/manage-user/create-user/create-user.component';
import { CreatePortalAccountComponent } from './features/portal-accounts/create-portal-account/create-portal-account.component';
import { UpdatePortalAccountComponent } from './features/portal-accounts/update-portal-account/update-portal-account.component';
import { SkeletonLoaderComponent } from './core/components/skeleton-loader/skeleton-loader.component';
import { PortalTableComponent } from './features/portal-accounts/portal-table/portal-table.component';
import { FilterSvgComponent } from './core/components/filter-svg/filter-svg.component';
import { DeletePortalAccountComponent } from './features/portal-accounts/delete-portal-account/delete-portal-account.component';
import { MyButtonComponent } from './core/components/my-button/my-button.component';
import { OverLayLoadingComponent } from './core/components/over-lay-loading/over-lay-loading.component';
import { CampaignTableComponent } from './features/campaign/campaign-table/campaign-table.component';
import { MyModalComponent } from './core/components/my-modal/my-modal.component';
import { ApprovedPreviewComponent } from './features/campaign/campaign-table/approved-preview/approved-preview.component';
import { ManageUserTableComponent } from './features/manage-user/manage-user-table/manage-user-table.component';
import { DisableModalComponent } from './core/components/disable-modal/disable-modal.component';
import { ModifyUserComponent } from './features/manage-user/modify-user/modify-user.component';
import { FaqListComponent } from './features/website-content/faq/faq-list/faq-list.component';
import { FaqCreateNewComponent } from './features/website-content/faq/faq-create-new/faq-create-new.component';
import { AddTestimonyComponent } from './features/website-content/about-us/add-testimony/add-testimony.component';
import { DeleteFaqComponent } from './features/website-content/faq/delete-faq/delete-faq.component';
import { ModifyFaqComponent } from './features/website-content/faq/modify-faq/modify-faq.component';
import { AdsComponent } from './features/website-content/ads/ads.component';
import { AdsTableComponent } from './features/website-content/ads/ads-table/ads-table.component';
import { AdsFormComponent } from './features/website-content/ads/ads-form/ads-form.component';
import { AdsDisableComponent } from './features/website-content/ads/ads-table/ads-disable/ads-disable.component';
import { AdsEnableComponent } from './features/website-content/ads/ads-table/ads-enable/ads-enable.component';
import { TransactionTableComponent } from './features/transaction/transaction-table/transaction-table.component';
import { TableEventsComponent } from './features/ngoEvents/table-events/table-events.component';
import { CreateEventsComponent } from './features/ngoEvents/create-events/create-events.component';
import { ModifyEventsComponent } from './features/ngoEvents/modify-events/modify-events.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { DonationsReportComponent } from './features/donations-report/donations-report.component';
import { DonationsTableComponent } from './features/donations-report/donations-table/donations-table.component';
import { AddImageAdsComponent } from './features/website-content/ads/ads-table/add-image-ads/add-image-ads.component';

@NgModule({
  declarations: [
    AppComponent,
    MyFooterComponent,
    AdminLayoutComponent,
    MySideNavComponent,
    MyHeaderNavComponent,
    LoginComponent,
    HeaderVersionTwoComponent,
    TransactionComponent,
    MyTableComponent,
    CampaignComponent,
    StartRaffleComponent,
    PortalAccountsComponent,
    DownloadButtonComponent,
    ManageUserComponent,
    DropZoneDirective,
    AboutUsComponent,
    PageTitleComponent,
    HowItWorksComponent,
    FaqComponent,
    CreateCampaignComponent,
    JoinersCampaignComponent,
    ModifyCampaignComponent,
    ViewUserTransactionsComponent,
    DotLoadingHandlerComponent,
    CreateUserComponent,
    CreatePortalAccountComponent,
    UpdatePortalAccountComponent,
    SkeletonLoaderComponent,
    PortalTableComponent,
    FilterSvgComponent,
    DeletePortalAccountComponent,
    MyButtonComponent,
    OverLayLoadingComponent,
    CampaignTableComponent,
    MyModalComponent,
    ApprovedPreviewComponent,
    ManageUserTableComponent,
    DisableModalComponent,
    ModifyUserComponent,
    FaqListComponent,
    FaqCreateNewComponent,
    AddTestimonyComponent,
    DeleteFaqComponent,
    ModifyFaqComponent,
    AdsComponent,
    AdsTableComponent,
    AdsFormComponent,
    AdsDisableComponent,
    AdsEnableComponent,
    TransactionTableComponent,
    TableEventsComponent,
    CreateEventsComponent,
    ModifyEventsComponent,
    DonationsReportComponent,
    DonationsTableComponent,
    AddImageAdsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSummernoteModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
