import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { EmptyResultInterface } from 'src/app/core/interface/empty_result_interface';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { apiUrl } from 'src/app/core/utils/api_url';

@Component({
  selector: 'app-delete-portal-account',
  templateUrl: './delete-portal-account.component.html',
  styleUrls: ['./delete-portal-account.component.scss'],
  providers: [ApiHelperService],
})
export class DeletePortalAccountComponent implements OnInit {
  @Input() userId: number;

  @Output() triggerRefresh: EventEmitter<any> = new EventEmitter();

  constructor(public http: ApiHelperService<EmptyResultInterface>) {}

  ngOnInit(): void {}

  deleteUser() {
    let id = this.userId.toString();
    this.http.delete({
      url: `${apiUrl.deletePortalAccount.replace(':id', id)}`,
      successCallBack: this.successCallBack,
    });
  }

  successCallBack = (): void => {
    const elem = document.getElementById('close-modal-delete');
    elem.click();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  refreshData(): void {
    this.triggerRefresh.emit();
  }
}
