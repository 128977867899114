import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { myRouter } from 'src/app/core/route/my_route';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { MockService } from 'src/app/core/services/mock/mock.service';
import { apiUrl } from 'src/app/core/utils/api_url';
import { UserTableInterface } from '../interface/user_table_interface';
import { TransactionDetailsInterface } from './interface/transaction_details_interface';
import { Location } from '@angular/common';
import { DownloadButtonComponent } from 'src/app/core/components/download-button/download-button.component';
import { ExportExcelService } from 'src/app/core/services/export-excel/export-excel.service';
import { ExcelInterface } from 'src/app/core/services/export-excel/excel_interface';
@Component({
  selector: 'app-view-user-transactions',
  templateUrl: './view-user-transactions.component.html',
  styleUrls: ['./view-user-transactions.component.scss'],
  providers: [ApiHelperService],
})
export class ViewUserTransactionsComponent implements OnInit {
  static readonly routeName: string = 'view-transaction-user/:id';
  mock: Function;
  /// todo
  /// temp solution only
  route = myRouter;
  selectedUser: UserTableInterface = {
    id: 0,
    firstName: '',
    middleName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    status: '',
    lastModified: '',
    registrationDate: '',
  };
  pageTitle: string = '';
  @ViewChild(DownloadButtonComponent)
  downloadComponent: DownloadButtonComponent;

  dataResponse: [TransactionDetailsInterface];

  userId: string = '';
  dataApi: any = {
    search: 'wallet',
    sortBy: {
      status: 'desc',
    },
  };
  downloadApi: string = '';

  constructor(
    private mockData: MockService,
    private actRoute: ActivatedRoute,
    public http: ApiHelperService<[TransactionDetailsInterface]>,
    private location: Location,
    public ete: ExportExcelService
  ) {
    this.mock = this.mockData.createMockData;
    const data = JSON.parse(
      atob(actRoute.snapshot.params['id'])
    ) as UserTableInterface;
    this.selectedUser = data;
    this.pageTitle = `VIEW TRANSACTION FOR (${data.firstName} ${data.lastName})`;
    this.userId = data.id.toString();
    this.getTnxData(data.id.toString());
    this.downloadApi = apiUrl.downloadTnxData.replace(':id', this.userId);
  }

  ngOnInit(): void {}

  goBack(): void {
    this.location.back();
  }


  clickDownload(): void {
    const user = this.selectedUser;
    let reportData:ExcelInterface = {
      fileName: `${user.firstName}-${user.lastName}`,
      data: this.dataResponse,
    }
    this.ete.exportExcel(reportData);
  }

  getTnxData(uid: string): void {
    this.http.post({
      url: apiUrl.getTnxData.replace(':id', uid),
      data: {
        search: 'wallet',
        sortBy: {
          status: 'desc',
        },
      },
      successCallBack: this.successCallBack,
    });
  }

  successCallBack = (): void => {
    const result = this.http.apiDataState.result.result;
    console.log(result, 'result');
    this.dataResponse = result;
  };
}
