<button [class]="buttonClass" (click)="onClickButton()" [type]="buttonType" [disabled]="isDisabled">
  <div *ngIf="isLoading" class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div *ngIf="!isLoading">
    {{ title }}
  </div>
</button>
