import { Component, OnInit } from '@angular/core';
import { EmptyResultInterface } from 'src/app/core/interface/empty_result_interface';
import { GlobalTableInterce } from 'src/app/core/interface/global_table_interface';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { MockService } from 'src/app/core/services/mock/mock.service';
import { apiUrl } from 'src/app/core/utils/api_url';
import { NgoEventsTableInterface } from '../interface/ngo_event_interface';
import { myRouter } from 'src/app/core/route/my_route';
import { MyTableOptionsInterface } from 'src/app/core/interface/my_table_data_interface';
import { SweetAlert2Service } from 'src/app/core/services/sweet-alert2/sweet_alert2';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgoEventsFilterInterface } from '../interface/ngo_event_filter_interface';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage-service';


@Component({
  selector: 'app-table-events',
  templateUrl: './table-events.component.html',
  styleUrls: ['./table-events.component.scss'],
  providers: [ApiHelperService, DatePipe],
})
export class TableEventsComponent implements OnInit {
  static readonly routeName: string = 'table-events';
  myRoutes = myRouter;
  mock: Function;

  role: string = '';
  user: string = 'User';
  marketing: string = 'Marketing';

  tableOptionsResponse: MyTableOptionsInterface = {
    items: 0,
    size: 0,
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    hasNextPage: false,
    nextPage: 0,
    isLoading: false,
  };

  tableSizeAndPage = {
    page: 1,
    size: 10,
  };
  remarks: String = '';

  selectedEventId: String;
  modeType: String;

  // filter table start
  filterForm = this.formBuilder.group({
    eventId: [''],
    dateFrom: [''],
    dateTo: [''],
    status: [''],
  });
  // filter table end
  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private swal: SweetAlert2Service,
    private mockData: MockService,
    private storage: LocalStorageService,
    public approveHttp: ApiHelperService<EmptyResultInterface>,
    public http: ApiHelperService<GlobalTableInterce<[NgoEventsTableInterface]>>
  ) {
    this.mock = this.mockData.createMockData;
  }

  ngOnInit(): void {
    this.getTableData({});
    this.role = this.storage.role();
  }

  submitFilter(): void {
    const filterForm = this.filterControl;
    let filterObject: NgoEventsFilterInterface = {
      dateFrom: null,
      dateTo: null,
      status: null,
      eventId: null,
      sortBy: {
        creationDate: 'desc',
      },
    };
    if (filterForm.dateFrom.value.length >= 1) {
      console.log(this.newDateFormat(filterForm.dateFrom.value));
      filterObject.dateFrom = this.newDateFormat(filterForm.dateFrom.value);
    }
    if (filterForm.dateTo.value.length >= 1) {
      console.log(this.newDateFormat(filterForm.dateTo.value));
      filterObject.dateTo = this.newDateFormat(filterForm.dateTo.value);
    }
    if (filterForm.eventId.value.length >= 1) {
      filterObject.eventId = filterForm.eventId.value;
    }
    if (filterForm.status.value.length >= 1) {
      filterObject.status = filterForm.status.value;
    }
    console.log('test');
    this.getTableData(filterObject);
  }

  newDateFormat(dateTime: string): string {
    return this.datePipe.transform(dateTime, 'yyyy-MM-dd');
  }

  get filterControl() {
    return this.filterForm.controls;
  }

  navigatePagination(pageNumber: number): void {
    console.log(pageNumber, 'from parent');
    this.tableSizeAndPage.page = pageNumber;
    this.getTableData({});
  }

  onChangeTableSize(pageSize: number): void {
    console.log(pageSize, 'from parent');
    this.tableSizeAndPage.size = pageSize;
    this.getTableData({});
  }

  getTableData(filter: any): void {
    const tableSizeAndPage = this.tableSizeAndPage;
    console.log(filter, 'test');
    console.log(
      `${apiUrl.getNgoEvents}?page=${tableSizeAndPage.page}&size=${tableSizeAndPage.size}`,
      'test'
    );
    this.http.post({
      url: `${apiUrl.getNgoEvents}?page=${tableSizeAndPage.page}&size=${tableSizeAndPage.size}`,
      data:
        Object.keys(filter).length === 0
          ? {
              sortBy: {
                creationDate: 'desc',
              },
            }
          : filter,
      successCallBack: this.callBackSuccess,
    });
  }

  callBackSuccess = (): void => {
    let tableOptionsResponse = this.tableOptionsResponse;
    let tableData = this.http.apiDataState.result.result;
    tableOptionsResponse.totalItems = tableData.totalItems;
    tableOptionsResponse.totalPages = tableData.totalPages;
    tableOptionsResponse.currentPage = tableData.currentPage;
    tableOptionsResponse.size = tableData.size;
    tableOptionsResponse.hasNextPage = tableData.hasNextPage;
  };

  actionEvent(): void {
    console.log(this.modeType, this.selectedEventId);
    if (this.modeType == 'disable') {
      if (this.remarks.length <= 0) {
        this.swal.toastInfo('Remarks is required.');
        return;
      }
      console.log('disable');
      this.approveHttp.put({
        data: {
          remarks: this.remarks,
        },
        url: apiUrl.actionsEvent
          .replace(':id', this.selectedEventId.toString())
          .replace(':action', this.modeType.toString()),
        successCallBack: this.resetData,
        // failCallBack: this.resetData,
      });
      return;
    }
    this.approveHttp.get({
      url: apiUrl.actionsEvent
        .replace(':id', this.selectedEventId.toString())
        .replace(':action', this.modeType.toString()),
      successCallBack: this.resetData,
      failCallBack: this.resetData,
    });
  }

  resetData = (): void => {
    setTimeout(() => {
      this.tableSizeAndPage.page = 1;
      this.tableSizeAndPage.size = 10;
      this.getTableData({});
      if (this.modeType == 'disable') {
        const closeBtn = document.getElementById('reject-modal-close');
        closeBtn.click();
        this.remarks = '';
      }
    }, 1000);
  };

  openModal(id: string | null, modeType: string): void {
    console.log('Event ID:', id);
    console.log('Mode Type:', modeType);
    if (!id) {
      console.error('Event ID is null or undefined');
      return;
    }
    this.selectedEventId = id;
    this.modeType = modeType;
  }

  isRestrictedRole(): boolean {
    const restrictedRoles = ['Marketing', 'Accounting', 'Audit'];
    return restrictedRoles.includes(this.role);
  }
  isRestrictedRole1(): boolean {
    const restrictedRoles = ['Audit'];
    return restrictedRoles.includes(this.role);
  }
  
  
  
}
