import { Component, OnInit } from '@angular/core';
import { myRouter } from 'src/app/core/route/my_route';

@Component({
  selector: 'app-joiners-campaign',
  templateUrl: './joiners-campaign.component.html',
  styleUrls: ['./joiners-campaign.component.scss'],
})
export class JoinersCampaignComponent implements OnInit {
  static readonly routeName: string = 'modify-joiners-campaign';
  /// todo
  /// temp solution only
  route = myRouter;

  constructor() {}

  ngOnInit(): void {}
}
