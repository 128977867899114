import { Component, OnInit, ViewChild } from '@angular/core';
import { MockService } from '../../core/services/mock/mock.service';
import { myRouter } from 'src/app/core/route/my_route';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage-service';
import { SweetAlert2Service } from 'src/app/core/services/sweet-alert2/sweet_alert2';
import { Router } from '@angular/router';
import { ApiHelperService } from '../../core/services/api-helper/api-helper.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CampaignTableComponent } from './campaign-table/campaign-table.component';
import { DatePipe } from '@angular/common';
import { CampaignFilterInterface } from './campaign-table/interface/campaign_filter_interface';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
  providers: [ApiHelperService, DatePipe],
})
export class CampaignComponent implements OnInit {
  static readonly routeName: string = 'campaign';
  // todo create model for table
  tableTitle: string[];
  tableActions: any;
  mock: Function;
  /// todo
  /// temp solution only
  route = myRouter;
  role: string = '';
  user: string = 'User';
  marketing: string = 'Marketing';
  isHiddenDraw: boolean = false;

  filterForm = this.formBuilder.group({
    campaignName: [''],
    campaignId: [''],
    dateFrom: [''],
    dateTo: [''],
    status: [''],
  });

  @ViewChild(CampaignTableComponent) tableChild: CampaignTableComponent;

  constructor(
    private mockData: MockService,
    private storage: LocalStorageService,
    private swal: SweetAlert2Service,
    private router: Router,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.mock = this.mockData.createMockData;
  }

  ngOnInit(): void {
    this.role = this.storage.role();
  }

  toggleHiddenDraw(): void {
    this.isHiddenDraw = !this.isHiddenDraw;
  }

  newDateFormat(dateTime: string): string {
    return this.datePipe.transform(dateTime, 'yyyy-MM-dd');
  }

  get filterControl() {
    return this.filterForm.controls;
  }

  submitFilter() {
    const filterForm = this.filterControl;
    let filterObject: CampaignFilterInterface = {
      dateFrom: null,
      dateTo: null,
      status: null,
      campaignName: null,
      campaignId: null,
      sortBy: {
        creationDate: 'desc',
      },
    };

    if (filterForm.dateFrom.value.length >= 1) {
      console.log(this.newDateFormat(filterForm.dateFrom.value));
      filterObject.dateFrom = this.newDateFormat(filterForm.dateFrom.value);
    }
    if (filterForm.dateTo.value.length >= 1) {
      console.log(this.newDateFormat(filterForm.dateTo.value));
      filterObject.dateTo = this.newDateFormat(filterForm.dateTo.value);
    }
    if (filterForm.campaignName.value.length >= 1) {
      filterObject.campaignName = filterForm.campaignName.value;
    }
    if (filterForm.campaignId.value.length >= 1) {
      filterObject.campaignId = filterForm.campaignId.value;
    }
    if (filterForm.status.value.length >= 1) {
      filterObject.status = filterForm.status.value;
    }
    this.tableChild.setFilterData(filterObject);
  }

  goToCreate(): void {
    console.log('click');
  }
  onClickTableNav(url: string, typeBtn: string) {
    //user
    if (this.role == this.user) {
      this.swal.toastWarning('permission not allowed');
      return;
    }

    //marketing
    if (this.role == this.marketing && typeBtn == 'approved') {
      this.swal.toastWarning('permission not allowed');
      return;
    }
    this.router.navigateByUrl(url);
  }
}
