<div class="container">
  <div class="row mb-3">
    <div class="col-4 mt-4">
      <app-page-title pageTitle="Manage Events"></app-page-title>
    </div>
    <!-- /.col -->
    <div class="col mt-4 align-self-end" *ngIf="!isRestrictedRole1()">
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <a
          [routerLink]="myRoutes.createEvents.path"
          role="button"
          class="my-btn-color-2 text-white px-2 text-decoration-none f-weight-6"
          type="button"
        >
          Create New
        </a>
      </div>
    </div>
    <!-- /.col -->
  </div>
  <!--  /. row buttons -->
  <form class="row" [formGroup]="filterForm" (ngSubmit)="submitFilter()">
    <div class="col-6">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="eventId" class="col-form-label form-label"
            >Event ID:</label
          >
        </div>
        <div class="col">
          <input
            id="eventId"
            class="form-control input-design-2"
            formControlName="eventId"
          />
        </div>
      </div>
      <!-- /. campaign id -->
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="status" class="col-form-label form-label">Status:</label>
        </div>
        <div class="col">
          <select
            class="custom-select input-design-2"
            id="status"
            formControlName="status"
          >
            <option value="" selected>Please select status...</option>
            <option value="PENDING">Pending</option>
            <option value="APPROVED">Approved</option>
            <option value="REJECTED">Rejected</option>
          </select>
        </div>
      </div>
      <!-- /. campaign status -->
    </div>
    <!-- /.left side -->
    <div class="col-6">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4 text-end">
          <label for="dateFrom" class="col-form-label form-label">From:</label>
        </div>
        <div class="col">
          <input
            id="dateFrom"
            class="form-control input-design-2"
            type="date"
            formControlName="dateFrom"
          />
        </div>
      </div>
      <!-- /. campaign from -->
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4 text-end">
          <label for="dateTo" class="col-form-label form-label">To:</label>
        </div>
        <div class="col">
          <input
            id="dateTo"
            class="form-control input-design-2"
            type="date"
            formControlName="dateTo"
          />
        </div>
      </div>
      <!-- /. campaign to -->
    </div>
    <!-- /. right side -->
    <div class="col-12">
      <div class="d-grid d-flex justify-content-end">
        <button class="btn my-btn-color-3 my-2 mx-2 btn-xs" type="submit">
          filter
        </button>
      </div>
    </div>
    <!-- /.col -->
  </form>
  <!-- /.row form -->
</div>

<!-- end of top -->
<div class="content-bottom-color pd-sm">
  <app-download-button></app-download-button>

  <!-- /.row -->
  <app-my-table
    [titleTemplates]="titleTemplates"
    [dataTemplate]="dataTemplate"
    [tableOptions]="tableOptionsResponse"
    (navigatePagination)="navigatePagination($event)"
    (onChangeTableSize)="onChangeTableSize($event)"
  >
    <!-- https://stackoverflow.com/a/42814996 -->
    <!-- /. passing of data thru params with from child to parent -->
    <ng-template #titleTemplates>
      <th scope="col" class="th-table b-l-none">Event ID</th>
      <th scope="col" class="th-table">Creation Date</th>
      <th scope="col" class="th-table">Event Name</th>
      <th scope="col" class="th-table">Details</th>
      <th scope="col" class="th-table">Slot Number</th>
      <th scope="col" class="th-table">Min Amount</th>
      <th scope="col" class="th-table">Max Amount</th>
      <th scope="col" class="th-table">Donors Percentage</th>
      <th scope="col" class="th-table">Status</th>
      <th scope="col" class="th-table b-r-none" *ngIf="!isRestrictedRole1()">Action</th>
    </ng-template>
    <!-- /. start of  dataTemplate -->
    <ng-template #dataTemplate>
      <ng-container *ngIf="http.apiDataState.isLoading">
        <tr *ngFor="let in of mock(10); let i = index">
          <td class="td-table b-l-none">
            <app-skeleton-loader Cwidth="20"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="80"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="150"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="150"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="b-r-none b-r-none">
            <app-skeleton-loader Cwidth="150"></app-skeleton-loader>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="!http.apiDataState.isLoading" >
        <tr
          *ngFor="
            let data of http.apiDataState.result.result.data;
            let i = index
          " 
        >
          <td class="td-table b-l-none">
            {{ data.eventId }}
          </td>
          <td class="td-table">
            {{ data.creationDate }}
          </td>
          <td class="td-table">
            {{ data.eventName }}
          </td>
          <td class="td-table">
            {{ data.details }}
          </td>
          <td class="td-table">
            {{ data.slotNumber }}
          </td>
          <td class="td-table">
            {{ data.minAmount }}
          </td>
          <td class="td-table">
            {{ data.maxAmount }}
          </td>
          <td class="td-table">{{ data.donorsPercentage }}%</td>
          <td class="td-table">
            {{ data.status }}
          </td>
          <td class="b-r-none b-r-none d-flex" *ngIf="!isRestrictedRole1()">
            <a
              role="button"
              [routerLink]="[
                myRoutes.modifyEvent.path.replace(':id', data.eventId)
              ]"
              class="badge green-bg text-white mx-1 text-size-action-button-table"
              [class.disabled]="data.status === 'REJECTED' || data?.dateDeleted !== null"
              [attr.aria-disabled]="data.status === 'REJECTED' || data?.dateDeleted !== null  ? true : null"
              [attr.disabled]="data.status === 'REJECTED' || data?.dateDeleted !== null  ? true : null"
              >Modify</a
            >
            <a
            *ngIf="!isRestrictedRole()"
              role="button"
              data-toggle="modal"
              data-target="#approve-modal"
              (click)="openModal(data.eventId, 'approve')"
              class="badge purple-bg text-white mx-1 text-size-action-button-table"
              [class.disabled]="data.status === 'APPROVED' || data.status !== 'PENDING'"
              [attr.aria-disabled]="data.status === 'APPROVED' ||  data.status !== 'PENDING' ? false : null"
              [attr.disabled]="data.status === 'APPROVED' ||  data.status !== 'PENDING' ? false : null"
              >Approve</a
            >
            <a
            *ngIf="!isRestrictedRole()"
            role="button"
            data-toggle="modal"
            data-target="#approve-modal"
            (click)="openModal(data.eventId, 'reject')"
            class="badge red-bg text-white mx-1 text-size-action-button-table"
            [class.disabled]="data.status === 'APPROVED' || data.status === 'REJECTED'"
            [attr.aria-disabled]="data.status === 'APPROVED'  || data.status === 'REJECTED' ? false : null"
            [attr.disabled]="data.status === 'APPROVED'  || data.status === 'REJECTED' ? false : null"
            >Reject</a
          >
          
<!-- Enable Button -->
<a
  role="button"
  data-toggle="modal"
  data-target="#approve-modal"
  (click)="openModal(data?.eventId, 'enable')"
  class="badge purple-bg text-white mx-1 text-size-action-button-table"
  [class.disabled]="data?.dateDeleted === null"
  [attr.aria-disabled]="data?.dateDeleted === null ? true : null"
  [attr.disabled]="data?.dateDeleted === null ? true : null"
>Enable</a>

<!-- Disable Button -->
<a
  role="button"
  data-toggle="modal"
  data-target="#reject-modal"
  (click)="openModal(data?.eventId, 'disable')"
  class="badge red-bg text-white mx-1 text-size-action-button-table"
  [class.disabled]="data?.dateDeleted !== null"
  [attr.aria-disabled]="data?.dateDeleted !== null ? true : null"
  [attr.disabled]="data?.dateDeleted !== null ? true : null"
>Disable</a>



          </td>
        </tr>
      </ng-container>
    </ng-template>
    <!-- /. end of dataTemplate -->
  </app-my-table>
</div>

<app-my-modal
  title="are you sure do you want to {{ modeType }}?"
  modalId="approve-modal"
  (clickYes)="actionEvent()"
></app-my-modal>

<app-over-lay-loading
  *ngIf="approveHttp.apiDataState.isLoading"
></app-over-lay-loading>

<!-- start modal for reject subject for refactor -->
<div
  class="modal fade"
  id="reject-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content radius-lg b-b-none">
      <div class="modal-header b-b-none">
        <h5 class="modal-title" id="exampleModalLabel">&nbsp;</h5>
        <button
          id="reject-modal-close"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row justify-content-center mb-3">
          <div class="col-10 text-center">
            <p class="my-4 my-text-bold-1">
              are you sure do you want to disable?
            </p>
          </div>
          <div class="col-10 mb-3">
            <div class="row g-3 align-items-center mb-3">
              <div class="col">
                <input
                  id="remarks"
                  class="form-control input-design-3"
                  placeholder="Enter remakrs"
                  [(ngModel)]="remarks"
                />
              </div>
            </div>
            <!-- /. Consolation prize -->
          </div>
          <div class="col-10 mb-3 justify-content text-center">
            <button
              class="btn my-btn-color-2 f-weight-6 align-self-center mx-2 w-150"
              type="button"
              (click)="actionEvent()"
            >
              Yes
            </button>
            <button
              id="my-modal-close-button"
              class="btn my-btn-color-2 f-weight-6 align-self-center mx-2 w-150"
              type="button"
              data-dismiss="modal"
            >
              No
            </button>
          </div>
          <!-- /.col -->
        </div>
      </div>
      <div class="modal-footer b-t-none"></div>
    </div>
  </div>
</div>
<!-- /. modal delete end -->
