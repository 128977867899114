import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmptyResultInterface } from '../../interface/empty_result_interface';
import { ApiHelperService } from '../../services/api-helper/api-helper.service';

@Component({
  selector: 'app-disable-modal',
  templateUrl: './disable-modal.component.html',
  styleUrls: ['./disable-modal.component.scss'],
  providers: [ApiHelperService],
})
export class DisableModalComponent implements OnInit {
  @Output() triggerRefresh: EventEmitter<any> = new EventEmitter();
  constructor(public http: ApiHelperService<EmptyResultInterface>) {}
  apiUrl: string;
  ngOnInit(): void {}

  openModal(setApi: string) {
    this.apiUrl = setApi;
  }

  clickYes(): void {
    this.http.delete({
      url: this.apiUrl,
      successCallBack: this.successCallBack,
    });
  }

  successCallBack = (): void => {
    const elem = document.getElementById('close-modal-delete');
    elem.click();

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  refreshData(): void {
    this.triggerRefresh.emit();
  }
}
