import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MockService } from 'src/app/core/services/mock/mock.service';
import { PortalTableData } from './interface/portal_table_data_interface';

@Component({
  selector: 'app-portal-accounts',
  templateUrl: './portal-accounts.component.html',
  styleUrls: ['./portal-accounts.component.scss'],
})
export class PortalAccountsComponent implements OnInit {
  static readonly routeName: string = 'portal-accounts';
  mock: Function;

  modifyUserData: PortalTableData = {
    id: 0,
    username: '',
    email: '',
    role: '',
    status: '',
  };

  constructor(private mockData: MockService) {
    this.mock = this.mockData.createMockData;
  }

  ngOnInit(): void {}

  parentModalListener: Subject<any> = new Subject();

  notifyChildrenModal() {
    this.parentModalListener.next('open modal');
  }
  modifyUser(user: PortalTableData): void {
    this.modifyUserData = user;
    setTimeout(() => {
      this.notifyChildrenModal();
    }, 100);
  }

  getUserID(userID: number) {
    this.modifyUserData.id = userID;
  }

  triggerRefreshData() {}
}
