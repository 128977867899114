<div class="container">
  <form [formGroup]="filterForm" (ngSubmit)="filterData()">
    <div class="row mb-3">
      <div class="col-4 mt-4">
        <app-page-title pageTitle="Cash In Transactions"></app-page-title>
      </div>
      <!-- /.col -->
    </div>
    <!--  /. row buttons -->
    <div class="row">
      <div class="col-6">
        <div class="row g-0 align-items-center mb-3">
          <div class="col-4">
            <label
              for="firstName"
              class="col-form-label my-text-label f-weight-7 form-label my-text-label f-weight-7"
              >First Name:</label
            >
          </div>
          <div class="col">
            <input
              id="firstName"
              class="form-control input-design-2"
              formControlName="firstName"
            />
          </div>
        </div>
        <!-- /. First name -->
        <div class="row g-0 align-items-center mb-3">
          <div class="col-4">
            <label
              for="lastName"
              class="col-form-label my-text-label f-weight-7 form-label my-text-label f-weight-7"
              >Last Name:</label
            >
          </div>
          <div class="col">
            <input
              id="lastName"
              class="form-control input-design-2"
              formControlName="lastName"
            />
          </div>
        </div>
        <!-- /. Last name -->
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4">
            <label
              for="mobileNumber"
              class="col-form-label my-text-label f-weight-7 form-label my-text-label f-weight-7"
              >Mobile number:</label
            >
          </div>
          <div class="col">
            <input
              id="mobileNumber"
              class="form-control input-design-2"
              formControlName="mobileNumber"
            />
          </div>
        </div>
        <!-- /. mobile_number -->
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4">
            <label
              for="transactionId"
              class="col-form-label my-text-label f-weight-7 form-label my-text-label f-weight-7"
              >Transaction ID:</label
            >
          </div>
          <div class="col">
            <input
              id="transactionId"
              class="form-control input-design-2"
              formControlName="transactionId"
            />
          </div>
        </div>
        <!-- /. transactions_id -->
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4">
            <label
              for="modeOfPayment"
              class="col-form-label my-text-label f-weight-7 form-label my-text-label f-weight-7"
              >Mode of Payment:</label
            >
          </div>
          <div class="col">
            <input
              id="modeOfPayment"
              class="form-control input-design-2"
              formControlName="modeOfPayment"
            />
          </div>
        </div>
        <!-- /. mode_of_payment -->
      </div>
      <!-- /.left side -->
      <div class="col-6">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4 text-end">
            <label
              for="dateFrom"
              class="col-form-label my-text-label f-weight-7 form-label my-text-label f-weight-7"
              >From:</label
            >
          </div>
          <div class="col">
            <input
              id="dateFrom"
              class="form-control input-design-2"
              type="date"
              formControlName="dateFrom"
            />
          </div>
        </div>
        <!-- /. campaign from -->
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4 text-end">
            <label
              for="dateTo"
              class="col-form-label my-text-label f-weight-7 form-label my-text-label f-weight-7"
              >To:</label
            >
          </div>
          <div class="col">
            <input
              id="dateTo"
              class="form-control input-design-2"
              type="date"
              formControlName="dateTo"
            />
          </div>
        </div>
        <!-- /. campaign to -->
      </div>
      <!-- /. right side -->
      <div class="col-12">
        <div class="d-grid d-flex justify-content-end">
          <button class="btn my-btn-color-3 my-2 mx-2 btn-xs" type="submit">
            filter
          </button>
        </div>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row form -->
  </form>
</div>

<div class="content-bottom-color pd-sm">
  <app-transaction-table
    [isLoading]="http.apiDataState.isLoading"
    [data]="data"
    [tableOption]="tableOptionsResponse"
    (changePageSize)="updatePageSize($event)"
    (changePageNumber)="updatePageNumber($event)"
    updatePageNumber
  ></app-transaction-table>
</div>
<!-- /. table orange bg -->
