<div class="container">
  <div class="row mb-3">
    <div class="col-4 mt-4">
      <app-page-title pageTitle="Best pick demo"></app-page-title>
    </div>
  </div>
  <!--  /. row buttons -->
  <div class="row">
    <div class="col-12 mb-3">
      <div class="container text-center mt-5">
        <!-- Apply DropZone Directive -->﻿
        <div
          class="dropzone best-card-shadow"
          appDropZone
          (onFileDropped)="droppedFiles($event)"
        >
          <div class="text-center upload-label" *ngIf="!isRestrictedRole1()">
            Upload <br />
            Video
            <!-- todo remove whn done -->
            <!-- <span class="note needsclick"
                  >(This is just a demo dropzone. Selected files are
                  <strong>not</strong> actually uploaded.)</span
                > -->
          </div>
        </div>
        <!-- todo will delete after tested and passed in test -->
        <ng-container *ngIf="allFiles.length >= 1">
          <div class="file-table">
            <h3 class="m-3">File details</h3>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">File Name</th>
                  <th scope="col">Size</th>
                  <th scope="col">Type</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let file of allFiles; let i = index">
                  <th scope="row">{{ i + 1 }}</th>
                  <td>{{ file.name }}</td>
                  <td>{{ file.size }} Bytes</td>
                  <td>{{ file.type }}</td>
                </tr>
                <tr class="text-center" *ngIf="allFiles.length === 0">
                  <td colspan="4"><strong>No files are uploaded</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- /.col -->
    <div class="col-12 mb-3" *ngIf="!isRestrictedRole1()">
      <div class="d-flex">
        <button
          class="btn my-btn-color-2 w-300 f-weight-6 align-self-center mx-auto"
          type="button"
          (click)="uploadVideo()"
        >
          <ng-container *ngIf="http.apiDataState.isLoading">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </ng-container>
          <ng-container *ngIf="!http.apiDataState.isLoading">
            SUBMIT
          </ng-container>
        </button>
      </div>
    </div>
  </div>
  <!-- /.row form -->
</div>
