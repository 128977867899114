import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { myRouter } from 'src/app/core/route/my_route';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { MockService } from 'src/app/core/services/mock/mock.service';
import { UserTableFilterInterface } from './interface/user_table_filter_interface';
import { UserTableInterface } from './interface/user_table_interface';
import { ManageUserTableComponent } from './manage-user-table/manage-user-table.component';
import { ModifyUserComponent } from './modify-user/modify-user.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss'],
  providers: [ApiHelperService,DatePipe],
})
export class ManageUserComponent implements OnInit {
  static readonly routeName: string = 'manage-accounts';
  mock: Function;
  /// todo
  /// temp solution only
  route = myRouter;

  isHiddenModify: boolean = false;
  isHiddenTnx: boolean = false;
  @ViewChild(ModifyUserComponent) modifyModal: ModifyUserComponent;

  filterForm = this.formBuilder.group({
    firstName: [''],
    lastName: [''],
    email: [''],
    mobileNumber: [''],
    dateFrom: [''],
    dateTo: [''],
  });

  @ViewChild(ManageUserTableComponent) tableChild: ManageUserTableComponent;

  constructor(
    private mockData: MockService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.mock = this.mockData.createMockData;
  }

  ngOnInit(): void {}

  get filterControl() {
    return this.filterForm.controls;
  }
  newDateFormat(dateTime: string): string {
    return this.datePipe.transform(dateTime, 'yyyy-MM-dd');
  }
  submitFilter(): void {
    const filterForm = this.filterControl;
    let filterObject: UserTableFilterInterface = {
      firstName: null,
      lastName: null,
      email: null,
      mobileNumber: null,
      dateFrom: null,
      dateTo: null,
      sortBy: {
        registrationDate: 'desc',
      },
    };

    if (filterForm.dateFrom.value.length >= 1) {
      console.log(this.newDateFormat(filterForm.dateFrom.value),'from');
      filterObject.dateFrom = this.newDateFormat(filterForm.dateFrom.value);
    }
    if (filterForm.dateTo.value.length >= 1) {
      console.log(this.newDateFormat(filterForm.dateTo.value),'to');
      filterObject.dateTo = this.newDateFormat(filterForm.dateTo.value);
    }

    if (filterForm.firstName.value.length >= 1) {
      filterObject.firstName = filterForm.firstName.value;
    }
    if (filterForm.lastName.value.length >= 1) {
      filterObject.lastName = filterForm.lastName.value;
    }
    if (filterForm.email.value.length >= 1) {
      filterObject.email = filterForm.email.value;
    }
    if (filterForm.mobileNumber.value.length >= 1) {
      filterObject.mobileNumber = filterForm.mobileNumber.value;
    }
    this.tableChild.setFilterData(filterObject);
  }

  toggleModify(data: UserTableInterface): void {
    this.isHiddenModify = true;
    this.isHiddenTnx = false;
    this.modifyModal.setSelectedUser(data);
    console.log(data, 'from pparent');
  }

  toggleOff(): void {
    this.isHiddenModify = false;
    this.isHiddenTnx = false;
  }
}
