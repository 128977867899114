<div class="container">
  <div class="row mb-3">
    <div class="col-4 mt-4">
      <app-page-title pageTitle="Modify Events"></app-page-title>
    </div>
    <!-- /.col -->
    <div class="col mt-4 align-self-end">
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <a
          [href]=""
          class="orange-pill text-white px-2 text-decoration-none f-weight-6"
          type="button"
        >
          X
        </a>
      </div>
    </div>
    <!-- /.col -->
  </div>
  <!--  /. row buttons -->
  <form [formGroup]="eventForm" (ngSubmit)="submitModifyEvent()">
    <div class="row justify-content-center">
      <div class="col-8">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4">
            <label for="title" class="col-form-label form-label my-text-label-2"
              >Title:</label
            >
          </div>
          <div class="col">
            <input
              id="title"
              class="form-control input-design-2"
              formControlName="title"
            />
          </div>
        </div>
        <!-- /. title -->
      </div>
      <!-- /.col -->

      <div class="col-8">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4">
            <label for="title" class="col-form-label form-label my-text-label-2"
              >Details:</label
            >
          </div>
          <div class="col">
            <input
              [ngxSummernote]="summernoteConfig"
              formControlName="details"
              [(ngModel)]="htmlOutput"
            />
          </div>
        </div>
        <!-- /. title -->
      </div>
      <!-- /.col -->

      <div class="col-10 mb-3">
        <div class="d-flex">
          <button
            class="btn my-btn-color-2 f-weight-6 align-self-center mx-auto"
            type="submit"
          >
            Save
          </button>
        </div>
        <!-- /. d flex -->
      </div>
      <!-- /.col -->
      <div class="col-10 mb-3">
        <hr />
      </div>
      <div class="col-10 mb-3">
        <div class="d-flex">
          <a
            role="button"
            class="f-weight-6 align-self-center mx-auto purple-text text-decoration-underline"
            (click)="openUpload()"
          >
            UPLOAD CONTENT
          </a>
          <input
            id="upload-content-input"
            type="file"
            multiple
            hidden
            (change)="onFileSelected($event)"
          />
        </div>
        <div
          class="my-best-card d-flex f-wrap justify-content-center"
          *ngIf="imageList.length >= 1"
        >
          <div
            class="image-frame rounded my-2 mx-3"
            *ngFor="let data of imageList; let i = index"
          >
            <a
              type="button"
              class="orange-text px-2 text-decoration-none f-weight-6"
              (click)="deleteImage(data)"
            >
              X
            </a>
            <img
              class="rounded img-fluid"
              src="{{ 'https://' + data.trim() }}"
            />
            <span class="image-number"> {{ i + 1 }}</span>
          </div>
        </div>
        <!-- /. d flex -->
      </div>
      <!-- /.col -->
    </div>
  </form>

  <!-- /.row form -->
</div>
