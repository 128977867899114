<div class="content-bottom-color pd-sm">
  <!-- /.row -->
  <app-my-table
    [titleTemplates]="titleTemplates"
    [dataTemplate]="dataTemplate"
    [tableOptions]="tableOptionsResponse"
    (navigatePagination)="navigatePagination($event)"
    (onChangeTableSize)="onChangeTableSize($event)"
  >
    <!-- https://stackoverflow.com/a/42814996 -->
    <!-- /. passing of data thru params with from child to parent -->
    <ng-template #titleTemplates>
      <th scope="col" class="th-table b-l-none">
        ID
        <app-filter-svg
          (clickFunction)="filterBy(myFilters.id)"
        ></app-filter-svg>
      </th>
      <th scope="col" class="th-table">
        Username
        <app-filter-svg
          (clickFunction)="filterBy(myFilters.username)"
        ></app-filter-svg>
      </th>
      <th scope="col" class="th-table">
        Email
        <app-filter-svg
          (clickFunction)="filterBy(myFilters.email)"
        ></app-filter-svg>
      </th>
      <th scope="col" class="th-table">
        Role
        <app-filter-svg
          (clickFunction)="filterBy(myFilters.role)"
        ></app-filter-svg>
      </th>
      <th scope="col" class="th-table">
        Status
        <app-filter-svg
          (clickFunction)="filterBy(myFilters.status)"
        ></app-filter-svg>
      </th>
      <th scope="col" class="th-table b-r-none">Action</th>
    </ng-template>
    <!-- /. start of  dataTemplate -->
    <ng-template #dataTemplate>
      <ng-container *ngIf="http.apiDataState.isLoading">
        <tr *ngFor="let in of mock(10); let i = index">
          <td class="td-table b-l-none">
            <app-skeleton-loader Cwidth="20"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="100"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="100"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="b-r-none b-r-none">
            <app-skeleton-loader Cwidth="150"></app-skeleton-loader>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="!http.apiDataState.isLoading">
        <tr
          *ngFor="
            let data of http.apiDataState.result.result.data;
            let i = index
          "
        >
          <td class="td-table b-l-none">
            {{ data.id }}
          </td>
          <td class="td-table">
            {{ data.username }}
          </td>
          <td class="td-table">
            {{ data.email }}
          </td>
          <td class="td-table">
            {{ data.role }}
          </td>
          <td class="td-table">
            {{ data.status }}
          </td>
          <td class="b-r-none b-r-none">
            <a
              (click)="modifyTrigger(data)"
              class="badge pointer-cursor bg-success text-white mx-1"
              role="button"
              data-toggle="modal"
              data-target="#modifyModal"
              >Modify</a
            >
            <a
              (click)="clickDelete(data.id)"
              role="button"
              class="badge pointer-cursor red-bg text-white mx-1"
              data-toggle="modal"
              data-target="#deleteModal"
              >Delete</a
            >
          </td>
        </tr>
      </ng-container>
    </ng-template>
    <!-- /. end of dataTemplate -->
  </app-my-table>
</div>
