import { myRouter } from './../../route/my_route';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../services/local-storage/local-storage-service';
import { LocalStorageKeys } from '../../utils/local_storage_keys';
import { SweetAlert2Service } from '../../services/sweet-alert2/sweet_alert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-side-nav',
  templateUrl: './my-side-nav.component.html',
  styleUrls: ['./my-side-nav.component.scss'],
})
export class MySideNavComponent implements OnInit {
  routes = myRouter;
  role: string = '';

  constructor(
    private storage: LocalStorageService,
    private swal: SweetAlert2Service,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.role = this.storage.role();
  }

  logout(): void {
    this.storage.remove(LocalStorageKeys.account);
    this.storage.remove(LocalStorageKeys.token);
    this.storage.remove(LocalStorageKeys.role);
    this.swal.toastSuccess('Logout successfully');
    setTimeout(() => {
      window.location.reload();
    }, 900);
  }

  // will create service for this
  onClickNav(url: string) {
    const user: string = 'User';
    const marketing: string = 'Marketing';
    const marketingManager: string = 'Marketing Manager';
    const raffleRoute = this.routes.campaign.items[1].path;
    const portalRoute = this.routes.portal.items[0].path;
    // user condition
    if (this.role == user && (raffleRoute == url || portalRoute == url)) {
      this.swal.toastWarning('permission not allowed');
      return;
    }
    // marketing condition
    if (this.role == marketing && (portalRoute == url)) {
      this.swal.toastWarning('permission not allowed');
      return;
    }

    // marketing Manager condition
    if (this.role == marketingManager && portalRoute == url) {
      this.swal.toastWarning('permission not allowed');
      return;
    }
    console.log('hello',url)
    this.router.navigateByUrl(url);
  }

  checkRole(roleAllowed: string[]): boolean {
    // let isAllowed =  roleAllowed.find((data)=> data == this.role);
    // console.log(isAllowed);
    const array1 = [5, 12, 8, 130, 44];
    // if (roleAllowed != undefined) {
    //   return false;
    // }

    // let found = roleAllowed.find((element) => element == 'hotdog');

    // console.log(found,'found');
    console.log(roleAllowed, 'found');
    // if(){
    //   return false

    // }
    return true;
  }
  
  isRestrictedRole1(): boolean {
    const restrictedRoles = ['Audit'];
    return restrictedRoles.includes(this.role);
  }
}
