import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { SweetAlert2Service } from 'src/app/core/services/sweet-alert2/sweet_alert2';
import { apiUrl } from 'src/app/core/utils/api_url';
import { FAQInterface } from '../interface/faq_interface';

@Component({
  selector: 'app-faq-create-new',
  templateUrl: './faq-create-new.component.html',
  styleUrls: ['./faq-create-new.component.scss'],
  providers: [ApiHelperService],
})
export class FaqCreateNewComponent implements OnInit {
  @Output() reloadFaq: EventEmitter<any> = new EventEmitter();

  faqFormBuilder = this.formBuilder.group({
    question: ['', Validators.required],
    answer: ['', Validators.required],
  });
  constructor(
    public http: ApiHelperService<FAQInterface>,
    private formBuilder: FormBuilder,
    private swal: SweetAlert2Service
  ) {}

  get faqControl() {
    return this.faqFormBuilder.controls;
  }

  ngOnInit(): void {}
  addFaq(): void {
    const formValidator = this.faqControl;

    if (formValidator.question.invalid) {
      this.swal.toastError('question is required');
      return;
    }
    if (formValidator.answer.invalid) {
      this.swal.toastError('answer is required');
      return;
    }

    this.http.post({
      url: apiUrl.addFaq,
      data: this.faqFormBuilder.value,
      successCallBack: this.successCallBack,
    });
  }

  successCallBack = (): void => {
    const closeModal = document.getElementById('closeModalfaq');
    closeModal.click();
    this.reloadFaq.emit();
  };
}
