<div class="container">
  <div class="row mb-3">
    <div class="col-4 mt-4">
      <app-page-title [pageTitle]="pageTitle"></app-page-title>
    </div>
    <!-- /.col -->
    <div class="col mt-4 align-self-end">
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <a
          role="button"
          class="orange-pill text-white px-2 text-decoration-none f-weight-6"
          type="button"
          (click)="goBack()"
        >
          X
        </a>
      </div>
    </div>
    <!-- /.col -->
  </div>
</div>
<div class="content-bottom-color pd-sm">
  <app-download-button
    (downloadEmitter)="clickDownload()"
  ></app-download-button>
  <app-my-table
    [titleTemplates]="titleTemplates"
    [dataTemplate]="dataTemplate"
    [settings]="false"
  >
    <ng-template #titleTemplates>
      <th scope="col" class="th-table b-l-none">ID</th>
      <th scope="col" class="th-table">TRANSACTION ID</th>
      <th scope="col" class="th-table">DATE</th>
      <th scope="col" class="th-table">MOBILE NUMBER</th>
      <th scope="col" class="th-table">MODE OF PAYMENT</th>
      <th scope="col" class="th-table">TRANSACTION</th>
      <th scope="col" class="th-table">BALANCE</th>
      <th scope="col" class="th-table">AMOUNT</th>
      <th scope="col" class="th-table">Status</th>
      <th scope="col" class="th-table b-r-none">ERROR MESSAGE</th>
    </ng-template>
    <ng-template #dataTemplate>
      <ng-container *ngIf="http.apiDataState.isLoading">
        <tr *ngFor="let in of mock(10); let i = index">
          <td class="td-table b-l-none">
            <app-skeleton-loader Cwidth="20"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="100"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="70"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="70"></app-skeleton-loader>
          </td>
          <td class="td-table">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
          <td class="b-r-none b-r-none">
            <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="!http.apiDataState.isLoading">
        <tr *ngFor="let data of http.apiDataState.result.result; let i = index">
          <td class="td-table b-l-none">{{ data.uid }}</td>
          <td class="td-table">{{ data.transactionId }}</td>
          <td class="td-table">{{ data.date }}</td>
          <td class="td-table">{{ data.mobileNumber }}</td>
          <td class="td-table">{{ data.modeOfPayment }}</td>
          <td class="td-table">
            <div class="pre-wrap">{{ data.transaction }}</div>
          </td>
          <td class="td-table">
            PHP
            {{
              data.balance.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }}
          </td>
          <td class="td-table">
            PHP
            {{
              data.amount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }}
          </td>
          <td class="td-table">{{ data.status }}</td>
          <td class="td-table b-r-none">
            {{ data.errorMessage != null ? data.errorMessage : "N/A" }}
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </app-my-table>
</div>
<!-- /. orange bg -->
