import { Component, OnInit } from '@angular/core';
import { myRouter } from 'src/app/core/route/my_route';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage-service';
import { Router } from '@angular/router';
import { LocalStorageKeys } from '../../core/utils/local_storage_keys';
import { LoginResponseInterface } from './interface/login_reponse_interface';
import { apiUrl } from 'src/app/core/utils/api_url';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  static readonly routeName: string = 'login';
  transactionRoute = myRouter.transaction;

  // form input builder
  loginForm = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(
    private formBuilder: FormBuilder,
    public http: ApiHelperService<LoginResponseInterface>,
    private storage: LocalStorageService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  get loginControl() {
    return this.loginForm.controls;
  }

  submitLoginForm() {
    this.http.post({
      url: apiUrl.login,
      data: this.loginForm.value,
      isAuth: false,
      successCallBack: this.loginCallBack,
    });
  }

  loginCallBack = (): void => {
    let apiSubscribe = this.http.apiDataState;
    const portalAccount = apiSubscribe.result.result.portalAccount;
    const token = apiSubscribe.result.result.loginToken;
    const role = portalAccount.role;
    this.storage.set(LocalStorageKeys.account, JSON.stringify(portalAccount));
    this.storage.set(LocalStorageKeys.token, token);
    this.storage.set(LocalStorageKeys.role, role);
    setTimeout(() => {
      this.router
        .navigate([`${myRouter.eventsTable.path}`])
        .then(() => {
          window.location.reload();
        });
    }, 1000);
  };
}
