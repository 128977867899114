export enum roleTypes {
  User = 'User',
  Master = 'Master',
  Admin = 'Admin',
  Marketing = 'Marketing',
  MarketingManger = 'Marketing Manager',
  Accounting = 'Accounting',
  Audit = 'Audit',
  Marketing_Manager = 'Marketing_Manager',
}
