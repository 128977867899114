import { Component, OnInit } from '@angular/core';
import { myRouter } from 'src/app/core/route/my_route';
import bsCustomFileInput from 'bs-custom-file-input';
import { SweetAlert2Service } from '../../../core/services/sweet-alert2/sweet_alert2';
import { ApiHelperService } from '../../../core/services/api-helper/api-helper.service';
import { DatePipe } from '@angular/common';
import { EmptyResultInterface } from '../../../core/interface/empty_result_interface';
import { apiUrl } from '../../../core/utils/api_url';
import {
  createRewardsInput,
  resetInputRewards,
  sleep,
  numberPlaceConverter,
} from 'src/app/core/utils/rewards_helper';
@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.scss'],
  providers: [ApiHelperService, DatePipe],
})
export class CreateCampaignComponent implements OnInit {
  static readonly routeName: string = 'campagin-create';
  /// todo
  /// temp solution only
  route = myRouter;

  campaignName: string;

  consolationNumber: number;
  consolationPrize: string;

  grandPrizeNumber: number;
  granPrize: string;

  slotNumber: number;
  amountPerSlot: number;

  prizeHolder: string[] = [];
  consolationNumberHolder: string[] = [];

  startDate: string;
  endDate: string;

  formData = new FormData();

  fileInput: any;

  selectedConsolationPrize: string[] = [];

  constructor(
    private swal: SweetAlert2Service,
    private datePipe: DatePipe,
    public http: ApiHelperService<EmptyResultInterface>
  ) {}

  ngOnInit(): void {
    bsCustomFileInput.init();
  }

  prepareRewards = () => {
    return sleep(1000).then(() => {
      const elements = document.getElementsByClassName('rewards-inputs');
      const form = this.formData;
      let loopReward = '';
      let loopConsolationNumber = '';
      for (let i = 0; i < elements.length; i++) {
        const inputs = elements[i] as HTMLInputElement;
        const myNumber = parseInt(inputs.value);
        loopReward += `${inputs.value}:`;
        if (i + 1 < elements.length) {
          loopConsolationNumber += `${myNumber},`;
        } else {
          loopConsolationNumber += `${myNumber}`;
        }
      }
      loopReward += `${numberPlaceConverter(this.slotNumber)} Draw - ${
        this.granPrize
      }`;
      form.append('consolationDrawNumbers', loopConsolationNumber);
      form.append('rewards', loopReward);
    });
  };

  addReward(): void {
    let myRewards = this.selectedConsolationPrize;
    const indexRewards = myRewards.length;
    createRewardsInput('', indexRewards, 3);
  }

  // https://stackoverflow.com/a/13627586
  numberPlaceConverter(place: number): string {
    if (place <= 0) {
      this.swal.toastError('consolation number cant be less than 0');
      return;
    }
    let newValue = place % 10,
      posValue = place % 100;
    if (newValue == 1 && posValue != 11) {
      return place + 'st';
    }
    if (newValue == 2 && posValue != 12) {
      return place + 'nd';
    }
    if (newValue == 3 && posValue != 13) {
      return place + 'rd';
    }
    return place + 'th';
  }

  addConsolationPrize(): void {
    let conNumber = this.numberPlaceConverter(this.consolationNumber);
    let conPrize = this.consolationPrize;
    this.prizeHolder.push(`${conNumber} Draw - ${conPrize}`);
    this.consolationNumberHolder.push(this.consolationNumber.toString());

    this.consolationNumber = null;
    this.consolationPrize = '';
  }

  removeConsolationPrize(selectedConsolation: string): void {
    console.log(selectedConsolation);
    const removePrize = this.prizeHolder.filter(
      (data) => data != selectedConsolation
    );
    this.prizeHolder = removePrize;
  }

  getAllPrize(): void {}

  onFileSelected(event) {
    const formData = this.formData;

    if (event.target.files.length <= 0) {
      formData.append('uploadedContents', '');
      return;
    }
    for (let i = 0; i < event.target.files.length; i++) {
      formData.append('uploadedContents[]', event.target.files[i]);
    }
  }

  async createCampaign(): Promise<void> {
    const formData = this.formData;
    await this.prepareRewards();
    formData.append('name', this.campaignName);
    formData.append(
      'startDate',
      this.datePipe.transform(this.startDate, 'yyyy-MM-dd')
    );
    formData.append(
      'endDate',
      this.datePipe.transform(this.endDate, 'yyyy-MM-dd')
    );
    formData.append('slotTotal', this.slotNumber.toString());
    formData.append('amountPerSlot', this.amountPerSlot.toString());
    console.log(formData);

    this.http.post({
      url: apiUrl.createCampaign,
      data: this.formData,
      successCallBack: this.successCreateForm,
    });
  }

  successCreateForm = (): void => {
    this.campaignName = '';
    this.consolationNumber = null;
    this.consolationPrize = '';
    this.grandPrizeNumber = null;
    this.granPrize = '';
    this.slotNumber = null;
    this.amountPerSlot = null;
    this.prizeHolder = [];
    this.consolationNumberHolder = [];
    this.startDate = '';
    this.endDate = '';
    var inputValue = <HTMLInputElement>document.getElementById('file-input');
    inputValue.value = '';
    inputValue.dispatchEvent(new Event('change'));
    resetInputRewards();
    this.formData.forEach((value, key) => {
      this.formData.delete(key);
    });
  };
}
