<div class="container">
  <div class="row mb-3">
    <div class="col-4 mt-4">
      <app-page-title pageTitle="Modify Campaign"></app-page-title>
    </div>
    <!-- /.col -->
    <div class="col mt-4 align-self-end">
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <a
          [href]=""
          class="orange-pill text-white px-2 text-decoration-none f-weight-6"
          type="button"
        >
          X
        </a>
      </div>
    </div>
    <!-- /.col -->
  </div>
  <!--  /. row buttons -->
  <form [formGroup]="campaignForm" (ngSubmit)="submitModifyCampaign()">
    <div class="row justify-content-center">
      <div class="col-10 mb-3">
        <div class="d-grid d-flex justify-content-end">
          <button
            class="btn my-btn-color-2 me-2 mx-2"
            type="button"
            (click)="addReward()"
          >
            Add Rewards
          </button>
          <button
            class="btn my-btn-color-2 me-md-2 mx-2"
            type="button"
            (click)="campaignForceDraw()"
          >
            Force Draw
          </button>
          <button
            class="btn my-btn-color-2 mx-2"
            type="button"
            (click)="deleteCampaign()"
          >
            Delete
          </button>
        </div>
      </div>
      <div class="col-8">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4">
            <label
              for="campaignName"
              class="col-form-label form-label my-text-label-2"
              >Campaign Name:</label
            >
          </div>
          <div class="col">
            <input
              id="campaignName"
              class="form-control input-design-2"
              formControlName="name"
            />
          </div>
        </div>
        <!-- /. campaign name -->
      </div>
      <!-- /.col -->
      <div class="col-8">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4">
            <label
              for="campaginRewards"
              class="col-form-label form-label my-text-label-2"
              >Grand Prize:</label
            >
          </div>
          <div class="col">
            <input
              id="campaginRewards"
              class="form-control input-design-2"
              formControlName="grandPrize"
            />
          </div>
        </div>
        <!-- /. campaign rewards -->
      </div>
      <div class="col-8">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4">
            <label
              for="campaginRewards"
              class="col-form-label form-label my-text-label-2"
              >Consolation Rewards:</label
            >
          </div>
          <div class="col" id="consolation-rewards">
            <!-- https://niisar.gitbooks.io/angular-plus-kitchen/content/directive/auto-format-input.html -->
            <input
              id="campaginRewards"
              class="form-control input-design-2"
              formControlName="rewards"
              hidden
            />
            <!-- generated html rewards -->
          </div>
        </div>
        <!-- /. campaign rewards -->
      </div>
      <!-- /.col -->
      <div class="col-10 mb-3">
        <div class="d-flex">
          <button
            class="btn my-btn-color-2 f-weight-6 align-self-center mx-auto"
            type="submit"
          >
            Save
          </button>
        </div>
        <!-- /. d flex -->
      </div>
      <!-- /.col -->
      <div class="col-10 mb-3">
        <hr />
      </div>

      <div class="col-8 mb-3">
        <div class="card content-bottom-color">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="row g-3 align-items-center mb-3">
                  <div class="col-4">
                    <label for="setSlotNumber" class="col-form-label form-label"
                      >Set Slot number:</label
                    >
                  </div>
                  <div class="col">
                    <input
                      id="setSlotNumber"
                      class="form-control input-design-4"
                      type="number"
                      formControlName="slotTotal"
                      readonly
                    />
                  </div>
                </div>
                <!-- /. draw date -->
              </div>
              <!-- /.col -->
              <div class="col-12">
                <div class="row g-3 align-items-center mb-3">
                  <div class="col-4">
                    <label for="amountPerSlot" class="col-form-label form-label"
                      >Amount per slot:</label
                    >
                  </div>
                  <div class="col">
                    <input
                      id="amountPerSlot"
                      class="form-control input-design-4"
                      formControlName="amountPerSlot"
                      readonly
                    />
                  </div>
                </div>
                <!-- /. draw date -->
              </div>
              <!-- /.col -->
              <div class="col-12">
                <div class="row g-3 align-items-center mb-3">
                  <div class="col-4">
                    <label
                      for="slotDrawNumbers"
                      class="col-form-label form-label"
                      >Slot Draw Numbers:</label
                    >
                  </div>
                  <div class="col">
                    <input
                      id="slotDrawNumbers"
                      class="form-control input-design-4"
                      formControlName="consolationDrawNumbers"
                      readonly
                    />
                  </div>
                </div>
                <!-- /. slotDrawNumbers date -->
              </div>
              <!-- /.col -->
            </div>
            <!-- /.row -->
          </div>
          <!-- /.card body -->
        </div>
        <!-- /. card -->
      </div>
      <!-- /.col card -->
      <div class="col-10 mb-3">
        <div class="d-flex">
          <a
            role="button"
            class="f-weight-6 align-self-center mx-auto purple-text text-decoration-underline"
            (click)="openUpload()"
          >
            UPLOAD CONTENT
          </a>
          <input
            id="upload-content-input"
            type="file"
            multiple
            hidden
            (change)="onFileSelected($event)"
          />
        </div>
        <div
          class="my-best-card d-flex f-wrap justify-content-center"
          *ngIf="imageList.length >= 1"
        >
          <div
            class="image-frame rounded my-2 mx-3"
            *ngFor="let data of imageList; let i = index"
          >
            <a
              type="button"
              class="orange-text px-2 text-decoration-none f-weight-6"
              (click)="deleteImage(data)"
            >
              X
            </a>
            <img
              class="rounded img-fluid"
              src="{{ 'https://' + data.trim() }}"
            />
            <span class="image-number"> {{ i + 1 }}</span>
          </div>
        </div>
        <!-- /. d flex -->
      </div>
      <!-- /.col -->
    </div>
  </form>

  <!-- /.row form -->
</div>
