<div class="container">
  <div class="row mb-3">
    <div class="col-md-4 col-11 mt-4">
      <app-page-title pageTitle="MODIFY USER ACCOUNT"></app-page-title>
    </div>
    <!-- /.col -->
    <div class="col mt-4 align-self-end">
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <a
          (click)="closeModal()"
          role="button"
          class="orange-pill text-white px-2 text-decoration-none f-weight-6"
          type="button"
        >
          X
        </a>
      </div>
    </div>
    <!-- /.col -->
  </div>
  <!--  /. row buttons -->
  <div class="row justify-content-center">
    <div class="col-md-8 col-12">
      <div class="row no-gutters align-items-center mb-3">
        <div class="col-4">
          <label for="firstName" class="col-form-label form-label purple-text"
            >First Name:</label
          >
        </div>
        <div class="col">
          <input
            id="firstName"
            class="form-control input-design-2"
            type="text"
            [(ngModel)]="selectedUser.firstName"
          />
        </div>
      </div>
      <!-- /. First Name -->
    </div>
    <!-- /.col -->
    <div class="col-md-8 col-12">
      <div class="row no-gutters align-items-center mb-3">
        <div class="col-4">
          <label for="lastName" class="col-form-label form-label purple-text"
            >Last Name:</label
          >
        </div>
        <div class="col">
          <input
            id="lastName"
            class="form-control input-design-2"
            [(ngModel)]="selectedUser.lastName"
          />
        </div>
      </div>
      <!-- /. Last Name -->
    </div>
    <!-- /.col -->
    <div class="col-10 mb-3">
      <hr />
    </div>

    <div class="col-md-8 col-12 mb-3">
      <div class="row">
        <!-- /.col -->
        <div class="col-12">
          <div class="row g-3 align-items-top mb-3">
            <div class="col-12">
              <h3
                class="purple-text my-text-bold-1 font-sm text-decoration-underline"
              >
                OTP REQUIRED CHANGES:
              </h3>
            </div>
            <div class="col-lg-3 col-sm-6">
              <h3 class="purple-text my-text-bold-1 font-sm mt-2">
                Mobile Number:
              </h3>
            </div>
            <div class="col-md-8 col-sm-12">
              <div class="row no-gutters mb-2">
                <div class="col-4">
                  <label
                    for="curNumber"
                    class="col-form-label form-label font-sm text-decoration-underline purple-text"
                    >Current Number:</label
                  >
                </div>
                <div class="col">
                  <input
                    id="curNumber"
                    class="form-control input-design-2"
                    [(ngModel)]="selectedUser.mobileNumber"
                  />
                </div>
              </div>
              <!-- /. Current Number -->
              <div class="row no-gutters">
                <div class="col-4">
                  <label
                    for="newNumber"
                    class="col-form-label purple-text form-label font-sm text-decoration-underline"
                    >New Number:</label
                  >
                </div>
                <div class="col">
                  <input
                    id="newNumber"
                    class="form-control input-design-2"
                    [(ngModel)]="modifiedDetials.mobileNumber"
                  />
                </div>
              </div>
              <!-- /. New Number -->
            </div>
            <!-- /.col -->
          </div>
          <!-- /. row -->
        </div>
        <!-- /.col -->

        <div class="col-12">
          <div class="row g-3 align-items-top mb-3">
            <div class="col-lg-3 col-sm-6">
              <h3 class="purple-text my-text-bold-1 font-sm mt-2">
                Email Address:
              </h3>
            </div>
            <div class="col-md-8 col-sm-12">
              <div class="row no-gutters mb-2">
                <div class="col-4">
                  <label
                    for="curEmail"
                    class="col-form-label form-label font-sm text-decoration-underline purple-text"
                    >Current Email:</label
                  >
                </div>
                <div class="col">
                  <input
                    id="curEmail"
                    class="form-control input-design-2"
                    type="email"
                    [(ngModel)]="selectedUser.email"
                  />
                </div>
              </div>
              <!-- /. Current Email -->
              <div class="row no-gutters">
                <div class="col-4">
                  <label
                    for="newEmail"
                    class="col-form-label purple-text form-label font-sm text-decoration-underline"
                    >New Email:</label
                  >
                </div>
                <div class="col">
                  <input
                    id="newEmail"
                    class="form-control input-design-2"
                    type="email"
                    [(ngModel)]="modifiedDetials.email"
                  />
                </div>
              </div>
              <!-- /. New Email -->
            </div>
            <!-- /.col -->
          </div>
          <!-- /. row -->
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.col card -->
    <!-- /.col -->
    <div class="col-10 mb-3">
      <div class="d-flex">
        <button
          (click)="submitModifyDetials()"
          class="btn my-btn-color-2 f-weight-6 align-self-center mx-auto btn-med"
          type="button"
        >
          SUBMIT
        </button>
      </div>
      <!-- /. d flex -->
    </div>
    <!-- /.col -->
  </div>
  <!-- /.row form -->
</div>
