import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { stringify } from 'querystring';
import { DisableModalComponent } from 'src/app/core/components/disable-modal/disable-modal.component';
import { MyTableOptionsInterface } from 'src/app/core/interface/my_table_data_interface';
import { TableInterface } from 'src/app/core/interface/table_interface';
import { myRouter } from 'src/app/core/route/my_route';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { MockService } from 'src/app/core/services/mock/mock.service';
import { apiUrl } from 'src/app/core/utils/api_url';
import { UserTableFilterInterface } from '../interface/user_table_filter_interface';
import { UserTableInterface } from '../interface/user_table_interface';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage-service';


@Component({
  selector: 'app-manage-user-table',
  templateUrl: './manage-user-table.component.html',
  styleUrls: ['./manage-user-table.component.scss'],
})
export class ManageUserTableComponent implements OnInit {
  mock: Function;
  /// todo
  /// temp solution only
  route = myRouter;

  role: string = '';
  user: string = 'Audit';

  @Output() emitterModify: EventEmitter<any> = new EventEmitter();

  tableOptionsResponse: MyTableOptionsInterface = {
    items: 0,
    size: 0,
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    hasNextPage: false,
    nextPage: 0,
    isLoading: false,
  };

  tableSizeAndPage = {
    page: 1,
    size: 10,
  };

  tableFilters: UserTableFilterInterface = {
    firstName: null,
    lastName: null,
    email: null,
    mobileNumber: null,
    dateFrom: null,
    dateTo: null,
    sortBy: {
      registrationDate: 'desc',
    },
  };

  @ViewChild(DisableModalComponent) disableModal: DisableModalComponent;
  constructor(
    private mockData: MockService,
    public http: ApiHelperService<TableInterface<UserTableInterface>>,
    private router: Router,
    private storage: LocalStorageService,

  ) {
    this.mock = this.mockData.createMockData;
  }

  ngOnInit(): void {
    this.getTableData({});
    this.role = this.storage.role();

  }



  clickViewTnx(data: UserTableInterface): void {
    const convertedData = btoa(JSON.stringify(data));
    console.log(convertedData);
    this.router.navigate([
      `${this.route.viewTransactionUser.path.replace(':id', convertedData)}`,
    ]);
  }

  setFilterData(data: UserTableFilterInterface): void {
    this.tableFilters = data;
    this.getTableData({});
  }

  clickModify(data: UserTableInterface): void {
    console.log(data, 'modify');
    this.emitterModify.emit(data);
  }

  navigatePagination(pageNumber: number): void {
    this.tableSizeAndPage.page = pageNumber;
    this.getTableData({});
  }
  openDisableModal(id: number): void {
    this.disableModal.openModal(
      `${apiUrl.deleteUser.replace(':id', id.toString())}`
    );
  }

  onChangeTableSize(pageSize: number): void {
    this.tableSizeAndPage.size = pageSize;
    this.getTableData({});
  }

  getTableData(filter: any): void {
    const tableSizeAndPage = this.tableSizeAndPage;
    this.http.post({
      url: `${apiUrl.getUsers}?page=${tableSizeAndPage.page}&size=${tableSizeAndPage.size}`,
      data: this.tableFilters,
      successCallBack: this.callBackSuccess,
    });
  }

  callBackSuccess = (): void => {
    let tableOptionsResponse = this.tableOptionsResponse;
    let tableData = this.http.apiDataState.result.result;
    tableOptionsResponse.totalItems = tableData.totalItems;
    tableOptionsResponse.totalPages = tableData.totalPages;
    tableOptionsResponse.currentPage = tableData.currentPage;
    tableOptionsResponse.size = tableData.size;
    tableOptionsResponse.hasNextPage = tableData.hasNextPage;
  };

  isRestrictedRole(): boolean {
    const restrictedRoles = [ 'Accounting'];
    return restrictedRoles.includes(this.role);
  }

  isRestrictedRole1(): boolean {
    const restrictedRoles = ['Audit'];
    return restrictedRoles.includes(this.role);
  }
  
  
}
