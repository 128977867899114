import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-svg',
  templateUrl: './filter-svg.component.html',
  styleUrls: ['./filter-svg.component.scss'],
})
export class FilterSvgComponent implements OnInit {
  @Output() clickFunction: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
  triggerEvent(): void {
    this.clickFunction.emit();
  }
}
