<nav class="navbar navbar-expand-lg navbar-light purple-bg">
  <div class="container">
    <a class="navbar-brand" href="#"
      ><img class="img-fluid" id="nav-icon" src="/assets/logo-white.png" />
      <span class="badge radius-sm bg-light purple-text my-text-bold-1"
        >ADMIN</span
      >
    </a>
    <form class="d-flex">
      <a class="btn text-light my-text-bold-1 f-weight-6" id="nav-login" type="submit"> LOG IN </a>
    </form>
  </div>
</nav>
