import { Component, OnInit } from '@angular/core';
import { EmptyResultInterface } from 'src/app/core/interface/empty_result_interface';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { SweetAlert2Service } from 'src/app/core/services/sweet-alert2/sweet_alert2';
import { apiUrl } from 'src/app/core/utils/api_url';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage-service';


@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
  providers: [ApiHelperService],
})
export class HowItWorksComponent implements OnInit {
  static readonly routeName: string = 'how-it-works';
  allFiles: File[] = [];
  role: string = '';
  user: string = 'Audit';

  constructor(
    public http: ApiHelperService<EmptyResultInterface>,
    private swal: SweetAlert2Service,
    private storage: LocalStorageService,

  ) {}

  ngOnInit(): void {
    this.role = this.storage.role();

  }

  droppedFiles(allFiles: File[]): void {
    console.log('test drop');
    const filesAmount = allFiles.length;
    for (let i = 0; i < filesAmount; i++) {
      const file = allFiles[i];
      console.log(file);
      this.allFiles.push(file);
    }
  }

  uploadVideo(): void {
    const file = this.allFiles;
    if (file.length <= 0) {
      this.swal.toastError('file is missing');
      return;
    }
    const formData = new FormData();
    formData.append('video', file[0]);

    this.http.post({
      data: formData,
      url: apiUrl.uploadHowItWorks,
      successCallBack: this.successCallBack,
    });
  }

  successCallBack = (): void => {
    this.allFiles = [];
  };
  isRestrictedRole1(): boolean {
    const restrictedRoles = ['Audit'];
    return restrictedRoles.includes(this.role);
  }
}
