import { StateInterface } from 'src/app/core/interface/state_interface';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiParameterInterface } from '../../interface/api_parameters_interface';
import { ResponseInterface } from '../../interface/response_interface';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage-service';
import { LocalStorageKeys } from '../../utils/local_storage_keys';
import { SweetAlert2Service } from 'src/app/core/services/sweet-alert2/sweet_alert2';
import { responseStatus } from 'src/app/core/utils/response_status';
import { throwError } from 'rxjs';
import { ApiErrorResponse } from './error_helper';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiHelperService<T> {
  baseUrl = environment.apiURL;

  // state handler
  apiDataState: StateInterface<ResponseInterface<T>> = {
    isLoading: false,
    isFail: false,
    isSuccess: false,
    result: <ResponseInterface<T>>{
      timestamp: '',
      status: 0,
      message: '',
      path: '',
      result: <T>{},
      error: '',
    },
  };

  constructor(
    private http: HttpClient,
    private storage: LocalStorageService,
    private swal: SweetAlert2Service
  ) {}

  setLoading(isLoading: boolean) {
    this.apiDataState.isLoading = isLoading;
  }
  setSuccess(isSuccess: boolean) {
    this.apiDataState.isSuccess = isSuccess;
  }

  get({
    url,
    data,
    headers,
    isAuth = true,
    successCallBack,
    failCallBack,
  }: ApiParameterInterface): StateInterface<ResponseInterface<T>> {
    const token = this.storage.get(LocalStorageKeys.token);
    const headerOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    this.setSuccess(false);
    this.setLoading(true);

    const httpResponse = this.http.get<ResponseInterface<T>>(
      `${this.baseUrl}${url}`,
      isAuth ? headerOption : { headers }
    );

    httpResponse.subscribe(
      (response) => {
        let message = response.message;
        this.apiDataState.result = response;
        let status = response.status;
        this.setLoading(false);
        if (status == responseStatus.failed) {
          this.swal.toastWarning(message);
          this.setLoading(false);
          this.setSuccess(false);
          if (failCallBack != null) {
            setTimeout(() => {
              typeof failCallBack === 'function' && failCallBack();
            }, 500);
          }
          return;
        } // status failed
        this.swal.toastSuccess(message);
        this.setSuccess(true);
        if (successCallBack != null) {
          setTimeout(() => {
            typeof successCallBack === 'function' && successCallBack();
            // successCallBack();
          }, 500);
        }
      },
      (error) => {
        this.swal.toastError('something went wrong');
        this.setLoading(false);
        this.setSuccess(false);
        console.log(error);
      }
    );

    return this.apiDataState;
  }
  post({
    url,
    data,
    headers,
    isAuth = true,
    successCallBack,
    failCallBack,
  }: ApiParameterInterface): StateInterface<ResponseInterface<T>> {
    const token = this.storage.get(LocalStorageKeys.token);
    const headerOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'multipart/form-data'
        // Connection: 'keep-alive',
      }),
    };
    this.setSuccess(false);
    this.setLoading(true);

    const httpResponse = this.http
      .post<ResponseInterface<T>>(
        `${this.baseUrl}${url}`,
        data,
        // headerOption
        isAuth ? headerOption : { headers }
      )
      .pipe(catchError(this.handleErrors));

    httpResponse.subscribe(
      (response) => {
        let message = response.message;
        this.apiDataState.result = response;
        let status = response.status;
        this.setLoading(false);
        if (status == responseStatus.failed) {
          this.swal.toastWarning(message);
          this.setLoading(false);
          this.setSuccess(false);
          if (failCallBack != null) {
            setTimeout(() => {
              typeof failCallBack === 'function' && failCallBack();
            }, 500);
          }
          return;
        } // status failed
        this.swal.toastSuccess(message);
        this.setSuccess(true);
        if (successCallBack != null) {
          setTimeout(() => {
            typeof successCallBack === 'function' && successCallBack();
            successCallBack();
          }, 500);
        }
      },
      (error) => {
        this.swal.toastError(error);
        this.setLoading(false);
        this.setSuccess(false);
        console.log(error);
        if (failCallBack != null) {
          setTimeout(() => {
            typeof failCallBack === 'function' && failCallBack();
          }, 500);
        }
      }
    );

    return this.apiDataState;
  }

  put({
    url,
    data,
    headers,
    isAuth = true,
    successCallBack,
    failCallBack,
  }: ApiParameterInterface): StateInterface<ResponseInterface<T>> {
    const token = this.storage.get(LocalStorageKeys.token);
    const headerOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'multipart/form-data'
        // 'Content-type': 'application/json',
      }),
    };

    this.setSuccess(false);
    this.setLoading(true);
    const httpResponse = this.http
      .put<ResponseInterface<T>>(
        `${this.baseUrl}${url}`,
        data,
        headerOption
        // isAuth ? headerOption : { headers }
      )
      .pipe(catchError(this.handleErrors));

    httpResponse.subscribe(
      (response) => {
        let message = response.message;
        this.apiDataState.result = response;
        let status = response.status;
        this.setLoading(false);
        if (status == responseStatus.failed) {
          console.log(this.apiDataState.isLoading);
          this.swal.toastWarning(message);
          this.setLoading(false);
          this.setSuccess(false);
          if (failCallBack != null) {
            setTimeout(() => {
              failCallBack();
            }, 500);
          }
          return;
        } // status failed
        this.swal.toastSuccess(message);
        this.setSuccess(true);
        if (successCallBack != null) {
          setTimeout(() => {
            successCallBack();
          }, 500);
        }
      },
      (error) => {
        this.swal.toastError(error);
        this.setLoading(false);
        this.setSuccess(false);
        console.log(error);
      }
    );

    return this.apiDataState;
  }

  delete({
    url,
    data,
    headers,
    isAuth = true,
    successCallBack,
    failCallBack,
  }: ApiParameterInterface): StateInterface<ResponseInterface<T>> {
    const token = this.storage.get(LocalStorageKeys.token);
    const headerOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      }),
    };
    this.setSuccess(false);
    this.setLoading(true);
    const httpResponse = this.http
      .delete<ResponseInterface<T>>(
        `${this.baseUrl}${url}`,
        isAuth ? headerOption : { headers }
      )
      .pipe(catchError(this.handleErrors));

    httpResponse.subscribe(
      (response) => {
        let message = response.message;
        this.apiDataState.result = response;
        let status = response.status;
        this.setLoading(false);
        if (status == responseStatus.failed) {
          console.log(this.apiDataState.isLoading);
          this.swal.toastWarning(message);
          this.setLoading(false);
          this.setSuccess(false);
          if (failCallBack != null) {
            setTimeout(() => {
              failCallBack();
            }, 500);
          }
          return;
        } // status failed
        this.swal.toastSuccess(message);
        this.setSuccess(true);
        if (successCallBack != null) {
          setTimeout(() => {
            successCallBack();
          }, 500);
        }
      },
      (error) => {
        this.swal.toastError(error);
        this.setLoading(false);
        this.setSuccess(false);
        console.log(error);
      }
    );

    return this.apiDataState;
  }

  handleErrors(error: any) {
    // console.log(error, 'console error');
    if (error instanceof HttpErrorResponse) {
      let apiError = new ApiErrorResponse(error.error);
      if (apiError) {
        console.log('1 err',apiError);
        return throwError(apiError.message || apiError.error || 'Api Error');
      }
      console.log('2 err');
      return throwError(error.message || 'Server Client Error');
    }
    console.log('3 err');
    return throwError(error.message || 'Something went wrong');
  }
}
