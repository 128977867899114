<form
  [formGroup]="adsFormBuilder"
  (ngSubmit)="createAds()"
  enctype="multipart/form-data"
>
  <div class="row justify-content-center">
    <div class="col-8">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label
            for="username"
            class="col-form-label form-label my-text-label-2"
            >Ads position:</label
          >
        </div>
        <div class="col">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="position"
              id="top"
              value="top"
              formControlName="position"
            />
            <label class="form-check-label" for="top">Top</label>
          </div>
          <!-- /.top -->
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="position"
              id="left"
              value="left"
              formControlName="position"
            />
            <label class="form-check-label" for="left">Left</label>
          </div>
          <!-- /.left -->
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="position"
              id="right"
              value="right"
              formControlName="position"
            />
            <label class="form-check-label" for="right">Right</label>
          </div>
          <!-- /.right -->
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="position"
              id="pop-up"
              value="pop-up"
              formControlName="position"
            />
            <label class="form-check-label" for="pop-up">Pop-up</label>
          </div>
          <!-- /.pop-up -->
        </div>
      </div>
      <!-- /. position -->
    </div>
    <!-- /.col -->
    <div class="col-8">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="title" class="col-form-label form-label my-text-label-2"
            >title:</label
          >
        </div>
        <div class="col">
          <input
            id="title"
            class="form-control input-design-2"
            placeholder="Enter ads title"
            formControlName="title"
          />
        </div>
      </div>
      <!-- /. title -->
    </div>
    <!-- /.col -->
    <div class="col-8">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label
            for="dateFrom"
            class="col-form-label form-label my-text-label-2"
            >Date from:</label
          >
        </div>
        <div class="col">
          <input
            id="dateFrom"
            class="form-control input-design-2"
            type="date"
            formControlName="dateFrom"
          />
        </div>
      </div>
      <!-- /. date -->
    </div>
    <!-- /.col -->
    <div class="col-8">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="dateTo" class="col-form-label form-label my-text-label-2"
            >Date To:</label
          >
        </div>
        <div class="col">
          <input
            id="dateTo"
            class="form-control input-design-2"
            type="date"
            formControlName="dateTo"
          />
        </div>
      </div>
      <!-- /. date -->
    </div>
    <!-- /.col -->
    <div class="col-md-8 col-12">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="campaginRewards" class="col-form-label form-label"
            >Upload Content:</label
          >
        </div>
        <div class="col">
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="file-input"
              (change)="onUploadFile($event)"
            />
            <label class="custom-file-label" for="exampleInputFile"
              >Choose file</label
            >
          </div>
        </div>
      </div>
      <!-- /. ads file -->
    </div>
    <!-- /.col -->
    <div class="col-md-8 col-12" *ngIf="editFile.length >= 1">
      <button
        (click)="clickRemoveFile()"
        class="img-x-btn orange-pill text-white px-2 text-decoration-none f-weight-6"
        type="button"
      >
        X
      </button>
      <img
        class="img-thumbnail rounded mx-auto d-block img-h-200"
        src="https://{{ editFile }}"
      />
    </div>
    <!-- /.col -->
    <div class="col-10 mb-3">
      <div class="text-center">
        <app-my-button
          buttonClass="btn my-btn-color-2 f-weight-6 align-self-center w-150"
          [isLoading]="http.apiDataState.isLoading"
          title="Upload"
          buttonType="submit"
        ></app-my-button>
      </div>
    </div>
    <!-- /.col -->
    <div class="col-10 mb-3">
      <hr />
    </div>
  </div>
</form>
