<div class="container">
  <div class="row mb-3">
    <div class="col-md-4 col-11 mt-4">
      <app-page-title pageTitle="Create Campaign"></app-page-title>
    </div>
    <!-- /.col -->
    <div class="col mt-4 align-self-end">
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <a
          [href]="route.campaignTable.path"
          role="button"
          class="orange-pill text-white px-2 text-decoration-none f-weight-6"
          type="button"
        >
          X
        </a>
      </div>
    </div>
    <!-- /.col -->
  </div>
  <!--  /. row buttons -->
  <div class="row justify-content-center">
    <div class="col-md-8 col-12 mb-3 align-self-end">
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button
          class="btn my-btn-color-2 me-md-2 mx-2"
          type="button"
          (click)="addReward()"
        >
          Add Rewards
        </button>
      </div>
    </div>
    <div class="col-md-8 col-12">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="campaignName" class="col-form-label form-label"
            >Campaign Name:</label
          >
        </div>
        <div class="col">
          <input
            id="campaignName"
            class="form-control input-design-2"
            placeholder="Enter campaign name"
            [(ngModel)]="campaignName"
          />
        </div>
      </div>
      <!-- /. campaign name -->
    </div>
    <!-- /.col -->
    <div class="col-md-8 col-12">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="campaginRewards" class="col-form-label form-label"
            >Set Slot Number:</label
          >
        </div>
        <div class="col">
          <input
            id="campaginRewards"
            class="form-control input-design-2"
            placeholder="Enter between 1-1000"
            type="number"
            [(ngModel)]="slotNumber"
          />
        </div>
      </div>
      <!-- /. campaign rewards -->
    </div>
    <!-- /.col -->
    <div class="col-md-8 col-12">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="campaginRewards" class="col-form-label form-label"
            >Amount Per Slot Number:</label
          >
        </div>
        <div class="col">
          <input
            id="campaginRewards"
            class="form-control input-design-2"
            placeholder="Set amount"
            type="number"
            [(ngModel)]="amountPerSlot"
          />
        </div>
      </div>
      <!-- /. amount per slots -->
    </div>
    <!-- /.col -->
    <div class="col-md-8 col-12">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="campaginRewards" class="col-form-label form-label"
            >Grand Prize:</label
          >
        </div>
        <div class="col">
          <input
            id="campaginRewards"
            class="form-control input-design-2"
            placeholder="Enter grand prize"
            [(ngModel)]="granPrize"
          />
        </div>
      </div>
      <!-- /. gran price -->
    </div>
    <!-- /.col -->
    <div class="col-md-8 col-12">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="campaginRewards" class="col-form-label form-label"
            >Upload Content:</label
          >
        </div>
        <div class="col">
          <div class="custom-file">
            <input
              (change)="onFileSelected($event)"
              type="file"
              class="custom-file-input"
              ([NgModel])="(fileInput)"
              id="file-input"
              multiple
            />
            <label class="custom-file-label" for="exampleInputFile"
              >Choose file</label
            >
          </div>
        </div>
      </div>
      <!-- /. campaign rewards -->
    </div>
    <!-- /.col -->
    <div class="col-md-8 col-12">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="startData" class="col-form-label form-label"
            >Start Date:</label
          >
        </div>
        <div class="col">
          <input
            id="startData"
            type="date"
            class="form-control input-design-2"
            [(ngModel)]="startDate"
          />
        </div>
      </div>
      <!-- /. start date -->
    </div>
    <!-- /.col -->
    <div class="col-md-8 col-12">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="endData" class="col-form-label form-label"
            >End Date:</label
          >
        </div>
        <div class="col">
          <input
            id="endData"
            type="date"
            class="form-control input-design-2"
            [(ngModel)]="endDate"
          />
        </div>
      </div>
      <!-- /. end date -->
    </div>
    <!-- /.col -->

    <div class="col-10 mb-3">
      <hr />
    </div>

    <div class="col-md-8 col-12 mb-3">
      <div class="card content-bottom-color">
        <div class="card-body">
          <div class="row">
            <!-- /.col -->
            <div class="col-12">
              <div class="row g-3 align-items-center mb-3">
                <div class="col-4">
                  <label for="amountPerSlot" class="col-form-label form-label"
                    >Consolation Prize:</label
                  >
                  <p class="mb-0 text-muted">
                    50<sup>th</sup> Draw - reward name
                    <br />
                    <small>(Example format)</small>
                  </p>
                </div>
                <div class="col">
                  <div class="col" id="consolation-rewards">
                    <!-- https://niisar.gitbooks.io/angular-plus-kitchen/content/directive/auto-format-input.html -->
                    <input
                      id="campaginRewards"
                      class="form-control input-design-2"
                      hidden
                    />
                    <!-- generated html rewards -->
                  </div>
                </div>
              </div>
              <!-- /. draw date -->
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.card body -->
      </div>
      <!-- /. card -->
    </div>
    <!-- /.col card -->
    <!-- /.col -->
    <div class="col-10 mb-3">
      <div class="d-flex">
        <button
          (click)="createCampaign()"
          class="btn my-btn-color-2 f-weight-6 align-self-center mx-auto btn-med"
          type="button"
        >
          SUBMIT
        </button>
      </div>
      <!-- /. d flex -->
    </div>
    <!-- /.col -->
  </div>
  <!-- /.row form -->
</div>

<app-over-lay-loading
  *ngIf="http.apiDataState.isLoading"
></app-over-lay-loading>
