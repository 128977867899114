<div
  class="modal fade"
  id="addFaqModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content radius-lg b-b-none">
      <div class="modal-header b-b-none">
        <h5 class="modal-title" id="">&nbsp;</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          id="closeModalfaq"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <form [formGroup]="modifyUserForm" (ngSubmit)="submitModifyForm()"> -->
        <form [formGroup]="faqFormBuilder" (ngSubmit)="addFaq()">
          <div class="row justify-content-center mb-3">
            <div class="col-8">
              <div class="row g-3 align-items-center mb-3">
                <div class="col-4">
                  <label
                    for="question"
                    class="col-form-label form-label my-text-label-2"
                    >Question:</label
                  >
                </div>
                <div class="col">
                  <input
                    id="question"
                    class="form-control input-design-3"
                    formControlName="question"
                    placeholder="Enter question"
                  />
                </div>
              </div>
              <!-- /. mobileNumber -->
            </div>
            <!-- /.col -->
            <div class="col-8">
              <div class="row g-3 align-items-center mb-3">
                <div class="col-4">
                  <label
                    for="answer"
                    class="col-form-label form-label my-text-label-2"
                    >Answer:</label
                  >
                </div>
                <div class="col">
                  <input
                    id="answer"
                    class="form-control input-design-3"
                    formControlName="answer"
                    placeholder="Enter answer"
                  />
                </div>
              </div>
              <!-- /. campaignId -->
            </div>
            <!-- /.col -->
            <div class="col-10 mb-3">
              <div class="text-center">
                <app-my-button
                  buttonClass="btn my-btn-color-2 f-weight-6 align-self-center w-150"
                  [isLoading]="http.apiDataState.isLoading"
                  title="Save"
                  buttonType="submit"
                ></app-my-button>
              </div>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row form -->
        </form>
      </div>
      <div class="modal-footer b-t-none"></div>
    </div>
  </div>
</div>
<!-- /. modal modify end -->
