import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import bsCustomFileInput from 'bs-custom-file-input';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { SweetAlert2Service } from 'src/app/core/services/sweet-alert2/sweet_alert2';
import { apiUrl } from 'src/app/core/utils/api_url';

@Component({
  selector: 'app-add-image-ads',
  templateUrl: './add-image-ads.component.html',
  styleUrls: ['./add-image-ads.component.scss'],
  providers: [ApiHelperService],
})
export class AddImageAdsComponent implements OnInit {
  @Output() reloadAds: EventEmitter<any> = new EventEmitter();
  id: number = null;

  formData = new FormData();
  adsWebFile: File = null;
  adsMobileFile: File = null;

  constructor(
    public http: ApiHelperService<{}>,
    private swal: SweetAlert2Service
  ) {}

  ngOnInit(): void {
    bsCustomFileInput.init();
    this.clearMobileInput();
    this.clearWebInput();
  }

  setId(uid: number): void {
    this.id = uid;
  }

  onUploadFileWeb(event): void {
    if (event.target.files.length <= 0) {
      this.formData.append('webFile', null);
      return;
    }

    for (let i = 0; i < event.target.files.length; i++) {
      this.formData.append('webFile', event.target.files[i]);
      this.adsWebFile = event.target.files[i];
    }
  }

  onUploadFileMobile(event): void {
    if (event.target.files.length <= 0) {
      this.formData.append('mobileFile', null);
      return;
    }

    for (let i = 0; i < event.target.files.length; i++) {
      this.formData.append('mobileFile', event.target.files[i]);
      this.adsMobileFile = event.target.files[i];
    }
  }
  clearMobileInput(): void {
    this.formData.append('mobileFile', null);
    this.adsMobileFile = null;
    var inputValue = <HTMLInputElement>(
      document.getElementById('mobile-file-input')
    );
    inputValue.value = '';
    inputValue.dispatchEvent(new Event('change'));
  }

  clearWebInput(): void {
    this.formData.append('webFile', null);
    this.adsWebFile = null;
    var inputValue = <HTMLInputElement>(
      document.getElementById('web-file-input')
    );
    inputValue.value = '';
    inputValue.dispatchEvent(new Event('change'));
  }

  submitUpload(): void {
    // console.log(this.adsWebFile, 'adsWebFile');
    // console.log(this.adsMobileFile, 'adsMobileFile');
    if (this.adsWebFile == null || this.adsMobileFile == null) {
      this.swal.toastInfo('Upload atleast one of missing file.');
      return;
    }
    this.http.post({
      url: apiUrl.uploadContentAds.replace(':id', this.id.toString()),
      data: this.formData,
      successCallBack: this.successCallBack,
    });
  }

  successCallBack = (): void => {
    this.clearWebInput();
    this.clearMobileInput();
  };
}
