<app-download-button></app-download-button>
<app-my-table
  [titleTemplates]="titleTemplates"
  [dataTemplate]="dataTemplate"
  [tableOptions]="tableOptionsResponse"
  (onChangeTableSize)="onChangeTableSize($event)"
  (navigatePagination)="navigatePagination($event)"
>
  <ng-template #titleTemplates>
    <th scope="col" class="th-table b-l-none">ID</th>
    <th scope="col" class="th-table">first name</th>
    <th scope="col" class="th-table">last name</th>
    <th scope="col" class="th-table">Mobile Number</th>
    <th scope="col" class="th-table">email</th>
    <th scope="col" class="th-table">Status</th>
    <th scope="col" class="th-table">last modified</th>
    <th scope="col" class="th-table">registration date</th>
    <th scope="col" class="th-table b-r-none" *ngIf="!isRestrictedRole1()">action</th>
  </ng-template>
  <ng-template #dataTemplate>
    <ng-container *ngIf="http.apiDataState.isLoading">
      <tr *ngFor="let in of mock(10); let i = index">
        <td class="td-table b-l-none">
          <app-skeleton-loader Cwidth="20"></app-skeleton-loader>
        </td>
        <td class="td-table">
          <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
        </td>
        <td class="td-table">
          <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
        </td>
        <td class="td-table">
          <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
        </td>
        <td class="td-table">
          <app-skeleton-loader Cwidth="100"></app-skeleton-loader>
        </td>
        <td class="td-table">
          <app-skeleton-loader Cwidth="70"></app-skeleton-loader>
        </td>
        <td class="td-table">
          <app-skeleton-loader Cwidth="70"></app-skeleton-loader>
        </td>
        <td class="td-table">
          <app-skeleton-loader Cwidth="50"></app-skeleton-loader>
        </td>
        <td class="b-r-none b-r-none">
          <app-skeleton-loader Cwidth="150"></app-skeleton-loader>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="!http.apiDataState.isLoading">
      <tr
        *ngFor="let data of http.apiDataState.result.result.data; let i = index"
      >
        <td class="td-table b-l-none">{{ data.id }}</td>
        <td class="td-table">{{ data.firstName }}</td>
        <td class="td-table">{{ data.lastName }}</td>
        <td class="td-table">{{ data.mobileNumber }}</td>
        <td class="td-table">{{ data.email }}</td>
        <td class="td-table">{{ data.status }}</td>
        <td class="td-table">{{ data.lastModified }}</td>
        <td class="td-table">{{ data.registrationDate }}</td>
        <td class="w-100 d-flex b-r-none" *ngIf="!isRestrictedRole1()">
          <span
          *ngIf="!isRestrictedRole()"
            (click)="clickModify(data)"
            class="badge pointer-cursor green-bg text-white mx-1 text-size-action-button-table"
            >Modify</span
          >
          <a
          *ngIf="!isRestrictedRole()"
            role="button"
            class="badge pointer-cursor red-bg text-white mx-1 text-size-action-button-table"
            (click)="openDisableModal(data.id)"
            data-toggle="modal"
            data-target="#deleteModal"
            >Disabled</a
          >
          <a
            role="button"
            (click)="clickViewTnx(data)"
            class="badge pointer-cursor purple-dark-bg text-white mx-1 text-size-action-button-table"
            >View Transaction</a
          >
        </td>
      </tr>
    </ng-container>
  </ng-template>
</app-my-table>
<app-disable-modal></app-disable-modal>
