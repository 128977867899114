import { LoginComponent } from './features/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './core/components/admin-layout/admin-layout.component';
import { TransactionComponent } from './features/transaction/transaction.component';
import { CampaignComponent } from './features/campaign/campaign.component';
import { StartRaffleComponent } from './features/campaign/start-raffle/start-raffle.component';
import { PortalAccountsComponent } from './features/portal-accounts/portal-accounts.component';
import { ManageUserComponent } from './features/manage-user/manage-user.component';
import { AboutUsComponent } from './features/website-content/about-us/about-us.component';
import { FaqComponent } from './features/website-content/faq/faq.component';
import { HowItWorksComponent } from './features/website-content/how-it-works/how-it-works.component';
import { CreateCampaignComponent } from './features/campaign/create-campaign/create-campaign.component';
import { JoinersCampaignComponent } from './features/campaign/joiners-campaign/joiners-campaign.component';
import { ModifyCampaignComponent } from './features/campaign/modify-campaign/modify-campaign.component';
import { ViewUserTransactionsComponent } from './features/manage-user/view-user-transactions/view-user-transactions.component';
import { AuthenticantionGuard } from './core/services/auth/authenticantion.guard';
import { myRouter } from './core/route/my_route';
import { AdsComponent } from './features/website-content/ads/ads.component';
import { TableEventsComponent } from './features/ngoEvents/table-events/table-events.component';
import { ModifyEventsComponent } from './features/ngoEvents/modify-events/modify-events.component';
import { CreateEventsComponent } from './features/ngoEvents/create-events/create-events.component';
import { DonationsReportComponent } from './features/donations-report/donations-report.component';

export const routes: Routes = [
  {
    path: 'admin',
    component: AdminLayoutComponent,
    children: [
      {
        path: TransactionComponent.routeName,
        component: TransactionComponent,
        canActivate: [AuthenticantionGuard],
        data: {
          role: myRouter.viewCashInTransaction.notAllowedRole,
        },
      },
      {
        path: DonationsReportComponent.routeName,
        component: DonationsReportComponent,
        canActivate: [AuthenticantionGuard],
        data: {
          role: myRouter.viewDonationsReport.notAllowedRole,
        },
      },
      {
        path: CampaignComponent.routeName,
        component: CampaignComponent,
        canActivate: [AuthenticantionGuard],
        data: {
          role: myRouter.campaignTable.notAllowedRole,
        },
      },
      {
        path: 'draw/:data',
        component: StartRaffleComponent,
        canActivate: [AuthenticantionGuard],
        data: {
          role: myRouter.campaignTable.notAllowedRole,
        },
      },
      {
        path: CreateCampaignComponent.routeName,
        component: CreateCampaignComponent,
        canActivate: [AuthenticantionGuard],
        data: {
          role: myRouter.createCampaign.notAllowedRole,
        },
      },
      {
        path: ModifyCampaignComponent.routeName,
        component: ModifyCampaignComponent,
        canActivate: [AuthenticantionGuard],
        data: {
          role: myRouter.modifyCampaign.notAllowedRole,
        },
      },
      {
        path: JoinersCampaignComponent.routeName,
        component: JoinersCampaignComponent,
        canActivate: [AuthenticantionGuard],
        data: {
          role: myRouter.modifyJoinersCampaign.notAllowedRole,
        },
      },
      {
        path: StartRaffleComponent.routeName,
        component: StartRaffleComponent,
        canActivate: [AuthenticantionGuard],
      },
      {
        path: PortalAccountsComponent.routeName,
        component: PortalAccountsComponent,
        canActivate: [AuthenticantionGuard],
        data: {
          role: myRouter.portalTable.notAllowedRole,
        },
      },
      {
        path: ManageUserComponent.routeName,
        component: ManageUserComponent,
        canActivate: [AuthenticantionGuard],
        data: {
          role: myRouter.userTable.notAllowedRole,
        },
      },
      {
        path: ViewUserTransactionsComponent.routeName,
        component: ViewUserTransactionsComponent,
        canActivate: [AuthenticantionGuard],
        data: {
          role: myRouter.viewTransactionUser.notAllowedRole,
        },
      },

      {
        path: AboutUsComponent.routeName,
        component: AboutUsComponent,
        canActivate: [AuthenticantionGuard],
        data: {
          role: myRouter.aboutUs.notAllowedRole,
        },
      },
      {
        path: FaqComponent.routeName,
        component: FaqComponent,
        canActivate: [AuthenticantionGuard],
        data: {
          role: myRouter.faq.notAllowedRole,
        },
      },
      {
        path: HowItWorksComponent.routeName,
        component: HowItWorksComponent,
        canActivate: [AuthenticantionGuard],
        data: {
          role: myRouter.howItWorks.notAllowedRole,
        },
      },
      {
        path: AdsComponent.routeName,
        component: AdsComponent,
        canActivate: [AuthenticantionGuard],
        data: {
          role: myRouter.ads.notAllowedRole,
        },
      },
      {
        path: TableEventsComponent.routeName,
        component: TableEventsComponent,
        canActivate: [AuthenticantionGuard],
        data: {
          role: myRouter.ads.notAllowedRole,
        },
      },
      {
        path: ModifyEventsComponent.routeName,
        component: ModifyEventsComponent,
        canActivate: [AuthenticantionGuard],
        data: {
          role: myRouter.ads.notAllowedRole,
        },
      },
      {
        path: CreateEventsComponent.routeName,
        component: CreateEventsComponent,
        canActivate: [AuthenticantionGuard],
        data: {
          role: myRouter.ads.notAllowedRole,
        },
      },
    ],
  },
  { path: '', redirectTo: LoginComponent.routeName, pathMatch: 'full' },
  { path: LoginComponent.routeName, component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
