<div
  class="modal fade"
  id="apppoved-preview"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content radius-lg b-b-none">
      <div class="modal-header b-b-none">
        <h5 class="modal-title" id="exampleModalLabel">&nbsp;</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row justify-content-center mb-3">
          <div class="col-10 text-center">
            <div
                *ngIf="imageList.length >= 1"
              id="carouselExampleControls"
              class="carousel slide campaing-card"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#carouselExampleIndicators"
                  *ngFor="let data of imageList; let i = index"
                  [data-slide-to]="index"
                  [ngClass]="{'active': i == 0}"
                ></li>
              </ol>
              <div class="carousel-inner">
                <div
                  *ngFor="let data of imageList; let i = index"
                  class="carousel-item "
                  [ngClass]="{'active': i == 0}"
                >
                  <img
                    src="{{ 'https://' + data.trim() }}"
                  />
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-target="#carouselExampleControls"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-target="#carouselExampleControls"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </button>
            </div>
          </div>
          <div class="col-10 mb-3 justify-content text-center">
            <button
              class="btn my-btn-color-2 f-weight-6 align-self-center mx-2 w-150 approved-btn"
              type="button"
              data-dismiss="modal"
              (click)="approvedCampaign()"
            >
              Approved
            </button>
          </div>
          <!-- /.col -->
        </div>
      </div>
      <div class="modal-footer b-t-none"></div>
    </div>
  </div>
</div>

<app-over-lay-loading
  *ngIf="httpCampaign.apiDataState.isLoading"
></app-over-lay-loading>
