<div class="container" [hidden]="isHiddenDraw">
  <div class="row mb-3">
    <div class="col-4 mt-4">
      <app-page-title pageTitle="Manage Campaign"></app-page-title>
    </div>
    <!-- /.col -->
    <div class="col mt-4 align-self-end">
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <a
          [routerLink]="route.createCampaign.path"
          role="button"
          class="my-btn-color-2 text-white px-2 text-decoration-none f-weight-6"
          type="button"
        >
          Create New
        </a>
      </div>
    </div>
    <!-- /.col -->
  </div>
  <!--  /. row buttons -->
  <form class="row" [formGroup]="filterForm" (ngSubmit)="submitFilter()">
    <div class="col-6">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="campaign_name" class="col-form-label form-label"
            >Campaign Name:</label
          >
        </div>
        <div class="col">
          <input
            id="campaign_name"
            class="form-control input-design-2"
            formControlName="campaignName"
          />
        </div>
      </div>
      <!-- /. campaign name -->
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="campaign_id" class="col-form-label form-label"
            >Campaign ID:</label
          >
        </div>
        <div class="col">
          <input
            id="campaign_id"
            class="form-control input-design-2"
            formControlName="campaignId"
          />
        </div>
      </div>
      <!-- /. campaign id -->
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4">
          <label for="campaign_id" class="col-form-label form-label"
            >Status:</label
          >
        </div>
        <div class="col">
          <select
            class="custom-select input-design-2"
            id="status"
            formControlName="status"
          >
            <option value="" selected>Please select status...</option>
            <option value="ACTIVE">Active</option>
            <option value="FOR REVIEW">FOR REVIEW</option>
            <option value="DISABLED">Disabled</option>
          </select>
        </div>
      </div>
      <!-- /. campaign status -->
    </div>
    <!-- /.left side -->
    <div class="col-6">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4 text-end">
          <label for="dateFrom" class="col-form-label form-label">From:</label>
        </div>
        <div class="col">
          <input
            id="dateFrom"
            class="form-control input-design-2"
            type="date"
            formControlName="dateFrom"
          />
        </div>
      </div>
      <!-- /. campaign from -->
      <div class="row g-3 align-items-center mb-3">
        <div class="col-4 text-end">
          <label for="dateTo" class="col-form-label form-label">To:</label>
        </div>
        <div class="col">
          <input
            id="dateTo"
            class="form-control input-design-2"
            type="date"
            formControlName="dateTo"
          />
        </div>
      </div>
      <!-- /. campaign to -->
    </div>
    <!-- /. right side -->
    <div class="col-12">
      <div class="d-grid d-flex justify-content-end">
        <button class="btn my-btn-color-3 my-2 mx-2 btn-xs" type="submit">
          filter
        </button>
      </div>
    </div>
    <!-- /.col -->
  </form>
  <!-- /.row form -->
</div>

<app-campaign-table
  [isHiddenDraw]="isHiddenDraw"
  (toggleHiddenDrawParent)="toggleHiddenDraw()"
></app-campaign-table>
