import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { EmptyResultInterface } from 'src/app/core/interface/empty_result_interface';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { SweetAlert2Service } from 'src/app/core/services/sweet-alert2/sweet_alert2';
import { apiUrl } from 'src/app/core/utils/api_url';
import { FAQInterface } from '../interface/faq_interface';

@Component({
  selector: 'app-modify-faq',
  templateUrl: './modify-faq.component.html',
  styleUrls: ['./modify-faq.component.scss'],
  providers: [ApiHelperService],
})
export class ModifyFaqComponent implements OnInit {
  @Output() reloadFaq: EventEmitter<any> = new EventEmitter();

  selectedFaq: FAQInterface = {
    uid: 0,
    question: '',
    answer: '',
    createdBy: '',
    dateCreated: '',
    updatedBy: '',
    dateUpdated: '',
    deletedBy: '',
    dateDeleted: '',
  };

  faqFormBuilder = this.formBuilder.group({
    question: ['', Validators.required],
    answer: ['', Validators.required],
  });
  constructor(
    public http: ApiHelperService<EmptyResultInterface>,
    private formBuilder: FormBuilder,
    private swal: SweetAlert2Service
  ) {}

  ngOnInit(): void {}

  get faqControl() {
    return this.faqFormBuilder.controls;
  }
  setSelectedFaq(data: FAQInterface): void {
    this.selectedFaq = data;

    const formValidator = this.faqControl;
    formValidator.answer.setValue(data.answer);
    formValidator.question.setValue(data.question);
  }

  submitModifyFaq(): void {
    const formValidator = this.faqControl;

    if (formValidator.question.invalid) {
      this.swal.toastError('question is required');
      return;
    }
    if (formValidator.answer.invalid) {
      this.swal.toastError('answer is required');
      return;
    }

    this.http.put({
      url: apiUrl.modifyFaq.replace(':id', this.selectedFaq.uid.toString()),
      data: this.faqFormBuilder.value,
      successCallBack: this.successCallBack,
    });
  }

  successCallBack = (): void => {
    const closeModal = document.getElementById('closeModalModifyfaq');
    closeModal.click();
    this.reloadFaq.emit();
  };
}
