import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { myRouter } from '../../../core/route/my_route';
import { ApiHelperService } from '../../../core/services/api-helper/api-helper.service';
import { apiUrl } from '../../../core/utils/api_url';
import { FormBuilder, Validators } from '@angular/forms';
import { EmptyResultInterface } from '../../../core/interface/empty_result_interface';
import { ImageListInterface } from './interface/image_list_interface';
import {
  grandPrize,
  consolationPrize,
  removeReward,
  createRewardsInput,
  resetInputRewards,
} from 'src/app/core/utils/rewards_helper';
import { CampaignInterface } from '../interface/campaign_table_interface';
@Component({
  selector: 'app-modify-campaign',
  templateUrl: './modify-campaign.component.html',
  styleUrls: ['./modify-campaign.component.scss'],
  providers: [ApiHelperService],
})
export class ModifyCampaignComponent implements OnInit {
  static readonly routeName: string = 'modify-campaign/:id';
  /// todo
  /// temp solution only
  route = myRouter;
  id: string;
  campaignForm = this.formBuilder.group({
    name: ['', Validators.required],
    rewards: ['', Validators.required],
    slotTotal: ['', Validators.required],
    amountPerSlot: ['', Validators.required],
    consolationDrawNumbers: ['', Validators.required],
    grandPrize: [''],
  });

  imageList: string[] = [];
  uploadNewContentData = new FormData();

  selectedGrandPrize: string = '';
  selectedConsolationPrize: string[] = [];

  loopRewardHolder: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    public httpCampaign: ApiHelperService<CampaignInterface>,
    public modifyCampaign: ApiHelperService<EmptyResultInterface>,
    private formBuilder: FormBuilder
  ) {
    const selectedId = this.activatedRoute.snapshot.params['id'];
    this.id = selectedId;
    this.getCampaign();
  }

  ngOnInit(): void {}

  prepareRewards = () => {
    return this.sleep(1000).then((v) => {
      const elements = document.getElementsByClassName('rewards-inputs');
      const form = this.campaignControl;
      let loopReward = '';
      let loopConsolationNumber = '';
      for (let i = 0; i < elements.length; i++) {
        const inputs = elements[i] as HTMLInputElement;
        const myNumber = parseInt(inputs.value);
        loopReward += `${inputs.value}:`;
        loopConsolationNumber += `${myNumber},`;
      }
      loopReward += form.grandPrize.value;
      this.loopRewardHolder = loopReward;
    });
  };

  resetData(): void {
    resetInputRewards();
    this.imageList = [];
    this.selectedGrandPrize = '';
    this.selectedConsolationPrize = [];
  }

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  prepareFile = (event) => {
    const formData = this.uploadNewContentData;

    if (event.target.files.length <= 0) {
      formData.append('newUploadedContents', '');
      return;
    }
    return this.sleep(1000).then((v) => {
      for (let i = 0; i < event.target.files.length; i++) {
        formData.append('newUploadedContents[]', event.target.files[i]);
      }
    });
  };

  async onFileSelected(event): Promise<void> {
    if (event.target.files.length <= 0) {
      return;
    }
    await this.prepareFile(event);
    this.uploadNewContent();
  }

  uploadNewContent(): void {
    this.modifyCampaign.put({
      url: apiUrl.modifyCampaign.replace(':uid', this.id),
      data: this.uploadNewContentData,
      successCallBack: this.successUploadContent,
    });
  }

  successUploadContent = (): void => {
    var inputValue = <HTMLInputElement>(
      document.getElementById('upload-content-input')
    );
    inputValue.value = '';
    inputValue.dispatchEvent(new Event('change'));
    this.uploadNewContentData.delete('newUploadedContents[]');
    this.getCampaign();
  };

  openUpload(): void {
    let element: HTMLElement = document.getElementById(
      'upload-content-input'
    ) as HTMLElement;

    element.click();
  }

  get campaignControl() {
    return this.campaignForm.controls;
  }

  getCampaign(): void {
    this.httpCampaign.get({
      url: `${apiUrl.getOneCampaign.replace(':id', this.id)}`,
      successCallBack: this.successGetCampaign,
    });
  }

  successGetCampaign = (): void => {
    const campaignData = this.httpCampaign.apiDataState.result.result;
    const form = this.campaignControl;
    this.resetData();
    form.name.setValue(campaignData.name);
    this.selectedGrandPrize = grandPrize(campaignData.rewards);
    form.grandPrize.setValue(grandPrize(campaignData.rewards));
    form.rewards.setValue(campaignData.rewards);
    form.slotTotal.setValue(campaignData.slotTotal);
    form.amountPerSlot.setValue(campaignData.amountPerSlot);
    form.consolationDrawNumbers.setValue(campaignData.consolationDrawNumbers);

    // consolation prize
    let consRewards = consolationPrize(campaignData.rewards);
    this.selectedConsolationPrize = consRewards;
    consRewards.forEach((value, index) => {
      createRewardsInput(value, index,2);
    });

    const imgReplace = campaignData.uploadedContents
      .replace('[', '')
      .replace(']', '')
      .split(',');

    this.imageList = imgReplace[0] == '' ? [] : imgReplace;
  };

  addReward(): void {
    let myRewards = this.selectedConsolationPrize;
    const indexRewards = myRewards.length;
    createRewardsInput('', indexRewards,2);
  }

  deleteImage(img: string): void {
    let uid = this.httpCampaign.apiDataState.result.result.uid.toString();
    const formData = new FormData();
    formData.append('contentsToBeDeleted', img);
    this.modifyCampaign.put({
      url: apiUrl.modifyCampaign.replace(':uid', uid),
      data: formData,
      successCallBack: this.successModifyRetrieve,
    });
  }

  async submitModifyCampaign(): Promise<void> {
    await this.prepareRewards();
    const form = this.campaignControl;
    const formDataUpdate = new FormData();
    let uid = this.httpCampaign.apiDataState.result.result.uid.toString();
    formDataUpdate.append('name', form.name.value);
    formDataUpdate.append('rewards', this.loopRewardHolder);
    this.modifyCampaign.put({
      url: apiUrl.modifyCampaign.replace(':uid', uid),
      data: formDataUpdate,
      successCallBack: this.successModifyRetrieve,
    });
  }

  deleteCampaign(): void {
    let uid = this.httpCampaign.apiDataState.result.result.uid.toString();
    this.modifyCampaign.delete({
      url: apiUrl.deleteCampaign.replace(':uid', uid),
      successCallBack: this.successModifyRetrieve,
    });
  }

  campaignForceDraw(): void {
    let campaingId = this.httpCampaign.apiDataState.result.result.campaignId;
    alert(campaingId);
    this.modifyCampaign.post({
      url: apiUrl.campaignForceDraw,
      data: {
        campaingId: campaingId,
      },
      successCallBack: this.successModifyRetrieve,
    });
  }

  successModifyRetrieve = (): void => {
    this.resetData();
    this.getCampaign();
  };

  imageConverter(imgList: string[]): ImageListInterface[] {
    let holderImgList: ImageListInterface[] = [];
    const data = imgList.forEach((data) => {
      const newUrl = `https://${data}`;
      holderImgList.push({
        newUrl: `${newUrl.replace(/\s/g, '')}`,
        originalUrl: data,
      });
    });
    return holderImgList;
  }
}
