<div [hidden]="isHiddenModify || isHiddenTnx">
  <div class="container">
    <div class="row mb-3">
      <div class="col-4 mt-4">
        <app-page-title pageTitle="Best Pick User Accounts"></app-page-title>
      </div>
      <!-- /.col -->
    </div>
    <!--  /. row buttons -->
    <!-- /.filters -->
    <form [formGroup]="filterForm" (ngSubmit)="submitFilter()">
      <div class="row">
        <div class="col-6">
          <div class="row g-3 align-items-center mb-3">
            <div class="col-4">
              <label for="firstName" class="col-form-label form-label"
                >First Name:</label
              >
            </div>
            <div class="col">
              <input
                id="firstName"
                class="form-control input-design-2"
                formControlName="firstName"
              />
            </div>
          </div>
          <!-- /.  name -->
          <div class="row g-3 align-items-center mb-3">
            <div class="col-4">
              <label for="lastName" class="col-form-label form-label"
                >Last Name:</label
              >
            </div>
            <div class="col">
              <input
                id="lastName"
                class="form-control input-design-2"
                formControlName="lastName"
              />
            </div>
          </div>
          <!-- /. mobile_number -->
          <div class="row g-3 align-items-center mb-3">
            <div class="col-4">
              <label for="email" class="col-form-label form-label"
                >Email:</label
              >
            </div>
            <div class="col">
              <input
                id="email"
                class="form-control input-design-2"
                formControlName="email"
              />
            </div>
          </div>
          <!-- /. transactions_id -->
          <div class="row g-3 align-items-center mb-3">
            <div class="col-4">
              <label for="mobileNumber" class="col-form-label form-label"
                >MobileNumber:</label
              >
            </div>
            <div class="col">
              <input
                id="mobileNumber"
                class="form-control input-design-2"
                formControlName="mobileNumber"
              />
            </div>
          </div>
          <!-- /. mode_of_payment -->
        </div>
        <!-- /.left side -->
        <div class="col-6">
          <div class="row g-3 align-items-center mb-3">
            <div class="col-4 text-end">
              <label for="dateFrom" class="col-form-label form-label"
                >From:</label
              >
            </div>
            <div class="col">
              <input
                id="dateFrom"
                class="form-control input-design-2"
                type="date"
                formControlName="dateFrom"
              />
            </div>
          </div>
          <!-- /. campaign from -->
          <div class="row g-3 align-items-center mb-3">
            <div class="col-4 text-end">
              <label for="dateTo" class="col-form-label form-label">To:</label>
            </div>
            <div class="col">
              <input
                id="dateTo"
                class="form-control input-design-2"
                type="date"
                formControlName="dateTo"
              />
            </div>
          </div>
          <!-- /. campaign to -->
        </div>
        <!-- /. right side -->
        <div class="col-12">
          <div class="d-grid d-flex justify-content-end">
            <button class="btn my-btn-color-3 my-2 mx-2 btn-xs" type="submit">
              filter
            </button>
          </div>
        </div>
      </div>
      <!-- /.row form -->
    </form>
    <!-- /. filters -->
  </div>
  <div class="content-bottom-color p-2">
    <app-manage-user-table
      (emitterModify)="toggleModify($event)"
    ></app-manage-user-table>
  </div>
  <!-- /.col -->
</div>
<!-- /. table -->

<app-modify-user
  [hidden]="!isHiddenModify"
  (emitterToggleOff)="toggleOff()"
></app-modify-user>
<!-- modify -->
