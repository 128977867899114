<div
  class="modal fade"
  id="deleteFaq"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content radius-lg b-b-none">
      <div class="modal-header b-b-none">
        <h5 class="modal-title" id="exampleModalLabel">&nbsp;</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          id="closeDeleteFaq"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row justify-content-center mb-3">
          <div class="col-10 text-center">
            <p class="my-4 my-text-bold-1">
              Are you sure you want to <br />
              delete this FAQ?
            </p>
          </div>
          <div class="col-10 mb-3 justify-content text-center">
            <button
              class="btn my-btn-color-2 f-weight-6 align-self-center mx-2 w-150"
              type="button"
              (click)="deleteFaq()"
            >
              Yes
            </button>
            <button
              id="close-modal-delete"
              class="btn my-btn-color-2 f-weight-6 align-self-center mx-2 w-150"
              type="button"
              data-dismiss="modal"
            >
              No
            </button>
          </div>
          <!-- /.col -->
        </div>
      </div>
      <div class="modal-footer b-t-none"></div>
    </div>
  </div>
</div>
<!-- /. modal delete end -->
