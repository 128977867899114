<div class="container">
  <div class="row mb-3">
    <div class="col-4 mt-4">
      <app-page-title pageTitle="ADS DASHBOARD"></app-page-title>
    </div>
    <!-- /.col -->
  </div>
  <!--  /. row buttons -->
  <app-ads-form (reloadAds)="reloadAds()" *ngIf="!isRestrictedRole1()"></app-ads-form>
  <!-- /.row form -->
  <app-ads-table (setModifyAdsEmitter)="passedModifyData($event)"></app-ads-table>
</div>
<!-- /. end of content -->
