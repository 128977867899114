<div class="container">
  <div class="row mb-3">
    <div class="col-4 mt-4">
      <app-page-title pageTitle="consolation winners"></app-page-title>
    </div>
    <!-- /.col -->
    <div class="col mt-4 align-self-end">
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <a
          [href]="route.campaign.items[0].path"
          role="button"
          class="orange-pill text-white px-2 text-decoration-none f-weight-6"
          type="button"
        >
          X
        </a>
      </div>
    </div>
    <!-- /.col -->
  </div>
  <!--  /. row buttons -->
  <div class="row justify-content-center">
    <div class="col-8 mb-3">
      <div class="table-responsive">
        <table class="table table-striped table-sm">
          <thead>
            <tr class="purple-bg text-white">
              <th scope="col">Draw count</th>
              <th scope="col">Slot number</th>
              <th scope="col">Winner Name</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>91st</td>
              <td>18</td>
              <td>Juan Dela Cruz</td>
            </tr>
            <tr>
              <td>5th</td>
              <td>1</td>
              <td>Juan Dela Cruz</td>
            </tr>
            <tr>
              <td>70th</td>
              <td>69</td>
              <td>Juan Dela Cruz</td>
            </tr>
            <tr>
              <td>5th</td>
              <td>66</td>
              <td>Juan Dela Cruz</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /.table responsive -->
    </div>
    <!-- /.col consolation table -->
  </div>
  <!-- /.row form -->
  <div class="row mb-3">
    <div class="col-4 mt-4">
      <app-page-title pageTitle="Grand winner"></app-page-title>
    </div>
    <!-- /.col -->
  </div>
  <!-- /.row grand winners -->
  <div class="row justify-content-center">
    <div class="col-8 mb-3">
      <div class="table-responsive">
        <table class="table table-striped table-sm">
          <thead>
            <tr class="purple-bg text-white">
              <th scope="col">Slot number</th>
              <th scope="col">Winner Name</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>18</td>
              <td>Juan Dela Cruz</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /.table responsive -->
    </div>
    <div class="col-10 mb-3">
      <hr />
    </div>
    <!-- /.col consolation table -->
    <div class="col-10 mb-3">
      <div class="d-flex">
        <button
          class="btn my-btn-color-1 f-weight-6 align-self-center mx-auto"
          type="button"
        >
          VIEW JOINERS LIST
        </button>
      </div>
      <!-- /. d flex -->
    </div>
    <!-- /.col -->
  </div>
  <!-- /.row form -->
</div>
