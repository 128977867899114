import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { MockService } from 'src/app/core/services/mock/mock.service';
import { apiUrl } from 'src/app/core/utils/api_url';
import { AdsDisableComponent } from './ads-disable/ads-disable.component';
import { AdsEnableComponent } from './ads-enable/ads-enable.component';
import { AdsTableI, AdvertisementI } from './interface/ads_table_i';
import { AddImageAdsComponent } from './add-image-ads/add-image-ads.component';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage-service';


@Component({
  selector: 'app-ads-table',
  templateUrl: './ads-table.component.html',
  styleUrls: ['./ads-table.component.scss'],
  providers: [ApiHelperService],
})
export class AdsTableComponent implements OnInit {
  mock: Function;
  role: string = '';
  user: string = 'User';
  marketing: string = 'Marketing';

  @ViewChild(AdsEnableComponent) enableAdsChild: AdsEnableComponent;
  @ViewChild(AdsDisableComponent) disableAdsChild: AdsDisableComponent;
  @ViewChild(AddImageAdsComponent) addImageMobileAdsChild: AddImageAdsComponent;

  @Output() setModifyAdsEmitter: EventEmitter<AdvertisementI> =
    new EventEmitter();

  constructor(
    public http: ApiHelperService<AdsTableI>,
    private mockData: MockService,
    private storage: LocalStorageService,

  ) {
    this.mock = this.mockData.createMockData;
  }

  ngOnInit(): void {
    this.getAds();
    this.role = this.storage.role();

  }

  clickEnable(id: number) {
    this.enableAdsChild.setId(id);
  }
  clickDisable(id: number) {
    this.disableAdsChild.setId(id);
  }

  clickAddImage(id: number) {
    this.addImageMobileAdsChild.setId(id);
  }

  getAds(): void {
    this.http.post({
      url: apiUrl.getAds,
      data: {
        sortBy: {
          dateFrom: 'desc',
        },
      },
      successCallBack: this.successCallBack,
    });
  }

  successCallBack = (): void => {
    console.log(this.http.apiDataState.result.result.advertisements[0].title);
  };

  clickModify(data: AdvertisementI): void {
    console.log(data, 'click modify');
    this.setModifyAdsEmitter.emit(data);
  }

  isRestrictedRole1(): boolean {
    const restrictedRoles = ['Audit'];
    return restrictedRoles.includes(this.role);
  }
  
  
}
