import { Component, OnInit } from '@angular/core';
import { MockService } from 'src/app/core/services/mock/mock.service';
import { Location } from '@angular/common';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { DonationsTableFilterI } from './interface/donations_table_option_interface';
import { DonationsResponseI, DonationsTableResponseI } from './interface/donations_table_data_interface';
import { apiUrl } from 'src/app/core/utils/api_url';
import { MyTableOptionsInterface } from 'src/app/core/interface/my_table_data_interface';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-donations-report',
  templateUrl: './donations-report.component.html',
  styleUrls: ['./donations-report.component.scss'],
  providers: [ApiHelperService, DatePipe],
})
export class DonationsReportComponent implements OnInit {
  static readonly routeName: string = 'donations';
  mock: Function;

  tableSizeAndPage = {
    page: 1,
    size: 10,
  };

  tableFilter: DonationsTableFilterI = {
    transactionId: null,
    firstName: null,
    middleName: null,
    lastName: null,
    mobileNumber: null,
    from: null,
    to: null,
    search: null,
    sortBy: {
      transactionId: 'asc',
    },
  };

  filterForm = this.formBuilder.group({
    firstName: [''],
    lastName: [''],
    transactionId: [''],
    mobileNumber: [''],
    from: [''],
    to: [''],
    status: [''],
  });

  tableOptionsResponse: MyTableOptionsInterface = {
    items: 0,
    size: 0,
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    hasNextPage: false,
    nextPage: 0,
    isLoading: false,
  };

  data: DonationsResponseI[] = [];

  constructor(
    private mockService: MockService,
    private location: Location,
    public http: ApiHelperService<DonationsTableResponseI>,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.mock = this.mockService.createMockData;
  }

  ngOnInit(): void {
    this.getDonationsData();
  }

  goBack(): void {
    this.location.back();
  }

  get filterControl() {
    return this.filterForm.controls;
  }

  filterData(): void {
    let filterForm = this.filterControl;
    let filterObject = this.tableFilter;

    if (filterForm.from.value.length >= 1) {
      filterObject.from = this.newDateFormat(filterForm.from.value);
    } else {
      filterObject.from = null;
    }
    if (filterForm.to.value.length >= 1) {
      filterObject.to = this.newDateFormat(filterForm.to.value);
    } else {
      filterObject.to = null;
    }

    if (filterForm.firstName.value.length >= 1) {
      filterObject.firstName = filterForm.firstName.value;
    } else {
      filterObject.firstName = null;
    }

    if (filterForm.lastName.value.length >= 1) {
      filterObject.lastName = filterForm.lastName.value;
    } else {
      filterObject.lastName = null;
    }
    if (filterForm.mobileNumber.value.length >= 1) {
      filterObject.mobileNumber = filterForm.mobileNumber.value;
    } else {
      filterObject.mobileNumber = null;
    }
    if (filterForm.transactionId.value.length >= 1) {
      filterObject.transactionId = filterForm.transactionId.value;
    } else {
      filterObject.transactionId = null;
    }
    this.resetData();
  }

  resetData = (): void => {
    setTimeout(() => {
      this.tableSizeAndPage.page = 1;
      this.tableSizeAndPage.size = 10;
      this.getDonationsData();
    }, 1000);
  };

  newDateFormat(dateTime: string): string {
    return this.datePipe.transform(dateTime, 'yyyy-MM-dd');
  }

  updatePageSize(pageSize: number) {
    this.tableSizeAndPage.size = pageSize;
    this.getDonationsData();
  }
  updatePageNumber(pageNumber: number) {
    this.tableSizeAndPage.page = pageNumber;
    this.getDonationsData();
  }
  getDonationsData(): void {
    const tableSizeAndPage = this.tableSizeAndPage;
    this.http.post({
      url: `${apiUrl.getDonations}?page=${tableSizeAndPage.page}&size=${tableSizeAndPage.size}`,
      data: this.tableFilter,
      successCallBack: this.callBackSuccess,
    });
  }
  callBackSuccess = (): void => {
    let tableOptionsResponse = this.tableOptionsResponse;
    let tableData = this.http.apiDataState.result.result;
    tableOptionsResponse.totalItems = tableData.totalItems;
    tableOptionsResponse.totalPages = tableData.totalPages;
    tableOptionsResponse.currentPage = tableData.currentPage;
    tableOptionsResponse.size = tableData.size;
    tableOptionsResponse.hasNextPage = tableData.hasNextPage;
    this.data = tableData.data;
  };
}
