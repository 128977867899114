import { Component, OnInit } from '@angular/core';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { CreateUserInterface } from '../interface/create_user_interface';
import { FormBuilder, Validators } from '@angular/forms';
import { apiUrl } from 'src/app/core/utils/api_url';
import { responseStatus } from '../../../core/utils/response_status';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
  // form input builder
  createUserForm = this.formBuilder.group({
    username: ['', Validators.required],
    email: ['', Validators.required],
    role: ['', Validators.required],
  });
  constructor(
    private http: ApiHelperService<CreateUserInterface>,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {}

  get createUserControl() {
    return this.createUserForm.controls;
  }

  submitCreateAccount(): void {}
}
