<aside class="main-sidebar sidebar-dark-primary purple-bg elevation-4">
  <div class="sidebar">
    <nav class="mt-2">
      <ul
        class="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="false"
      >
        <li class="nav-item">
          <a role="button" class="nav-link" *ngIf="!isRestrictedRole1()">
            <img class="nav-icon" [src]="routes.transaction.asset" />
            <p>
              {{ routes.transaction.name }}
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [href]="routes.viewCashInTransaction.path" class="nav-link">
                <p>{{ routes.viewCashInTransaction.name }}</p>
              </a>
            </li>
            <li class="nav-item">
              <a [href]="routes.viewDonationsReport.path" class="nav-link">
                <p>{{ routes.viewDonationsReport.name }}</p>
              </a>
            </li>
          </ul>
        </li>
        <!-- /.transaction -->

        <li class="nav-item">
          <a role="button" class="nav-link">
            <img class="nav-icon" [src]="routes.campaign.asset" />
            <p>
              {{ routes.campaign.name }}
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <!-- <li class="nav-item">
              <a
                role="button"
                [routerLink]="routes.campaignTable.path"
                class="nav-link"
              >
                <p>{{ routes.campaignTable.name }}</p>
              </a>
            </li> -->
            <li class="nav-item">
              <a
                role="button"
                [routerLink]="routes.eventsTable.path"
                class="nav-link"
              >
                <p>{{ routes.eventsTable.name }}</p>
              </a>
            </li>
          </ul>
        </li>
        <!-- /.campaign -->
        <li class="nav-item">
          <a role="button" class="nav-link">
            <img class="nav-icon" [src]="routes.portal.asset" />
            <p>
              {{ routes.portal.name }}
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [routerLink]="routes.portalTable.path" class="nav-link">
                <p>{{ routes.portalTable.name }}</p>
              </a>
            </li>
          </ul>
        </li>
        <!-- /.portal -->
        <li class="nav-item">
          <a href="#" class="nav-link">
            <img class="nav-icon" [src]="routes.user.asset" />
            <p>
              {{ routes.user.name }}
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [routerLink]="routes.userTable.path" class="nav-link">
                <p>{{ routes.userTable.name }}</p>
              </a>
            </li>
          </ul>
        </li>
        <!-- /.user -->
        <li class="nav-item">
          <a href="#" class="nav-link">
            <img class="nav-icon" [src]="routes.websiteContent.asset" />
            <p>
              {{ routes.websiteContent.name }}
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [routerLink]="routes.howItWorks.path" class="nav-link">
                <p>{{ routes.howItWorks.name }}</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="routes.aboutUs.path" class="nav-link">
                <p>{{ routes.aboutUs.name }}</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="routes.faq.path" class="nav-link">
                <p>{{ routes.faq.name }}</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="routes.ads.path" class="nav-link">
                <p>{{ routes.ads.name }}</p>
              </a>
            </li>
          </ul>
        </li>
        <!-- /.user -->
      </ul>
    </nav>
  </div>
  <!-- /.sidebar -->
  <div class="sidebar-custom">
    <a (click)="logout()" role="button" class="logout-btn">
      <img
        src="/assets/svg/logout.svg"
        class="pointer-cursor white-fill"
        alt="My logout SVG"
      />
      Logout
    </a>
  </div>
</aside>
