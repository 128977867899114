import { MockService } from 'src/app/core/services/mock/mock.service';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { PortalAccountsInterface } from '../interface/portal_accounts_interface';
import { apiUrl } from '../../../core/utils/api_url';
import { filterTypeEnum } from 'src/app/core/utils/filter_type_enum';
import { MyTableOptionsInterface } from 'src/app/core/interface/my_table_data_interface';
import { PortalTableData } from '../interface/portal_table_data_interface';

@Component({
  selector: 'app-portal-table',
  templateUrl: './portal-table.component.html',
  styleUrls: ['./portal-table.component.scss'],
  providers: [ApiHelperService],
})
export class PortalTableComponent implements OnInit {
  mock: Function;

  myFilters = filterTypeEnum;

  tableOptionsResponse: MyTableOptionsInterface = {
    items: 0,
    size: 0,
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    hasNextPage: false,
    nextPage: 0,
    isLoading: false,
  };

  tableSizeAndPage = {
    page: 1,
    size: 10,
  };

  @Input() callRefreshData: () => void;
  @Output() modifyUser: EventEmitter<PortalTableData> = new EventEmitter();
  @Output() passUserId: EventEmitter<number> = new EventEmitter();

  constructor(
    private mockData: MockService,
    public http: ApiHelperService<PortalAccountsInterface>
  ) {
    this.mock = this.mockData.createMockData;
  }

  modifyTrigger(user: PortalTableData): void {
    this.modifyUser.emit(user);
  }

  reloadData(): void {
    this.callRefreshData();
  }

  clickDelete(userID: number) {
    console.log(userID, 'user id');
    this.passUserId.emit(userID);
  }

  ngOnInit(): void {
    this.getPortalData({});
  }

  filterBy(filterType: filterTypeEnum): any {
    switch (filterType) {
      case filterTypeEnum.id: {
        this.getPortalData({
          sortBy: {
            id: 'desc',
          },
        });
        break;
      }
      case filterTypeEnum.username: {
        this.getPortalData({
          sortBy: {
            username: 'asc',
          },
        });
        break;
      }
      case filterTypeEnum.email: {
        this.getPortalData({
          sortBy: {
            email: 'desc',
          },
        });
        break;
      }
      case filterTypeEnum.role: {
        this.getPortalData({
          sortBy: {
            role: 'asc',
          },
        });
        break;
      }
      case filterTypeEnum.status: {
        this.getPortalData({
          sortBy: {
            status: 'desc',
          },
        });
        break;
      }
      default: {
        console.log('Invalid choice');
        break;
      }
    }
  }

  navigatePagination(pageNumber: number): void {
    this.tableSizeAndPage.page = pageNumber;
    this.getPortalData({});
  }

  onChangeTableSize(pageSize: number): void {
    this.tableSizeAndPage.size = pageSize;
    this.getPortalData({});
  }

  getPortalData(filter: any): void {
    const tableSizeAndPage = this.tableSizeAndPage;
    this.http.post({
      url: `${apiUrl.getAccounts}?page=${tableSizeAndPage.page}&size=${tableSizeAndPage.size}`,
      data: filter,
      successCallBack: this.callBackSuccess,
    });
  }

  callBackSuccess = (): void => {
    let tableOptionsResponse = this.tableOptionsResponse;
    let tableData = this.http.apiDataState.result.result;
    tableOptionsResponse.totalItems = tableData.totalItems;
    tableOptionsResponse.totalPages = tableData.totalPages;
    tableOptionsResponse.currentPage = tableData.currentPage;
    tableOptionsResponse.size = tableData.size;
    tableOptionsResponse.hasNextPage = tableData.hasNextPage;
  };
}
