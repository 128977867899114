import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { FaqListComponent } from './faq-list/faq-list.component';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage-service';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  providers: [ApiHelperService],
})
export class FaqComponent implements OnInit {
  static readonly routeName: string = 'faq';
  role: string = '';
  user: string = 'Audit';
  marketing: string = 'Marketing';
  @ViewChild(FaqListComponent) faqListChild: FaqListComponent;

  constructor(
    private storage: LocalStorageService,

  ) {}

  ngOnInit(): void {
    this.role = this.storage.role();

  }

  getFaqList():void{
    this.faqListChild.getFaq();
  }

  isRestrictedRole1(): boolean {
    const restrictedRoles = ['Audit'];
    return restrictedRoles.includes(this.role);
  }
}
