<div class="container">
  <div class="row mb-3">
    <div class="col-4 mt-4">
      <app-page-title pageTitle="about us"></app-page-title>
    </div>
    <!-- /.col -->
  </div>
  <!--  /. row buttons -->
  <div class="row mb-5">
    <div class="col-12">
      <div class="best-card best-card-shadow">
        <p class="content text-center">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat quos
          veritatis hic delectus voluptatem molestiae est dignissimos expedita
          accusamus labore iure voluptate laudantium necessitatibus dolorum
          deserunt provident tempore, numquam minus? Lorem, ipsum dolor sit amet
          consectetur adipisicing elit. Libero perspiciatis unde ex vel
          blanditiis magni quaerat obcaecati ad velit voluptatibus, error porro,
          corporis itaque tenetur aliquid? Vero porro dolore voluptate.
        </p>
      </div>
    </div>
  </div>
  <!-- /.row form -->
  <div class="row row-cols-3 mb-5">
    <div class="col d-block justify-content-center">
      <div class="upload-circle mx-auto mb-3 best-card-shadow">
        <p class="content">Image <br />Upload</p>
      </div>
      <a *ngIf="!isRestrictedRole1()" role="button" class="text-center text-style1 d-block">
        Edit testimony
      </a>
    </div>
    <div class="col d-block justify-content-center">
      <div class="upload-circle mx-auto mb-3 best-card-shadow">
        <p class="content">Image <br />Upload</p>
      </div>
      <a *ngIf="!isRestrictedRole1()" role="button" class="text-center text-style1 d-block">
        Edit testimony
      </a>
    </div>
    <div class="col d-block justify-content-center">
      <div class="upload-circle mx-auto mb-3 best-card-shadow">
        <p class="content">Image <br />Upload</p>
      </div>
      <a *ngIf="!isRestrictedRole1()" role="button" class="text-center text-style1 d-block">
        Edit testimony
      </a>
    </div>
  </div>
  <div class="d-flex"  *ngIf="!isRestrictedRole1()">
    <button
      class="btn my-btn-color-2 f-weight-6 align-self-center mx-auto btn-med"
      role="button"
      data-toggle="modal"
      data-target="#addTestimony"
    >
      Add testimony
    </button>
  </div>
</div>
<app-add-testimony></app-add-testimony>
