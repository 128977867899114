<div
  class="modal fade"
  id="modifyModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content radius-lg b-b-none">
      <div class="modal-header b-b-none">
        <h5 class="modal-title" id="exampleModalLabel">&nbsp;</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="modifyUserForm" (ngSubmit)="submitModifyForm()">
          <div class="row justify-content-center mb-3">
            <div class="col-8">
              <div class="row g-3 align-items-center mb-3">
                <div class="col-4">
                  <label
                    for="username"
                    class="col-form-label form-label my-text-label-2"
                    >Username:</label
                  >
                </div>
                <div class="col">
                  <input
                    id="username"
                    class="form-control input-design-3"
                    formControlName="username"
                  />
                </div>
              </div>
              <!-- /. username -->
            </div>
            <!-- /.col -->
            <div class="col-8">
              <div class="row g-3 align-items-center mb-3">
                <div class="col-4">
                  <label
                    for="email"
                    class="col-form-label form-label my-text-label-2"
                    >Email:</label
                  >
                </div>
                <div class="col">
                  <input
                    id="email"
                    class="form-control input-design-3"
                    formControlName="email"
                  />
                </div>
              </div>
              <!-- /. email -->
            </div>
            <!-- /.col -->
            <div class="col-8">
              <div class="row g-3 align-items-center mb-3">
                <div class="col-4">
                  <label
                    for="role"
                    class="col-form-label form-label my-text-label-2"
                    >Change Password:</label
                  >
                </div>
                <div class="col">
                  <input
                    id="role"
                    class="form-control input-design-3"
                    placeholder="Input New Password"
                    formControlName="password"
                  />
                </div>
              </div>
              <!-- /. role -->
            </div>
            <!-- /.col -->
            <div class="col-8">
              <div class="row g-3 align-items-center mb-3">
                <div class="col-4">
                  <label
                    for="role"
                    class="col-form-label form-label my-text-label-2"
                    >Role/Permission:</label
                  >
                </div>
                <div class="col">
                  <select
                    id="role"
                    class="form-control input-design-3"
                    formControlName="role"
                  >
                    <option selected>Choose...</option>
                    <option [value]="data.title" *ngFor="let data of userRoles">
                      {{ data.title }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- /. role -->
            </div>
            <!-- /.col -->
            <div class="col-10 mb-3 justify-content text-center">
              <app-dot-loading-handler
                [dotCount]="3"
                [dataElement]="myBtn"
                [isLoading]="http.apiDataState.isLoading"
              ></app-dot-loading-handler>
              <ng-template #myBtn let-data="data">
                <div class="d-flex">
                  <button
                    class="btn my-btn-color-2 f-weight-6 align-self-center mx-auto w-150"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </ng-template>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row form -->
        </form>
      </div>
      <div class="modal-footer b-t-none"></div>
    </div>
  </div>
</div>
<!-- /. modal modify end -->
