import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { AdsFormComponent } from './ads-form/ads-form.component';
import { AdsTableComponent } from './ads-table/ads-table.component';
import { AdvertisementI } from './ads-table/interface/ads_table_i';
import { LocalStorageService } from 'src/app/core/services/local-storage/local-storage-service';


@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.scss'],
  providers: [ApiHelperService],
})
export class AdsComponent implements OnInit {
  static readonly routeName: string = 'ads';
  role: string = '';
  user: string = 'Audit';

  @ViewChild(AdsTableComponent) adsTableChild: AdsTableComponent;
  @ViewChild(AdsFormComponent) adsFormChild: AdsFormComponent;

  constructor(
    private storage: LocalStorageService,

  ) {}

  ngOnInit(): void {
    this.role = this.storage.role();

  }

  reloadAds(): void {
    this.adsTableChild.getAds();
  }

  passedModifyData(data: AdvertisementI): void {
    this.adsFormChild.setModifyAds(data);
    console.log(data, 'from parent');
  }
  isRestrictedRole1(): boolean {
    const restrictedRoles = ['Audit'];
    return restrictedRoles.includes(this.role);
  }
  
}
