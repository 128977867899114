import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../local-storage/local-storage-service';
import { Router } from '@angular/router';
import { LocalStorageKeys } from '../../utils/local_storage_keys';
import { SweetAlert2Service } from '../sweet-alert2/sweet_alert2';

@Injectable({
  providedIn: 'root',
})
export class AuthenticantionGuard implements CanActivate {
  constructor(
    private storage: LocalStorageService,
    private router: Router,
    private swal: SweetAlert2Service
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // authchecker
    if (
      !this.storage.get(LocalStorageKeys.account) &&
      !this.storage.get(LocalStorageKeys.token)
    ) {
      this.router.navigateByUrl('/');
    }

    const isAuthorized = route.data.role.includes(this.storage.role());
    if (isAuthorized) {
      this.swal.toastInfo('you are not authorized !');
      return false;
    }

    // role checker
    return true;
  }
}
