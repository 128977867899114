import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { apiUrl } from 'src/app/core/utils/api_url';
import { NgoEventInterface } from '../interface/ngo_event_interface';
import { ImageListInterface } from '../../campaign/modify-campaign/interface/image_list_interface';
import { FormBuilder, Validators } from '@angular/forms';
import { EmptyResultInterface } from 'src/app/core/interface/empty_result_interface';

@Component({
  selector: 'app-modify-events',
  templateUrl: './modify-events.component.html',
  styleUrls: ['./modify-events.component.scss'],
  providers: [ApiHelperService],
})
export class ModifyEventsComponent implements OnInit {
  static readonly routeName: string = 'view-events/:id';
  id: string;
  imageList: string[] = [];
  uploadNewContentData = new FormData();
  eventForm = this.formBuilder.group({
    title: ['', Validators.required],
    details: ['', Validators.required],
  });

  editTitle: string = '';

  htmlOutput: string = '';
  summernoteConfig = {
    placeholder: '',
    tabsize: 2,
    height: '250px',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      [
        'font',
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear',
        ],
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'link', 'video', 'hr']],
    ],
    fontNames: [
      'Helvetica',
      'Arial',
      'Arial Black',
      'Comic Sans MS',
      'Courier New',
      'Roboto',
      'Times',
    ],
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public httpEvent: ApiHelperService<NgoEventInterface>,
    public modifyEvent: ApiHelperService<EmptyResultInterface>
  ) {
    const selectedId = this.activatedRoute.snapshot.params['id'];
    this.id = selectedId;
  }

  ngOnInit(): void {
    this.getCampaign();
  }

  submitModifyEvent(): void {
    const form = this.ngoEventControl;
    form.details.setValue(this.htmlOutput);
    const formDataUpdate = new FormData();

    formDataUpdate.append('details', form.details.value);
    formDataUpdate.append('title', form.title.value);

    this.modifyEvent.put({
      url: apiUrl.modifyEvent.replace(':uid', this.id),
      data: formDataUpdate,
      successCallBack: this.successModifyRetrieve,
    });
  }

  successModifyRetrieve = (): void => {
    this.resetData();
    this.getCampaign();
  };

  getCampaign(): void {
    this.httpEvent.get({
      url: `${apiUrl.getOneNgoEvents.replace(':id', this.id)}`,
      successCallBack: this.successGetEvent,
    });
  }

  get ngoEventControl() {
    return this.eventForm.controls;
  }

  successGetEvent = (): void => {
    const eventData = this.httpEvent.apiDataState.result.result;
    const form = this.ngoEventControl;
    this.resetData();
    this.htmlOutput = eventData.details;

    form.title.setValue(eventData.title);
    form.details.setValue(eventData.details);
    // this.selectedGrandPrize = grandPrize(campaignData.rewards);
    // form.grandPrize.setValue(grandPrize(campaignData.rewards));
    // form.rewards.setValue(campaignData.rewards);
    // form.slotTotal.setValue(campaignData.slotTotal);
    // form.amountPerSlot.setValue(campaignData.amountPerSlot);
    // form.consolationDrawNumbers.setValue(campaignData.consolationDrawNumbers);
    // // consolation prize
    // let consRewards = consolationPrize(campaignData.rewards);
    // this.selectedConsolationPrize = consRewards;
    // consRewards.forEach((value, index) => {
    //   createRewardsInput(value, index,2);
    eventData.eventContents.forEach((data) => {
      this.imageList.push(data.url);
    });
  };

  resetData(): void {
    this.imageList = [];
  }

  deleteImage(img: string): void {
    const formData = new FormData();
    formData.append('contentsToBeDeleted', img);
    this.modifyEvent.put({
      url: apiUrl.modifyEvent.replace(':uid', this.id),
      data: formData,
      successCallBack: this.successModifyRetrieve,
    });
  }

  uploadNewContent(): void {
    this.modifyEvent.put({
      url: apiUrl.modifyEvent.replace(':uid', this.id),
      data: this.uploadNewContentData,
      successCallBack: this.successUploadContent,
    });
  }

  successUploadContent = (): void => {
    var inputValue = <HTMLInputElement>(
      document.getElementById('upload-content-input')
    );
    inputValue.value = '';
    inputValue.dispatchEvent(new Event('change'));
    this.uploadNewContentData.delete('newUploadedContents[]');
    this.getCampaign();
  };

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  prepareFile = (event) => {
    const formData = this.uploadNewContentData;

    if (event.target.files.length <= 0) {
      formData.append('newUploadedContents', '');
      return;
    }
    return this.sleep(1000).then((v) => {
      for (let i = 0; i < event.target.files.length; i++) {
        formData.append('newUploadedContents[]', event.target.files[i]);
      }
    });
  };

  openUpload(): void {
    let element: HTMLElement = document.getElementById(
      'upload-content-input'
    ) as HTMLElement;

    element.click();
  }

  async onFileSelected(event): Promise<void> {
    if (event.target.files.length <= 0) {
      return;
    }
    await this.prepareFile(event);
    this.uploadNewContent();
  }
}
