<div class="wrapper">
  <app-my-header-nav></app-my-header-nav>

  <app-my-side-nav></app-my-side-nav>

  <div class="content-wrapper">
    <router-outlet></router-outlet>
  </div>
  <app-my-footer></app-my-footer>

  <aside class="control-sidebar control-sidebar-dark"></aside>
</div>
