import { Component, OnInit } from '@angular/core';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import bsCustomFileInput from 'bs-custom-file-input';
import { AddTestimonyInterface } from './interface/add_testimony_interface';
import { Validators, FormBuilder } from '@angular/forms';
import { SweetAlert2Service } from 'src/app/core/services/sweet-alert2/sweet_alert2';
import { apiUrl } from 'src/app/core/utils/api_url';

@Component({
  selector: 'app-add-testimony',
  templateUrl: './add-testimony.component.html',
  styleUrls: ['./add-testimony.component.scss'],
  providers: [ApiHelperService],
})
export class AddTestimonyComponent implements OnInit {
  formData = new FormData();

  imageFile: File = null;
  testimonyFormBuilder = this.formBuilder.group({
    mobileNumber: ['', Validators.required],
    image: ['', Validators.required],
    testimony: ['', Validators.required],
    campaignId: [null],
  });
  constructor(
    public http: ApiHelperService<AddTestimonyInterface>,
    private formBuilder: FormBuilder,
    private swal: SweetAlert2Service
  ) {}

  ngOnInit(): void {
    bsCustomFileInput.init();
  }
  get testimonyControl() {
    return this.testimonyFormBuilder.controls;
  }

  addTestimony(): void {
    const formData = this.formData;
    const formValidator = this.testimonyControl;
    console.log(formValidator.mobileNumber.value);
    if (formValidator.mobileNumber.invalid) {
      this.swal.toastError('mobile is required');
      return;
    }
    if (formValidator.image.invalid) {
      this.swal.toastError('image is required');
      return;
    }
    if (formValidator.testimony.invalid) {
      this.swal.toastError('testimony is required');
      return;
    }
    const campaingId = formValidator.campaignId.value;
    if (campaingId != null ) {
      formData.append('campaignId', campaingId);
    }

    formData.append('mobileNumber', formValidator.mobileNumber.value);

    formData.append('testimony', formValidator.testimony.value);
    // formData.append('image', this.imageFile);

    this.http.post({
      url: apiUrl.addTestimony,
      data: formData,
      successCallBack: this.successCallBack,
    });
  }

  successCallBack = (): void => {
    const closeModal = document.getElementById('closeModal');
    closeModal.click();
  };

  onChangeFiles(event): void {
    const formData = this.formData;
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      console.log(file, 'test');

      formData.append('image', file);
    }
  }
}
