import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { MockService } from 'src/app/core/services/mock/mock.service';
import { SweetAlert2Service } from 'src/app/core/services/sweet-alert2/sweet_alert2';
import { apiUrl } from 'src/app/core/utils/api_url';
import { StartRaffleInterface } from '../create-campaign/interface/start_raffle_interface';
import { CampaignInterface } from '../interface/campaign_table_interface';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-start-raffle',
  templateUrl: './start-raffle.component.html',
  styleUrls: ['./start-raffle.component.scss'],
  providers: [ApiHelperService],
})
export class StartRaffleComponent implements OnInit {
  static readonly routeName: string = 'start-raffle';
  mock: Function;
  settings: boolean = false;

  @Input() selectedCampaign: CampaignInterface = {
    uid: 0,
    campaignId: '',
    creationDate: '',
    campaignName: '',
    details: '',
    slotNumber: 0,
    slotAmount: 0,
    joinersPercentage: 0,
    status: '',
  };
  @Output() closeDrawEmit: EventEmitter<any> = new EventEmitter();

  formData = new FormData();

  uploadFile: File = null;

  typeOfGo: boolean = false;

  result: StartRaffleInterface = {
    referenceNumber: '',
    date: '',
    timeOfDraw: '',
    slot: 0,
    drawSlotWinners: [],
  };

  constructor(
    private mockData: MockService,
    private swal: SweetAlert2Service,
    public http: ApiHelperService<StartRaffleInterface>,
    private actRoute: ActivatedRoute,
    private location: Location
  ) {
    this.mock = this.mockData.createMockData;

    const data = JSON.parse(
      atob(actRoute.snapshot.params['data'])
    ) as CampaignInterface;
    this.selectedCampaign = data;
    const formData = this.formData;
    formData.append('campaignId', this.selectedCampaign.campaignId.toString());
    console.log(data);
  }

  ngOnInit(): void {}

  openInputFile(): void {
    const inputFile = document.getElementById('excel');
    inputFile.click();
  }

  onFileSelected(event) {
    const formData = this.formData;

    if (event.target.files.length <= 0) {
      formData.append('file', '');
      this.uploadFile = null;
      return;
    }
    const file = event.target.files[0];
    console.log(file);
    this.uploadFile = file;

    formData.append('file', file);
  }

  saveAndClose(): void {
    this.closeDrawEmit.emit();
  }

  startRaffle(tog: boolean): void {
    const file = this.uploadFile;
    this.typeOfGo = tog;
    if (file == null) {
      this.swal.toastError('file is missing');
      return;
    }

    this.http.post({
      url: apiUrl.startRaffle,
      data: this.formData,
      successCallBack: this.successCallBack,
      failCallBack: this.failCallBack,
    });
  }

  clearUploadData(): void {
    const formData = this.formData;
    this.uploadFile = null;
    this.formData.forEach((value, key) => {
      this.formData.delete(key);
    });
  }
  failCallBack = (): void => {
    // this.clearUploadData();
  };
  successCallBack = (): void => {
    const respose = this.http.apiDataState;
    respose.result.result.drawSlotWinners;
    this.result = respose.result.result;
    // this.clearUploadData();
    if (this.typeOfGo) {
      // this.closeDrawEmit.emit();
    }
  };

  closeDraw(): void {
    this.location.back();

    // this.closeDrawEmit.emit();
  }
}
