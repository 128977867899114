import { Component, Input, OnInit } from '@angular/core';
import { EmptyResultInterface } from 'src/app/core/interface/empty_result_interface';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { apiUrl } from 'src/app/core/utils/api_url';
import { CampaignInterface } from '../../interface/campaign_table_interface';

@Component({
  selector: 'app-approved-preview',
  templateUrl: './approved-preview.component.html',
  styleUrls: ['./approved-preview.component.scss'],
  providers: [ApiHelperService],
})
export class ApprovedPreviewComponent implements OnInit {
  @Input() id: string = '';
  imageList: string[] = [];
  selectedId: string = '';
  constructor(
    public httpCampaign: ApiHelperService<CampaignInterface>,
    public approveHttp: ApiHelperService<EmptyResultInterface>
  ) {}

  ngOnInit(): void {
    console.log('created');
  }

  getOneCampaign(id: string): void {
    this.selectedId = id;
    this.httpCampaign.get({
      url: `${apiUrl.getOneCampaign.replace(':id', id)}`,
      successCallBack: this.successGetCampaign,
    });
  }
  successGetCampaign = (): void => {
    const campaignData = this.httpCampaign.apiDataState.result.result;

    const imgReplace = campaignData.uploadedContents
      .replace('[', '')
      .replace(']', '')
      .split(',');

    this.imageList = imgReplace[0] == '' ? [] : imgReplace;
  };

  approvedCampaign() {
    this.approveHttp.put({
      url: apiUrl.approveCampginStatus.replace(':id', this.selectedId),
      data: {
        isApproved: true,
      },
      successCallBack: this.resetData,
      failCallBack: this.resetData,
    });
  }

  resetData = (): void => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
}
