import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ApiHelperService } from 'src/app/core/services/api-helper/api-helper.service';
import { apiUrl } from 'src/app/core/utils/api_url';
import { CreatePortalAccountInterface } from '../interface/create_portal_account';
import { RoleInterface } from '../../../core/interface/role_interface';
import { myUserRoles } from 'src/app/core/utils/role_helper';

@Component({
  selector: 'app-create-portal-account',
  templateUrl: './create-portal-account.component.html',
  styleUrls: ['./create-portal-account.component.scss'],
  providers: [ApiHelperService],
})
export class CreatePortalAccountComponent implements OnInit {
  userRoles: RoleInterface[] = myUserRoles;
  createPortalAccountForm = this.formBuilder.group({
    username: ['', Validators.required],
    email: ['', Validators.required],
    role: ['', Validators.required],
  });

  constructor(
    public http: ApiHelperService<CreatePortalAccountInterface>,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {}

  get createUserControl() {
    return this.createPortalAccountForm.controls;
  }

  submitCreateAccount(): void {
    this.http.post({
      url: apiUrl.createNewAccount,
      data: this.createPortalAccountForm.value,
      successCallBack: this.successCallBack,
    });
  }

  successCallBack = (): void => {
    this.createPortalAccountForm.reset();
  };
}
