<div class="container">
  <div class="row mb-3">
    <div class="col-md-4 col-11 mt-4">
      <app-page-title pageTitle="Create Events"></app-page-title>
    </div>
    <!-- /.col -->
    <div class="col mt-4 align-self-end">
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <a
          [href]="myRoutes.eventsTable.path"
          role="button"
          class="orange-pill text-white px-2 text-decoration-none f-weight-6"
          type="button"
        >
          X
        </a>
      </div>
    </div>
    <!-- /.col -->
  </div>
  <!--  /. row buttons -->
  <form [formGroup]="eventForm" (ngSubmit)="createEvents()">
    <div class="row justify-content-center">
      <div class="col-md-8 col-12">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4">
            <label for="title" class="col-form-label form-label">Title:</label>
          </div>
          <div class="col">
            <input
              id="title"
              class="form-control input-design-2"
              placeholder="Enter title"
              formControlName="title"
            />
          </div>
        </div>
        <!-- /. title  -->
      </div>
      <!-- /.col -->
      <div class="col-md-8 col-12">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4">
            <label for="name" class="col-form-label form-label">Name:</label>
          </div>
          <div class="col">
            <input
              id="name"
              class="form-control input-design-2"
              placeholder="Enter name"
              formControlName="name"
            />
          </div>
        </div>
        <!-- /. name  -->
      </div>
      <!-- /.col -->
      <div class="col-md-8 col-12">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4">
            <label for="details" class="col-form-label form-label"
              >Details:</label
            >
          </div>
          <div class="col">
            <input
              id="details"
              class="form-control input-design-2"
              placeholder="Enter details"
              [ngxSummernote]="summernoteConfig"
              formControlName="details"
              [(ngModel)]="htmlOutput"
            />
          </div>
        </div>
        <!-- /. details  -->
      </div>
      <!-- /.col -->
      <div class="col-md-8 col-12">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4">
            <label for="slotTotal" class="col-form-label form-label"
              >Set Slot Total:</label
            >
          </div>
          <div class="col">
            <input
              id="slotTotal"
              class="form-control input-design-2"
              placeholder="Enter between 1-1000"
              type="number"
              formControlName="slotTotal"
            />
          </div>
        </div>
        <!-- /. slotTotal -->
      </div>
      <!-- /.col -->
      <div class="col-md-8 col-12">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4">
            <label for="minAmount" class="col-form-label form-label"
              >Min amount:</label
            >
          </div>
          <div class="col">
            <input
              id="minAmount"
              class="form-control input-design-2"
              placeholder="Set amount"
              type="number"
              formControlName="minAmount"
            />
          </div>
        </div>
        <!-- /. minAmount -->
      </div>
      <!-- /.col -->
      <!-- <div class="col-md-8 col-12">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4">
            <label for="maxAmount" class="col-form-label form-label"
              >max amount:</label
            >
          </div>
          <div class="col">
            <input
              id="maxAmount"
              class="form-control input-design-2"
              placeholder="Set amount"
              type="number"
              formControlName="maxAmount"
            />
          </div>
        </div>
        /. minAmount 
      </div>-->
      <!-- /.col -->
      <div class="col-md-8 col-12">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4">
            <label for="campaginRewards" class="col-form-label form-label"
              >Upload Content:</label
            >
          </div>
          <div class="col">
            <div class="custom-file">
              <input
                (change)="onFileSelected($event)"
                type="file"
                class="custom-file-input"
                ([NgModel])="(fileInput)"
                id="file-input"
                multiple
              />
              <label class="custom-file-label" for="exampleInputFile"
                >Choose file</label
              >
            </div>
          </div>
        </div>
        <!-- /. campaign rewards -->
      </div>
      <!-- /.col -->
      <div class="col-md-8 col-12">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4">
            <label for="startDate" class="col-form-label form-label"
              >Start Date:</label
            >
          </div>
          <div class="col">
            <input
              id="startDate"
              type="date"
              class="form-control input-design-2"
              formControlName="startDate"
            />
          </div>
        </div>
        <!-- /. end date -->
      </div>
      <!-- /.col -->
      <div class="col-md-8 col-12">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-4">
            <label for="endData" class="col-form-label form-label"
              >End Date:</label
            >
          </div>
          <div class="col">
            <input
              id="endData"
              type="date"
              class="form-control input-design-2"
              formControlName="endDate"
            />
          </div>
        </div>
        <!-- /. end date -->
      </div>
      <!-- /.col -->

      <div class="col-10 mb-3">
        <hr />
      </div>

      <div class="col-10 mb-3">
        <div class="d-flex">
          <button
            class="btn my-btn-color-2 f-weight-6 align-self-center mx-auto btn-med"
            type="submit"
          >
            SUBMIT
          </button>
        </div>
        <!-- /. d flex -->
      </div>
      <!-- /.col -->
    </div>
  </form>
  <!-- /.row form -->
</div>

<app-over-lay-loading
  *ngIf="http.apiDataState.isLoading"
></app-over-lay-loading>
