import { RoleInterface } from '../interface/role_interface';

export const myUserRoles: RoleInterface[] = [
  {
    uid: 1,
    title: 'Master',
    description: 'has control or privilege to anything',
  },
  {
    uid: 2,
    title: 'Admin',
    description: 'has master controls but inferior to master role',
  },
  {
    uid: 3,
    title: 'Marketing',
    description: 'has upload privileges',
  },
  {
    uid: 4,
    title: 'Marketing Manager',
    description: 'has upload, modify & approve privileges',
  },
  {
    uid: 5,
    title: 'Accounting',
    description: 'has cash in and history campaign privileges',
  },
  {
    uid: 6,
    title: 'User',
    description: 'has general control or privilege',
  },
  {
    uid: 7,
    title: 'Marketing_Manager',
    description: 'has upload, modify & approve privileges',
  },
  {
    uid: 8,
    title: 'Audit',
    description: 'limited privilages',
  },
];
