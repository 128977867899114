<div
  class="modal fade"
  id="addImageMobile"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content radius-lg b-b-none">
      <div class="modal-header b-b-none">
        <h5 class="modal-title" id="exampleModalLabel">&nbsp;</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          id="closeAdsModaldelete"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form action="">
          <div class="row">
            <div class="col-12">
              <div class="row g-3 align-items-center mb-3">
                <div class="col-4">
                  <label for="web" class="col-form-label form-label"
                    >Web:</label
                  >
                </div>
                <div class="col">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="web-file-input"
                      (change)="onUploadFileWeb($event)"

                    />
                    <label class="custom-file-label" for="exampleInputFile"
                      >Choose file</label
                    >
                  </div>
                </div>
              </div>
              <!-- /. ads file -->
            </div>
            <!-- /.web -->
            <div class="col-12">
              <div class="row g-3 align-items-center mb-3">
                <div class="col-4">
                  <label for="mobile" class="col-form-label form-label"
                    >Mobile:</label
                  >
                </div>
                <div class="col">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="mobile-file-input"
                      (change)="onUploadFileMobile($event)"
                    />
                    <label class="custom-file-label" for="exampleInputFile"
                      >Choose file</label
                    >
                  </div>
                </div>
              </div>
              <!-- /. ads file -->
            </div>
            <!-- /. mobile -->
          </div>
          <div class="row justify-content-center mb-3">
            <div class="col-10 text-center"></div>
            <div class="col-10 mb-3 justify-content text-center">
              <button
                class="btn my-btn-color-2 f-weight-6 align-self-center mx-2 w-150"
                type="button"
                (click)="submitUpload()"
              >
                submit
              </button>
            </div>
            <!-- /.col -->
          </div>
        </form>
      </div>
      <div class="modal-footer b-t-none"></div>
    </div>
  </div>
</div>
<!-- /. modal delete end -->
