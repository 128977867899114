import {
  Component,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { MyTableOptionsInterface } from '../../interface/my_table_data_interface';
import { MockService } from 'src/app/core/services/mock/mock.service';
import { EventEmitter } from '@angular/core';
import { SweetAlert2Service } from '../../services/sweet-alert2/sweet_alert2';

@Component({
  selector: 'app-my-table',
  templateUrl: './my-table.component.html',
  styleUrls: ['./my-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MyTableComponent implements OnInit {
  @Input() dataTemplate: TemplateRef<any>;
  @Input() titleTemplates: TemplateRef<any>;
  @Input() settings: boolean = true;
  @Input() searchTypes: string[] = [];
  @Input() tableOptions: MyTableOptionsInterface = {
    items: 0,
    size: 0,
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
    hasNextPage: false,
    nextPage: 0,
    isLoading: false,
  };
  // https://stackoverflow.com/a/42814996
  // passing of data thru params with from child to parent
  @Output() navigatePagination: EventEmitter<any> = new EventEmitter();
  @Output() onChangeTableSize: EventEmitter<any> = new EventEmitter();
  @Output() onChangeSearchEmitter: EventEmitter<any> = new EventEmitter<{
    keyword: string;
    type: string;
  }>();

  mock: Function;

  tableSize: number = 10;
  searchInput: string = '';
  selectedSearchType: string = '';

  constructor(private mockData: MockService, private swal: SweetAlert2Service) {
    this.mock = this.mockData.createMockData;
  }

  onChangeSearch() {
    this.onChangeSearchEmitter.emit({
      keyword: this.searchInput,
      type: this.selectedSearchType,
    });
  }

  ngOnInit(): void {
    console.log('test');
    console.log(this.tableOptions.size, this.tableSize);
    setTimeout(() => {
      this.tableOptions.size = this.tableSize;
    }, 500);
  }

  paginateTrigger(page: number) {
    console.log(page, 'from child');
    this.navigatePagination.emit(page);
  }

  onChangeEntries() {
    let tableSize = this.tableSize;

    if (tableSize <= 0) {
      this.swal.toastInfo('Entries cannot be less than 0');
      return;
    }
    this.onChangeTableSize.emit(tableSize);
  }

  nextAndPrevTrigger(isAdd: boolean): void {
    let currentPage = this.tableOptions.currentPage;
    let hasNextPage = this.tableOptions.hasNextPage;
    if (currentPage > 1 && !isAdd) {
      const newPage = currentPage - 1;
      console.log(newPage);
      console.log('minus');
      this.navigatePagination.emit(newPage);
      return;
    }
    if (isAdd && hasNextPage) {
      const newPage = currentPage + 1;
      console.log('plus');
      console.log(newPage);
      this.navigatePagination.emit(newPage);
      return;
    }
    console.log('not allowed');
  }
}
