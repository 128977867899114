import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { ExcelInterface } from './excel_interface';
// import * as logo from './mylogo.js';
// export const imgBase64 = "data:image/png;base64,iVBORw0KGgoA.....VORK5CYII=";
// https://www.freakyjolly.com/create-excel-from-json-in-angular-98-using-exceljs-tutorial-with-example/
@Injectable({
  providedIn: 'root',
})
export class ExportExcelService {
  constructor() {}
  //NOTE
  // do not remove
  // the comments
  // reference for conditions
  exportExcel(excelData: ExcelInterface) {
    //Title, Header & Data
    let data = [];
    const title = excelData.fileName;
    const dataEntry = excelData.data;

    dataEntry.forEach((row: any) => {
      data.push(Object.values(row));
    });

    let header = Object.keys(dataEntry[0]);

    console.log(data);
    console.log(header);
    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Sales Data');

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' },
      };
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12,
      };
    });
    // //Add Row and formatting
    // worksheet.mergeCells('C1', 'F4');
    // let titleRow = worksheet.getCell('C1');
    // titleRow.value = title
    // titleRow.font = {
    //   name: 'Calibri',
    //   size: 16,
    //   underline: 'single',
    //   bold: true,
    //   color: { argb: '0085A3' }
    // }
    // titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    //Blank Row
    // worksheet.addRow([]);

    data.forEach((value) => {
      let row = worksheet.addRow(value);

      // reference for conditional cell
      // let sales = row.getCell(6);
      // let color = 'FF99FF99';
      // if (+sales.value < 200000) {
      //   color = 'FF9999';
      // }
      // sales.fill = {
      //   type: 'pattern',
      //   pattern: 'solid',
      //   fgColor: { argb: color },
      // };
    });

    // worksheet.getColumn(3).width = 20;
    // worksheet.addRow([]);

    // //Footer Row
    // let footerRow = worksheet.addRow(['Employee Sales Report Generated from example.com at ' + date]);
    // footerRow.getCell(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFB050' }
    // };

    //Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, title + '-report.xlsx');
    });
  }
}
