export const apiUrl = {
  // ***********************
  // ***** post method *****
  // ***********************
  login: 'auth/login',
  createNewAccount: 'portal/accounts/new',
  createCampaign: 'campaigns/new',
  campaignForceDraw: 'campaigns/force',
  getUsers: 'user/accounts',
  getFaq: 'faqs',
  getTnxData: 'user/accounts/:id/transactions',
  downloadTnxData: 'user/accounts/:id/transactions/excel',
  createNewFaq: 'faqs/new',
  startRaffle: 'campaigns/start',
  uploadHowItWorks: 'how-it-works',
  addTestimony: 'winners/testimonies',
  addFaq: 'faqs/new',
  addAds: 'advertisements/new',
  editAds: 'advertisements/:id/update',
  uploadContentAds: 'advertisements/:id/uploadContents',
  getAds: 'advertisements',
  getNgoEvents: 'events',
  getOneNgoEvents: 'events/:id',
  createEvents: 'events/create',
  // ***********************
  // ***** get method ******
  // ***********************
  // with parameters
  // [?page=1&size=10]
  getAccounts: 'portal/accounts',
  getCampaigns: 'campaigns',
  getTranscation: 'cashIn/orders',
  getOneCampaign: 'campaigns/:id',
  actionsEvent: 'events/:id/:action',
  getDonations: 'events/donations/all',
  // ***********************
  // ***** put method ******
  // ***********************
  modifyAccount: 'portal/accounts/:id/update',
  approveCampginStatus: 'campaigns/:id/approve',
  modifyCampaign: 'campaigns/:uid/update',
  modifyUser: 'user/accounts/:id/update',
  modifyFaq: 'faqs/:id/update',
  enabaleAds: 'advertisements/:id/enable',
  modifyEvent: 'events/:uid/update',
  // ***********************
  // ***** delete method ******
  // ***********************
  deletePortalAccount: 'portal/accounts/:id/delete',
  deleteCampaign: 'campaigns/:uid/delete',
  deleteUser: 'user/accounts/:id/delete',
  deleteFaq: 'faqs/:id/delete',
  deleteAds: 'advertisements/:id/disable',
};
