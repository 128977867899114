import { MockService } from 'src/app/core/services/mock/mock.service';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-dot-loading-handler',
  templateUrl: './dot-loading-handler.component.html',
  styleUrls: ['./dot-loading-handler.component.scss'],
})
export class DotLoadingHandlerComponent implements OnInit {
  @Input() dataElement?: TemplateRef<any>;
  @Input() dotCount: number = 1;
  @Input() isLoading: boolean = false;
  mock: Function;

  constructor(private mockData: MockService) {
    this.mock = this.mockData.createMockData;
  }

  ngOnInit(): void {}
}
